import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import AbortRequest from "services/AbortRequest";

class SelectSearch extends React.Component {
  constructor(props) {
    super(props);
    this.ABORT_REQUEST = new AbortRequest();

    this.state = {
      loading: true,
      noneValue: { value: "", label: props.emptyLabelValue },
      options: [],
      display: !props.hideIfEmpty,
    };
  }

  componentDidMount() {
    if (this.props.loadOnMount) {
      this.loadAsyncData();
    } else if (this.props.propsData) {
      this.handleResponse(this.props.propsData);
    }
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props;
    if (filter !== prevProps.filter) {
      this.loadAsyncData();
    }
  }

  loadAsyncData() {
    const { filter, filterKey, apiUrl, hideIfEmpty } = this.props;
    if (apiUrl == null) {
      return;
    }
    let config = {
      signal: this.ABORT_REQUEST.abortAndGetSignal(),
    };
    if (filter) {
      config.params = { [filterKey]: filter };
    }
    axiosApiBackend.get(apiUrl, config).then((res) => {
      this.handleResponse(res.data);
      if (hideIfEmpty) {
        this.setState({ display: res.data.length !== 0 });
      }
    });
  }

  handleResponse(datas) {
    const {
      buildOptionKey,
      buildOptionValue,
      buildOptionLabel,
      buildOptionDisabled,
      buildOptionAdditionalData,
      enableNoneValue,
      onResponse,
      sortOptions,
      sortResponseData,
    } = this.props;

    if (sortResponseData) {
      datas = datas.sort(sortResponseData);
    }

    let initOptions = datas.map((data) => {
      let option = {
        key: buildOptionKey(data),
        value: buildOptionValue(data),
        label: buildOptionLabel(data),
        disabled: buildOptionDisabled(data),
      };

      if (buildOptionAdditionalData) {
        option = { ...option, ...buildOptionAdditionalData(data) };
      }

      return option;
    });
    if (sortOptions) {
      initOptions = initOptions.sort(sortOptions);
    }
    if (enableNoneValue) {
      initOptions.unshift(this.state.noneValue);
    }

    this.setState(
      {
        loading: false,
        options: initOptions,
      },
      () => {
        this.selectFirstOption(initOptions);
      },
    );
    if (onResponse) {
      onResponse(datas, initOptions);
    }
  }

  selectFirstOption = (initOptions) => {
    const expectedLength = this.props.enableNoneValue ? 2 : 1;
    if (
      initOptions.length === expectedLength &&
      this.props.autoSelectFirstOption
    ) {
      this.props.onChange(
        this.props.name,
        initOptions[expectedLength - 1].value,
        initOptions[expectedLength - 1],
      );
    }
  };

  handleChange = (event, value) => {
    this.props.onChange(this.props.name, value?.value ?? "", value);
  };

  handleChangeVal = (value) => {
    let option = this.state.options.find((option) => option.value === value);
    return option != null ? option : this.getNoneValue();
  };

  getNoneValue = () => {
    return this.props.enableNoneValue ? this.state.noneValue : null;
  };

  filterValuesTohide = (options, valuesToHide) => {
    if (!options || !valuesToHide) {
      return options;
    }

    return options.filter((option) => !valuesToHide.includes(option.value));
  };

  render() {
    const {
      sx,
      className,
      size,
      inputRef,
      name,
      label,
      value,
      margin,
      disabled,
      error,
      shrink,
      textFieldProps,
      valuesToHide,
      sortOptions,
      groupBy,
      autoSelectFirstOption,
    } = this.props;
    const { options } = this.state;

    let optionToDispay = sortOptions ? options.sort(sortOptions) : options;
    optionToDispay = this.filterValuesTohide(optionToDispay, valuesToHide);

    return (
      <>
        {this.state.display && (
          <Autocomplete
            sx={sx}
            className={className}
            size={size}
            options={optionToDispay}
            getOptionLabel={(option) => option.label}
            getOptionDisabled={(option) => option.disabled}
            groupBy={groupBy}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.key ?? option.label}>
                  {option.label}
                  {this.props.renderExtra && this.props.renderExtra(option)}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                {...textFieldProps}
                inputRef={inputRef}
                name={name}
                label={label}
                variant="outlined"
                margin={margin}
                InputLabelProps={{
                  shrink: shrink,
                }}
                error={error}
              />
            )}
            onChange={this.handleChange}
            value={this.handleChangeVal(value)}
            loading={this.state.loading}
            loadingText="Chargement..."
            disabled={
              disabled || (options.length === 2 && autoSelectFirstOption)
            }
            disableClearable={this.props.disableClearable}
          />
        )}
      </>
    );
  }
}

SelectSearch.defaultProps = {
  size: "normal",
  hideIfEmpty: false,
  loadOnMount: true,
  buildOptionKey: (data) => data.id,
  buildOptionValue: (data) => data.id,
  buildOptionDisabled: () => false,
  // buildOptionDisabled: (data) => data.deleted_at != null,
  margin: "normal",
  enableNoneValue: true,
  disableClearable: false,
  emptyLabelValue: "Non renseigné",
  autoSelectFirstOption: false,
};

SelectSearch.propTypes = {
  sx: PropTypes.any,
  className: PropTypes.string,
  size: PropTypes.string,
  inputRef: PropTypes.func,
  hideIfEmpty: PropTypes.bool,
  loadOnMount: PropTypes.bool,
  name: PropTypes.string.isRequired,
  apiUrl: PropTypes.string,
  sortResponseData: PropTypes.func,
  onResponse: PropTypes.func,
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  buildOptionKey: PropTypes.func.isRequired,
  buildOptionValue: PropTypes.func.isRequired,
  buildOptionLabel: PropTypes.func.isRequired,
  buildOptionDisabled: PropTypes.func.isRequired,
  buildOptionAdditionalData: PropTypes.func,
  value: PropTypes.any,
  margin: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  shrink: PropTypes.bool,
  filter: PropTypes.any,
  filterKey: PropTypes.any,
  enableNoneValue: PropTypes.bool,
  disableClearable: PropTypes.bool,
  textFieldProps: PropTypes.object,
  valuesToHide: PropTypes.array,
  emptyLabelValue: PropTypes.string,
  sortOptions: PropTypes.func,
  groupBy: PropTypes.func,
  propsData: PropTypes.array,
  renderExtra: PropTypes.func,
  autoSelectFirstOption: PropTypes.bool,
};

export default SelectSearch;
