// @mui/icons-material
import Speed from "@mui/icons-material/Speed";
import AccountBalance from "@mui/icons-material/AccountBalance";
import HomeWork from "@mui/icons-material/HomeWork";
import Assignment from "@mui/icons-material/Assignment";
import Person from "@mui/icons-material/Person";
import Home from "@mui/icons-material/Home";
import Build from "@mui/icons-material/Build";
import Assessment from "@mui/icons-material/Assessment";
import HistoryEdu from "@mui/icons-material/HistoryEdu";

// core components
import Dashboard from "views/Dashboard/Dashboard";
import EmbeddedDashboard from "views/EmbeddedDashboard/EmbeddedDashboard";
import UnreadNotifications from "views/Tables/Notifications";
import StructureNationaleForm from "views/Forms/StructureNationaleForm";
import StructureNationaleView from "views/Forms/StructureNationaleView";
import StructuresLocales from "views/Tables/StructuresLocales";
import StructuresLocalesView from "views/Forms/StructuresLocalesView";
import StructuresLocalesForm from "views/Forms/StructuresLocalesForm";
import Missions from "views/Tables/Missions";
import MissionsView from "views/Forms/MissionsView";
import MissionsForm from "views/Forms/MissionsForm";
import Beneficiaires from "views/Tables/Beneficiaires";
import BeneficiairesView from "views/Forms/BeneficiairesView";
import BeneficiairesForm from "views/Forms/BeneficiairesForm";
import LogementsView from "views/Forms/LogementsView";
import LogementsForm from "views/Forms/LogementsForm";
import Logements from "views/Tables/Logements";
import AmoView from "views/Forms/AmoView";
import AmoForm from "views/Forms/AmoForm";
import Amo from "views/Tables/Amo";
import Esignatures from "views/Tables/Esignatures";
import EsignatureView from "views/Forms/EsignatureView";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Tableau de bord",
    icon: Speed,
    component: Dashboard,
  },
  {
    permission: ["view.embeddeddashboard.nationale"],
    path: "/bi-nationale",
    name: "BI Nationale",
    icon: Assessment,
    component: EmbeddedDashboard,
    fullscreen: true,
    componentProps: {
      backUrl: "nationale",
    },
  },
  {
    permission: ["view.embeddeddashboard.locale"],
    path: "/bi-locale",
    name: "BI Locale",
    icon: Assessment,
    component: EmbeddedDashboard,
    fullscreen: true,
    componentProps: {
      backUrl: "locale",
    },
  },
  {
    sidebar: false,
    permission: ["use.internalnotification"],
    path: "/notifications",
    name: "Notifications",
    component: UnreadNotifications,
  },
  {
    sidebar: false,
    permission: ["edit.structurenationale"],
    path: "/structure-nationale/modifier/:id",
    name: "Structure Nationale",
    component: StructureNationaleForm,
  },
  {
    permission: ["view.structurenationale", "edit.structurenationale"],
    path: "/structure-nationale",
    name: "Structure Nationale",
    icon: AccountBalance,
    component: StructureNationaleView,
    logs: true,
  },
  {
    sidebar: false,
    permission: ["view.structurelocale", "edit.structurelocale"],
    path: "/structures-locales/detail/:id",
    name: "Structures Locales",
    component: StructuresLocalesView,
  },
  {
    sidebar: false,
    permission: ["edit.structurelocale"],
    path: "/structures-locales/modifier/:id",
    name: "Structures Locales",
    component: StructuresLocalesForm,
  },
  {
    sidebar: false,
    permission: ["edit.structurelocale"],
    path: "/structures-locales/ajouter",
    name: "Structures Locales",
    component: StructuresLocalesForm,
  },
  {
    permission: ["view.structurelocale", "edit.structurelocale"],
    path: "/structures-locales",
    name: "Structures Locales",
    icon: HomeWork,
    component: StructuresLocales,
    fullscreen: true,
    logs: true,
  },
  {
    sidebar: false,
    permission: ["view.mission", "edit.mission"],
    path: "/missions/detail/:id",
    name: "Missions",
    component: MissionsView,
  },
  {
    sidebar: false,
    permission: ["edit.mission"],
    path: "/missions/modifier/:id",
    name: "Missions",
    component: MissionsForm,
  },
  {
    sidebar: false,
    permission: ["edit.mission"],
    path: "/missions/ajouter",
    name: "Missions",
    component: MissionsForm,
  },
  {
    permission: ["view.mission", "edit.mission"],
    path: "/missions",
    name: "Missions",
    icon: Assignment,
    component: Missions,
    fullscreen: true,
    logs: true,
  },
  {
    sidebar: false,
    permission: ["view.beneficiaire", "edit.beneficiaire"],
    path: "/beneficiaires/detail/:id",
    name: "Bénéficiaires",
    component: BeneficiairesView,
  },
  {
    sidebar: false,
    permission: ["edit.beneficiaire"],
    path: "/beneficiaires/modifier/:id",
    name: "Bénéficiaires",
    component: BeneficiairesForm,
  },
  {
    sidebar: false,
    permission: ["edit.beneficiaire"],
    path: "/beneficiaires/ajouter",
    name: "Bénéficiaires",
    component: BeneficiairesForm,
  },
  {
    permission: ["view.beneficiaire", "edit.beneficiaire"],
    path: "/beneficiaires",
    name: "Bénéficiaires",
    icon: Person,
    component: Beneficiaires,
    fullscreen: true,
    logs: true,
  },
  {
    sidebar: false,
    permission: ["view.logement", "edit.logement"],
    path: "/logements/detail/:id",
    name: "Logements",
    component: LogementsView,
  },
  {
    sidebar: false,
    permission: ["edit.logement"],
    path: "/logements/modifier/:id",
    name: "Logements",
    component: LogementsForm,
  },
  {
    sidebar: false,
    permission: ["edit.logement"],
    path: "/logements/ajouter",
    name: "Logements",
    component: LogementsForm,
  },
  {
    permission: ["view.logement", "edit.logement"],
    path: "/logements",
    name: "Logements",
    icon: Home,
    component: Logements,
    fullscreen: true,
    logs: true,
  },
  {
    sidebar: false,
    permission: ["view.amo", "edit.amo"],
    path: "/amo/detail/:id",
    name: "AMO",
    component: AmoView,
  },
  {
    sidebar: false,
    permission: ["edit.amo"],
    path: "/amo/modifier/:id",
    name: "AMO",
    component: AmoForm,
  },
  {
    sidebar: false,
    permission: ["edit.amo"],
    path: "/amo/ajouter",
    name: "AMO",
    component: AmoForm,
  },
  {
    permission: ["view.amo", "edit.amo"],
    path: "/amo",
    name: "AMO",
    icon: Build,
    component: Amo,
    fullscreen: true,
    logs: true,
  },
  {
    sidebar: false,
    permission: ["view.esignature", "edit.esignature"],
    path: "/esignatures/detail/:id",
    name: "Esignatures",
    component: EsignatureView,
  },
  {
    permission: ["view.esignature", "edit.esignature"],
    path: "/esignatures",
    name: "Esignatures",
    icon: HistoryEdu,
    component: Esignatures,
    fullscreen: true,
    logs: true,
  },
  // redirects
  {
    redirect: true,
    permission: ["view.structurenationale", "edit.structurenationale"],
    path: "/structure-nationale",
    pathTo: "/structure-nationale",
  },
  {
    redirect: true,
    permission: ["view.structurelocale", "edit.structurelocale"],
    path: "/structures-locales",
    pathTo: "/structures-locales",
  },
  {
    redirect: true,
    permission: ["view.mission", "edit.mission"],
    path: "/missions",
    pathTo: "/missions",
  },
  {
    redirect: true,
    permission: ["view.beneficiaire", "edit.beneficiaire"],
    path: "/beneficiaires",
    pathTo: "/beneficiaires",
  },
  {
    redirect: true,
    permission: ["view.logement", "edit.logement"],
    path: "/logements",
    pathTo: "/logements",
  },
  {
    redirect: true,
    permission: ["view.amo", "edit.amo"],
    path: "/amo",
    pathTo: "/amo",
  },
  {
    redirect: true,
    permission: ["view.esignature", "edit.esignature"],
    path: "/esignatures",
    pathTo: "/esignatures",
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/dashboard",
  },
];

export default dashRoutes;
