import LoginForm from "views/Forms/Auth/LoginForm";
import ForgotPasswordFrom from "views/Forms/Auth/ForgotPasswordFrom.jsx";
import ResetPasswordFrom from "views/Forms/Auth/ResetPasswordFrom.jsx";

var indexRoutes = [
  {
    exact: true,
    path: "/",
    name: "Login",
    component: LoginForm,
  },
  {
    exact: true,
    path: "/forgot-password",
    name: "Forgot Password",
    component: ForgotPasswordFrom,
  },
  {
    exact: true,
    path: "/reset-password",
    name: "Reset Password",
    component: ResetPasswordFrom,
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/",
    name: "Login",
  },
];

export default indexRoutes;
