import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// @mui/icons-material
import List from "@mui/icons-material/List";
import Edit from "@mui/icons-material/Edit";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Description from "@mui/icons-material/Description";
import AccountBalance from "@mui/icons-material/AccountBalance";
import Place from "@mui/icons-material/Place";

// Services
import SearchBarService from "services/SearchBarService";

// core components
import Button from "components/Button/Button.jsx";
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import DisableLink from "components/DisableLink/DisableLink.jsx";
import Render from "Utils/RenderUtils";
import MenuBottom from "components/MenuBottom/MenuBottom";
import AgentTable from "components/Table/AgentTable";
import Ged from "views/Tables/Ged";
import Image from "components/Image/Image";
import AdresseView from "./Adresse/AdresseView";
import { withUserContext } from "context/UserContext";

class StructureNationaleView extends React.Component {
  constructor(props) {
    super(props);
    // this.QUERY_PARAMS = new URLSearchParams(props.location.search);
    this.USER = props.user;
    this.CAN_EDIT = this.USER.can("edit.structurenationale");
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    this.CAN_EDIT_AGENT = this.USER.can("edit.agentnational");
    this.CAN_DELETE_AGENT = this.USER.can("delete.agentnational");
    this.GED_SN_PERMISSIONS = {
      CAN_VIEW: this.USER.can("view.ged.portail.structurenationale"),
      CAN_EDIT: this.USER.can("edit.ged.portail.structurenationale"),
      CAN_DELETE: this.USER.can("delete.ged.portail.structurenationale"),
    };

    this.BACKEND_URL = "/structures-nationales";
    this.FRONT_URL = "/structure-nationale";
    const storeSearchBar = new SearchBarService(this.FRONT_URL);
    this.prevURL = storeSearchBar.getOffsetUrl(props.match.params.id, -1);
    this.nextURL = storeSearchBar.getOffsetUrl(props.match.params.id);

    this.state = {
      // NESTED DATA
      adresse: null,
      agents: [],
      // DATA FORM
      nom: "",
      code_comptable: "",
      site_internet: "",
      email_contact: "",
      url_logo: null,
      siret: "",
      // END DATA FORM
      open: false,
      id: 1,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = result.data ?? {};
        this.setState(state);
      });
  }

  render() {
    const { adresse } = this.state;

    return (
      <Grid container columnSpacing={2}>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <AccountBalance />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Structure Nationale{Render.ifNotEmpty(this.state.nom, " : ")}
                </Typography>
              }
            />
            <CardContent>
              <Image src={this.state.url_logo} alt="logo" />
              <Typography>
                <strong>Code comptable : </strong>
                {this.state.code_comptable}
              </Typography>
              <Typography>
                <strong>Siret : </strong>
                {Render.siret(this.state.siret)}
              </Typography>
              <Typography>
                <strong>Site internet : </strong>
                {Render.externalLink(this.state.site_internet)}
              </Typography>
              <Typography>
                <strong>Email contact : </strong>
                {Render.email(this.state.email_contact)}
              </Typography>
            </CardContent>
          </Card>
          <AgentTable
            backendUrl="/agents-nationaux"
            configTitreUrl="/config-an-titre"
            structureId={this.state.id}
            canEdit={this.CAN_EDIT_AGENT}
            canDelete={this.CAN_DELETE_AGENT}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Place />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Adresse
                </Typography>
              }
            />
            <CardContent>
              <AdresseView adresse={adresse} hideAdresseDetails />
            </CardContent>
          </Card>
          <Ged
            rootFolder="documents"
            section="portail"
            type="structurenationale"
            objectId={this.state.id}
            permissions={this.GED_SN_PERMISSIONS}
            frontUrl={this.FRONT_URL}
          />
        </Grid>
        <Grid item xs={12} lg={6} />
        <MenuBottom>
          <Link to={this.FRONT_URL}>
            <Button square round>
              <List />
            </Button>
          </Link>
          <DisableLink to={this.prevURL}>
            <Button square round disabled={this.prevURL === null}>
              <ArrowBack />
            </Button>
          </DisableLink>
          <DisableLink to={this.nextURL}>
            <Button square round disabled={this.nextURL === null}>
              <ArrowForward />
            </Button>
          </DisableLink>
          {this.CAN_EDIT && (
            <Link to={this.FRONT_URL + "/modifier/" + this.state.id}>
              <Button color="onyx" square round>
                <Edit />
              </Button>
            </Link>
          )}
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link
              to={
                this.FRONT_URL +
                "/logs?type=StructureNationale&id=" +
                this.state.id
              }
            >
              <Button square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
      </Grid>
    );
  }
}

StructureNationaleView.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.any,
};

export default withUserContext(StructureNationaleView);
