import React, { Component } from "react";
import PropTypes from "prop-types";

import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Check from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";

class FormSubmitButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { onCancel, isEdit, ...rest } = this.props;

    return (
      <>
        <LoadingButton sx={{ mr: 1 }} size="small" color="success" {...rest}>
          <Check /> {isEdit ? <>Enregistrer</> : <>Ajouter</>}
        </LoadingButton>
        <Button size="small" color="error" onClick={onCancel}>
          <Close /> Annuler
        </Button>
      </>
    );
  }
}

FormSubmitButtons.propTypes = {
  onCancel: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
};

export default FormSubmitButtons;
