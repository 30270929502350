import React, { Component } from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";
import AgGridUtils from "Utils/AgGridUtils";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// @mui/icons-material
import Description from "@mui/icons-material/Description";
import GetApp from "@mui/icons-material/GetApp";
import Assignment from "@mui/icons-material/Assignment";

// core components
import Button from "components/Button/Button.jsx";
import Card from "components/CardV2/Card.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import SearchBar from "components/SearchBar/SearchBar.jsx";
import SearchBarService from "services/SearchBarService";
import MenuBottom from "components/MenuBottom/MenuBottom";
import { withUserContext } from "context/UserContext";
import GedUtils from "Utils/GedUtils";
import FileUtils from "Utils/FileUtils";
import Render from "Utils/RenderUtils";

class Esignatures extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");

    this.BACKEND_URL = "/esignatures";
    this.FRONT_URL = "/esignatures";
    this.SEARCH_BAR_SERVICE = new SearchBarService(this.FRONT_URL);
    this.AG_UTILS = new AgGridUtils(this.FRONT_URL);
    this.gridApi = null;
    this.gridColumnApi = null;
    this.filtersRef = React.createRef();

    this.state = {
      data: null,
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        cellDataType: "text",
      },
      columnDefs: this.AG_UTILS.applyOrder([
        {
          headerName: "#",
          field: "id",
          sort: "desc",
          cellDataType: "number",
        },
        {
          headerName: "Entitée de référence",
          field: "signable_type",
          hide: props.dense,
          valueGetter: (params) =>
            GedUtils.renderType(params.data.signable_type) +
            " (" +
            params.data.signable_id +
            ")",
        },
        {
          headerName: "Statut",
          field: "statut",
        },
        {
          headerName: "Dernière mise à jour",
          field: "date_modification",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.dateTime(params.value),
        },
        {
          headerName: "Signataires",
          field: "signataires",
          valueGetter: (params) =>
            params.data.signataires
              .map((s) => `${s.prenom ?? ""} ${s.nom ?? ""}`)
              .join(", "),
        },
        {
          headerName: "Fichier Non Signé",
          field: "fichier_non_signe",
          valueGetter: (params) =>
            FileUtils.getFilenameFromPath(params.data.fichier_non_signe),
        },
        {
          headerName: "Fichier Signé",
          field: "fichier_signe",
          valueGetter: (params) =>
            FileUtils.getFilenameFromPath(params.data.fichier_signe),
        },
        {
          headerName: "Identifiant",
          field: "identifiant",
        },
        {
          headerName: "Création",
          field: "date_creation",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.dateTime(params.value),
        },
        {
          headerName: "Envoi",
          field: "date_envoi",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.dateTime(params.value),
        },
        {
          headerName: "Complétion",
          field: "date_complete",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.dateTime(params.value),
        },
      ]),
      quickFilterText: this.SEARCH_BAR_SERVICE.retrieveSearchValue(),
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    const { signable_type, signable_id, section } = this.props;
    const params = {
      signable_type: signable_type,
      signable_id: signable_id,
      section: section,
    };

    axiosApiBackend.get(this.BACKEND_URL, { params: params }).then((result) => {
      this.setState({ data: result.data }, () => {
        this.SEARCH_BAR_SERVICE.storeSearchHistory(this.gridApi);
      });
    });
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  autoSizeAll = () => {
    if (this.gridColumnApi !== null) {
      this.gridColumnApi.autoSizeAllColumns();
    }
  };

  onColumnMoved = () => {
    if (this.gridColumnApi !== null) {
      this.AG_UTILS.saveOrder(this.gridColumnApi);
    }
  };

  goToView = (event) => {
    this.props.history.push(this.FRONT_URL + "/detail/" + event.node.data.id);
  };

  onBlur = (event) => {
    this.SEARCH_BAR_SERVICE.storeSearch(event.target.value, this.gridApi);
  };

  resetSearchValue = () => {
    this.SEARCH_BAR_SERVICE.storeSearch(null, this.gridApi, true);
    this.setState({ quickFilterText: "" });
  };

  render() {
    const { dense } = this.props;

    return (
      <Grid container sx={{ px: 1 }}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Assignment />
                </CardAvatar>
              }
              title={
                <Typography variant="h5">
                  {dense ? <>Esignatures</> : <>Gestion des Esignatures</>}
                </Typography>
              }
            />
            <CardContent>
              {!dense && (
                <Grid container alignItems="flex-end" ref={this.filtersRef}>
                  <Grid item>
                    <SearchBar
                      label="Rechercher"
                      name="quickFilterText"
                      value={this.state.quickFilterText}
                      onChange={this.onChange}
                      onBlur={this.onBlur}
                      resetSearchValue={this.resetSearchValue}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12} className="ag-theme-material">
                <AgGridReact
                  overlayNoRowsTemplate="Aucune donnée à afficher."
                  enableCellTextSelection={true}
                  animateRows={true}
                  onGridReady={this.onGridReady}
                  rowSelection="multiple"
                  columnDefs={this.state.columnDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowData={this.state.data}
                  quickFilterText={this.state.quickFilterText}
                  pagination={true}
                  onRowDoubleClicked={this.goToView}
                  domLayout="autoHeight"
                  paginationPageSize={AgGridUtils.getPageSize(
                    window.innerHeight,
                    this.filtersRef,
                  )}
                  onPaginationChanged={this.autoSizeAll}
                  localeText={AG_GRID_LOCALE}
                  onColumnMoved={this.onColumnMoved}
                  suppressDragLeaveHidesColumns={true}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <MenuBottom>
          {/* <Link to={this.FRONT_URL + "/ajouter"}>
            <Button square round>
              <AddBox />
            </Button>
          </Link> */}
          <Button
            square
            round
            onClick={() => AgGridUtils.exportCSV(this.gridApi)}
          >
            <GetApp />
          </Button>
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link to={this.FRONT_URL + "/logs?type=Esignature"}>
              <Button square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
      </Grid>
    );
  }
}

Esignatures.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  user: PropTypes.object,
  signable_type: PropTypes.any,
  signable_id: PropTypes.any,
  section: PropTypes.any,
  dense: PropTypes.any,
};

export default withUserContext(Esignatures);
