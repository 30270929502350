import React from "react";
import {
  axiosApiBackend,
  axiosApiBackendNoJson,
} from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// @mui/icons-material
import AccountBalance from "@mui/icons-material/AccountBalance";
import Place from "@mui/icons-material/Place";

// core components
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import FormSubmitButtons from "components/CustomButtons/FormSubmitButtons";
import TextInput from "components/CustomInput/TextInput";
import ImagePicker from "components/CustomUpload/ImagePicker";
import AdresseForm from "./Adresse/AdresseForm";

class StructureNationaleForm extends React.Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/structures-nationales";
    this.FRONT_URL = "/structure-nationale";

    this.state = {
      // NESTED DATA
      adresse: null,
      agents: [],
      // DATA FORM
      nom: "",
      code_comptable: "",
      site_internet: "",
      email_contact: "",
      siret: "",
      // END DATA FORM
      logos: [],
      loading: false,
      dep_code: process.env.REACT_APP_DEFAULT_DEPARTEMENT_CODE ?? "",
      errors: null,
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.loadAsyncData();
    }
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = result.data ?? {};
        const cp = result.data.ville?.cp;
        state.dep_code = cp ? cp.substring(0, 2) : "";
        this.setState(state);
      });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeAdresse = (newAdresse) => {
    this.setState((prevState) => ({
      adresse: { ...prevState.adresse, ...newAdresse },
    }));
  };

  handleSubmit = () => {
    const { id } = this.state;

    // handle data of structure nationale
    const dataIn = {
      nom: this.state.nom,
      code_comptable: this.state.code_comptable,
      site_internet: this.state.site_internet,
      email_contact: this.state.email_contact,
      siret: this.state.siret,
      agents: this.state.agents.map((a) => a.id),
      adresse: this.state.adresse,
    };

    let request = null;
    if (id) {
      // modify
      request = axiosApiBackend.put(this.BACKEND_URL + "/" + id, dataIn);
    } else {
      // create
      request = axiosApiBackend.post(this.BACKEND_URL, dataIn);
    }
    request
      .then((res) => {
        if (this.state.logos.length > 0) {
          this.handleLogo(res.data.id);
        } else {
          this.handleSuccess();
        }
      })
      .catch((err) => {
        this.setState({
          errors: err.response?.data?.errors ?? {},
          loading: false,
        });
      });

    this.setState({
      errors: null,
      logoError: null,
      loading: true,
    });
  };

  handleLogo = (id) => {
    const formData = new FormData();
    this.state.logos.forEach((filePond) => {
      formData.append("logo", filePond.file);
    });

    axiosApiBackendNoJson
      .post(`${this.BACKEND_URL}/${id}/logo`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(() => {
        this.handleSuccess();
      })
      .catch((err) => {
        this.setState({
          logoError: err.response?.data?.error ?? "",
          loading: false,
        });
      });
  };

  handleSuccess = () => {
    this.props.history.push(this.FRONT_URL);
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const { id, adresse, logoError, errors } = this.state;

    return (
      <Grid container columnSpacing={2}>
        {errors && (
          <Grid item xs={12}>
            <Typography variant="h6" component="h4" color="error">
              {Object.entries(errors).length === 0 ? (
                <>Une erreur est survenue.</>
              ) : (
                <>Merci de corriger les champs en rouge du formulaire.</>
              )}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <AccountBalance />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  {this.state.id ? (
                    <>Modifier la Structure Nationale</>
                  ) : (
                    <>Ajouter une Structure Nationale</>
                  )}
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2}>
                <Grid item xs={12}>
                  <ImagePicker
                    label="Ajoutez le nouveau logo<br>(MAX 10Mo)"
                    value={this.state.logos}
                    onChange={(files) => {
                      this.setState({ logos: files });
                    }}
                  />
                  <Typography variant="h6" component="h4" color="error">
                    {logoError}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Nom"
                    name="nom"
                    value={this.state.nom}
                    onChange={this.handleChange}
                    error={Boolean(errors?.nom)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Code comptable"
                    name="code_comptable"
                    value={this.state.code_comptable}
                    onChange={this.handleChange}
                    error={Boolean(errors?.code_comptable)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Siret"
                    name="siret"
                    value={this.state.siret}
                    onChange={this.handleChange}
                    error={Boolean(errors?.siret)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Site internet"
                    name="site_internet"
                    value={this.state.site_internet}
                    onChange={this.handleChange}
                    error={Boolean(errors?.site_internet)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Email contact"
                    name="email_contact"
                    value={this.state.email_contact}
                    onChange={this.handleChange}
                    error={Boolean(errors?.email_contact)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Place />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Adresse
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2}>
                <AdresseForm
                  adresse={adresse}
                  onChange={this.handleChangeAdresse}
                  errors={errors}
                  isEditMode={Boolean(id)}
                  hideAdresseDetails
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <FormSubmitButtons
            onClick={this.handleSubmit}
            onCancel={this.handleCancel}
            loading={this.state.loading}
            isEdit={Boolean(this.state.id)}
          />
        </Grid>
      </Grid>
    );
  }
}

StructureNationaleForm.propTypes = {
  location: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
};

export default StructureNationaleForm;
