import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// @mui/icons-material
import List from "@mui/icons-material/List";
import Edit from "@mui/icons-material/Edit";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Description from "@mui/icons-material/Description";
import Delete from "@mui/icons-material/Delete";
import Home from "@mui/icons-material/Home";

// Services
import SearchBarService from "services/SearchBarService";

// core components
import Button from "components/Button/Button.jsx";
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import DisableLink from "components/DisableLink/DisableLink.jsx";
import MenuBottom from "components/MenuBottom/MenuBottom";
import AlertDialog from "components/AlertDialog/AlertDialog";
import AdresseView from "./Adresse/AdresseView";
import Ged from "views/Tables/Ged";
import { withUserContext } from "context/UserContext";

class LogementsView extends React.Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_EDIT = this.USER.can("edit.logement");
    this.CAN_DELETE = this.USER.can("delete.logement");
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    this.GED_LOGEMENT_PERMISSIONS = {
      CAN_VIEW: this.USER.can("view.ged.portail.logement"),
      CAN_EDIT: this.USER.can("edit.ged.portail.logement"),
      CAN_DELETE: this.USER.can("delete.ged.portail.logement"),
    };

    this.BACKEND_URL = "/logements";
    this.FRONT_URL = "/logements";
    const storeSearchBar = new SearchBarService(this.FRONT_URL);
    this.prevURL = storeSearchBar.getOffsetUrl(props.match.params.id, -1);
    this.nextURL = storeSearchBar.getOffsetUrl(props.match.params.id);

    this.state = {
      // NESTED DATA
      beneficiaire: null,
      adresse: null,
      // DATA FORM
      // END DATA FORM
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = result.data ?? {};
        this.setState(state);
      });
  }

  clearAlert = () => {
    this.setState({ alert: null });
  };

  handleDeleteAlert = () => {
    axiosApiBackend
      .delete(this.BACKEND_URL + "/" + this.state.id)
      .then(() => {
        this.props.history.push(this.FRONT_URL);
      })
      .catch((err) => {
        if (err.response.status === 428) {
          const entities = err.response.data;
          this.onError(entities);
        }
      });
  };

  onError = (entities) => {
    this.setState({
      alert: (
        <AlertDialog
          title="Impossible de supprimer ce Logement : "
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Ok"
          cancelColor="info"
          fullWidth
        >
          <ul>
            {entities.amo > 0 && <li>{entities.amo} AMO(s) associé(s)</li>}
          </ul>
        </AlertDialog>
      ),
    });
  };

  onClickDeleteButton = () => {
    this.setState({
      alert: (
        <AlertDialog
          title="Voulez-vous supprimer le Logement ?"
          onConfirm={this.handleDeleteAlert}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="info"
          fullWidth
        />
      ),
    });
  };

  render() {
    const { beneficiaire, adresse } = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Home />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Logement
                </Typography>
              }
            />
            <CardContent>
              <Typography>
                <strong>Bénéficiaire : </strong>
                <Link to={"/beneficiaires/detail/" + beneficiaire?.id}>
                  {beneficiaire?.prenom} {beneficiaire?.nom}
                </Link>
              </Typography>
              <AdresseView adresse={adresse} hideRegion />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Ged
            rootFolder="documents"
            section="portail"
            type="logement"
            objectId={this.state.id}
            permissions={this.GED_LOGEMENT_PERMISSIONS}
            frontUrl={this.FRONT_URL}
          />
        </Grid>
        <MenuBottom>
          <Link to={this.FRONT_URL}>
            <Button square round>
              <List />
            </Button>
          </Link>
          <DisableLink to={this.prevURL}>
            <Button square round disabled={this.prevURL === null}>
              <ArrowBack />
            </Button>
          </DisableLink>
          <DisableLink to={this.nextURL}>
            <Button square round disabled={this.nextURL === null}>
              <ArrowForward />
            </Button>
          </DisableLink>
          {this.CAN_EDIT && (
            <Link to={this.FRONT_URL + "/modifier/" + this.state.id}>
              <Button color="onyx" square round>
                <Edit />
              </Button>
            </Link>
          )}
          {this.CAN_DELETE && this.state.id && (
            <Button
              color="error"
              square
              round
              onClick={this.onClickDeleteButton}
            >
              <Delete />
            </Button>
          )}
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link
              to={this.FRONT_URL + "/logs?type=Logement&id=" + this.state.id}
            >
              <Button square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
        {this.state.alert}
      </Grid>
    );
  }
}

LogementsView.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(LogementsView);
