import axios from "axios";
import UiCustomization from "context/UiCustomization";
import User from "context/User";

const uiCustomization = new UiCustomization();
const user = new User();

function checkUiCustomizationAndUser(headers = [], request = null) {
  const responseURL = request?.responseURL ?? null;

  if (
    responseURL != null &&
    (responseURL.includes("/api" + uiCustomization.getRefreshUrl()) ||
      responseURL.includes("/api" + user.getRefreshUrl()))
  ) {
    // we skip for this url
    return;
  }

  if (uiCustomization.isOutdated(headers)) {
    uiCustomization.refresh();
  }

  if (user.isOutdated(headers)) {
    user.refresh();
  }
}

function createAxiosConfig(headers = []) {
  /**
   * Axios config to reach Backend Api
   */
  const axiosConfig = axios.create({
    baseURL: window?._env_?.BACKEND_BASE_URL + "/api",
  });

  // Set defaults after instance has been created
  const token = user.access_token;
  if (token !== null) {
    axiosConfig.defaults.headers.common["Authorization"] = "Bearer " + token;
  }

  for (const key in headers) {
    axiosConfig.defaults.headers.common[key] = headers[key];
  }

  axiosConfig.interceptors.response.use(
    function (response) {
      checkUiCustomizationAndUser(response.headers, response.request);
      return response;
    },
    async function (error) {
      const status = error?.response?.status;
      if (status === 422) {
        checkUiCustomizationAndUser(error.response?.headers, error.request);
      } else if (status === 401) {
        // token is invalid
        user.loadData();
        if (token === user.access_token) {
          // pour les cas de token invalidé côté backend
          user.cleanData();
        }
        window.location.reload();
      }
      return Promise.reject(error);
    },
  );
  return axiosConfig;
}

function createAxiosNoAuthConfig(headers = []) {
  const axiosConfig = axios.create({
    baseURL: window?._env_?.BACKEND_BASE_URL + "/api",
  });

  for (const key in headers) {
    axiosConfig.defaults.headers.common[key] = headers[key];
  }
  return axiosConfig;
}

const axiosApiBackend = createAxiosConfig({
  "Content-Type": "application/json",
  Accept: "application/json",
});
const axiosApiBackendNoJson = createAxiosConfig();
const axiosApiBackendNoAuth = createAxiosNoAuthConfig({
  "Content-Type": "application/json",
  Accept: "application/json",
});

export default axios;
export {
  axiosApiBackend,
  axiosApiBackendNoJson,
  axiosApiBackendNoAuth,
  uiCustomization,
  user,
};
