import React, { Component } from "react";
import PropTypes from "prop-types";

// @mui/material components
import Button from "@mui/material/Button";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";

// @mui/icons-material
import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// core components
import MissionUtils from "Utils/MissionUtils";

class ActiviteTreeView extends Component {
  constructor(props) {
    super(props);
    this.organizedMissions = this.activitiesByMission(props.activites);

    this.state = { expanded: this.getExpandedIds(this.organizedMissions) };
  }

  getExpandedIds = (missions) => {
    return missions.map((mission) => "mission-" + mission.id.toString());
  };

  handleToggle = (event, nodeIds) => {
    this.setState({ expanded: nodeIds });
  };

  handleExpandClick = () => {
    this.setState((prevState) => {
      return {
        expanded:
          prevState.expanded.length === 0
            ? this.getExpandedIds(this.organizedMissions)
            : [],
      };
    });
  };

  getMissionState = (missionId) => {
    const etatMission = this.props.etatMissions.find(
      (m) => m.mission_id === missionId,
    );

    return etatMission.etat;
  };

  calculatePartialMissionCost = (mission) => {
    return mission.activites.reduce(
      (sum, activite) => sum + activite.cout_facturation,
      0,
    );
  };

  activitiesByMission = (activites) => {
    const data = [];
    const missionMap = {};

    activites.forEach((activite) => {
      if (!missionMap[activite.mission.id]) {
        const newMission = {
          id: activite.mission.id,
          titre: activite.mission.titre,
          cout_facturation: activite.mission.cout_facturation,
          description: activite.mission.description,
          activites: [],
        };
        data.push(newMission);
        missionMap[activite.mission.id] = newMission;
      }

      missionMap[activite.mission.id].activites.push({
        id: activite.id,
        titre: activite.titre,
        cout_facturation: activite.cout_facturation,
        description: activite.description,
      });
    });

    // Sort missions
    data.sort((a, b) => a.titre.localeCompare(b.titre));

    // Sort activités
    data.forEach((mission) => {
      mission.activites.sort((a, b) => a.titre.localeCompare(b.titre));
    });

    return data;
  };

  render() {
    const { expanded } = this.state;

    return (
      <>
        <Button
          onClick={this.handleExpandClick}
          sx={{ fontSize: 10, mb: 1 }}
          size="small"
          startIcon={expanded?.length === 0 ? <Add /> : <Remove />}
        >
          {expanded?.length === 0 ? "Tout ouvrir" : "Tout fermer"}
        </Button>
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          disableSelection
          expanded={expanded}
          onNodeToggle={this.handleToggle}
        >
          {this.organizedMissions.map((mission) => {
            const missionState = this.getMissionState(mission.id);
            const missionCost =
              missionState === "partiel"
                ? this.calculatePartialMissionCost(mission)
                : mission.cout_facturation;

            return (
              <TreeItem
                key={mission.id}
                nodeId={`mission-${mission.id}`}
                label={MissionUtils.renderTreeItem(mission, missionCost)}
              >
                {mission.activites.map((activite) => (
                  <TreeItem
                    key={`activite-${activite.id}`}
                    nodeId={activite.id.toString()}
                    label={MissionUtils.renderTreeItem(
                      activite,
                      null,
                      missionState === "partiel",
                    )}
                  />
                ))}
              </TreeItem>
            );
          })}
        </TreeView>
      </>
    );
  }
}

ActiviteTreeView.propTypes = {
  activites: PropTypes.array.isRequired,
  etatMissions: PropTypes.array.isRequired,
};

export default ActiviteTreeView;
