import React from "react";
import PropTypes from "prop-types";
import Button from "components/Button/Button";

// @mui/material components

// @mui/icons-material

class DocumentTemplateRenderer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  displayButton = (button) => {
    return (
      <Button
        key={button.fileName}
        loading={button.loading}
        square
        onClick={button.onClick}
      >
        {button.label}
      </Button>
    );
  };

  render() {
    return this.props.buttons.map((button) => this.displayButton(button));
  }
}

DocumentTemplateRenderer.propTypes = {
  buttons: PropTypes.array,
};

export default DocumentTemplateRenderer;
