import DisableLink from "components/DisableLink/DisableLink";
import React from "react";

export default class LogementUtils {
  static renderForList = (logement) => {
    const prenom = logement.beneficiaire?.prenom ?? "";
    const nom = logement.beneficiaire?.nom ?? "";
    const numero_voie = logement.adresse?.numero_voie ?? "";
    const type_voie = logement.adresse?.type_voie ?? "";
    const nom_voie = logement.adresse?.nom_voie ?? "";
    const cp = logement.adresse?.ville?.cp ?? "";
    const ville = logement.adresse?.ville?.ville ?? "";

    return `${prenom} ${nom} - ${numero_voie} ${type_voie} ${nom_voie} ${cp} ${ville} (${logement.id})`;
  };

  static buildRowsForLinks = (amos) => {
    let rows = [];
    amos = amos ?? [];
    const amoForLinks = amos.map((amo) => {
      let span = 0;
      amo.span = span > 0 ? span : 1;
      return amo;
    });

    amoForLinks.forEach((amo) => {
      rows.push({
        dossier_travaux: amo,
      });
    });

    return rows;
  };

  static renderInfo = (logement, withLink = true) => {
    if (logement == null) {
      return;
    }

    const url = withLink ? "/logements/detail/" + logement.id : null;

    return (
      <DisableLink to={url}>
        {logement.adresse?.numero_voie} {logement.adresse?.type_voie}{" "}
        {logement.adresse?.nom_voie} {logement.adresse?.ville?.cp}{" "}
        {logement.adresse?.ville?.ville} ({logement.id})
      </DisableLink>
    );
  };
}
