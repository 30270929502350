import React, { useState } from "react";
import PropTypes from "prop-types";

// @mui/material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

// @mui/icons-material
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";

// components
import PaperList from "components/PaperList/PaperList";
import AlertDialog from "components/AlertDialog/AlertDialog";
import Button from "components/Button/Button";

function PaperListForm({
  datas,
  name,
  onChange,
  buildFormItem,
  buildDeleteTitle,
  buildCantClickDeleteButtonText,
  addButtonText,
}) {
  const [alert, setAlert] = useState([]);

  const handleChange = (key) => (event) => {
    datas[key][event.target.name] = event.target.value;
    onChange(name, datas);
  };

  const handleAdd = () => {
    datas.push({});
    onChange(name, datas);
  };

  const clearAlert = () => {
    setAlert(null);
  };

  const confirmDelete = (key, data) => () => {
    setAlert(
      <AlertDialog
        title={buildDeleteTitle(data)}
        onConfirm={handleDelete(key)}
        confirmLabel="Supprimer"
        confirmColor="error"
        onCancel={clearAlert}
        cancelLabel="Annuler"
        cancelColor="primary"
      />,
    );
  };

  const handleDelete = (key) => () => {
    clearAlert();
    datas.splice(key, 1);
    onChange(name, datas);
  };

  return (
    <>
      <PaperList
        datas={datas}
        buildBody={(data, key) => {
          const cantClickDeleteButtonText =
            buildCantClickDeleteButtonText(data);

          return (
            <Grid container columnSpacing={1} alignItems="center">
              <Grid container item columnSpacing={2} xs alignItems="center">
                {buildFormItem(data, key, handleChange(key))}
              </Grid>
              <Grid item textAlign="right">
                {cantClickDeleteButtonText != null ? (
                  <>{cantClickDeleteButtonText}</>
                ) : (
                  <Tooltip title="Supprimer" placement="left">
                    <Button
                      square
                      color="error"
                      onClick={confirmDelete(key, data)}
                    >
                      <Delete />
                    </Button>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          );
        }}
      />
      <Grid container columnSpacing={2}>
        <Grid item xs={12} textAlign="center">
          <Tooltip title={addButtonText} placement="left">
            <Button onClick={handleAdd}>
              <Add />
              {addButtonText}
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
      {alert}
    </>
  );
}

PaperListForm.propTypes = {
  datas: PropTypes.array,
  name: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  buildFormItem: PropTypes.func,
  buildDeleteTitle: PropTypes.func,
  buildCantClickDeleteButtonText: PropTypes.func,
  addButtonText: PropTypes.any,
};

export default PaperListForm;
