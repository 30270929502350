import React, { Component } from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";
import AgGridUtils from "Utils/AgGridUtils";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// @mui/icons-material
import AddBox from "@mui/icons-material/AddBox";
import Description from "@mui/icons-material/Description";
import GetApp from "@mui/icons-material/GetApp";
import Home from "@mui/icons-material/Home";

// core components
import Button from "components/Button/Button.jsx";
import Card from "components/CardV2/Card.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import SearchBar from "components/SearchBar/SearchBar.jsx";
import SearchBarService from "services/SearchBarService";
import MenuBottom from "components/MenuBottom/MenuBottom";
import Render from "Utils/RenderUtils";
import { withUserContext } from "context/UserContext";

class Logements extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");

    this.BACKEND_URL = "/logements";
    this.FRONT_URL = "/logements";
    this.SEARCH_BAR_SERVICE = new SearchBarService(this.FRONT_URL);
    this.AG_UTILS = new AgGridUtils(this.FRONT_URL);
    this.gridApi = null;
    this.gridColumnApi = null;
    this.filtersRef = React.createRef();

    this.state = {
      data: null,
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        cellDataType: "text",
      },
      columnDefs: this.AG_UTILS.applyOrder([
        {
          headerName: "#",
          field: "id",
          sort: "desc",
          cellDataType: "number",
        },
        {
          headerName: "Bénéficiaire",
          field: "beneficiaire",
          valueGetter: (params) =>
            (params.data.beneficiaire?.prenom ?? "") +
            " " +
            (params.data.beneficiaire?.nom ?? ""),
        },
        {
          headerName: "Adresse",
          field: "adresse",
          valueGetter: (params) => Render.address(params.data.adresse),
        },
        { headerName: "Code postal", field: "adresse.ville.cp" },
        { headerName: "Ville", field: "adresse.ville.ville" },
        { headerName: "Résidence", field: "adresse.residence" },
        { headerName: "Bâtiment", field: "adresse.batiment" },
        { headerName: "Étage", field: "adresse.etage" },
        {
          headerName: "Latitude",
          field: "adresse.latitude",
          cellDataType: "number",
          valueFormatter: (params) =>
            params.value ? parseFloat(params.value) : "",
        },
        {
          headerName: "Longitude",
          field: "adresse.longitude",
          cellDataType: "number",
          valueFormatter: (params) =>
            params.value ? parseFloat(params.value) : "",
        },
      ]),
      quickFilterText: this.SEARCH_BAR_SERVICE.retrieveSearchValue(),
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend.get(this.BACKEND_URL).then((result) => {
      this.setState({ data: result.data }, () => {
        this.SEARCH_BAR_SERVICE.storeSearchHistory(this.gridApi);
      });
    });
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  autoSizeAll = () => {
    if (this.gridColumnApi !== null) {
      this.gridColumnApi.autoSizeAllColumns();
    }
  };

  onColumnMoved = () => {
    if (this.gridColumnApi !== null) {
      this.AG_UTILS.saveOrder(this.gridColumnApi);
    }
  };

  goToView = (event) => {
    this.props.history.push(this.FRONT_URL + "/detail/" + event.node.data.id);
  };

  onBlur = (event) => {
    this.SEARCH_BAR_SERVICE.storeSearch(event.target.value, this.gridApi);
  };

  resetSearchValue = () => {
    this.SEARCH_BAR_SERVICE.storeSearch(null, this.gridApi, true);
    this.setState({ quickFilterText: "" });
  };

  render() {
    return (
      <Grid container sx={{ px: 1 }}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Home />
                </CardAvatar>
              }
              title={
                <Typography variant="h5">Gestion des Logements</Typography>
              }
            />
            <CardContent>
              <Grid container alignItems="flex-end" ref={this.filtersRef}>
                <Grid item>
                  <SearchBar
                    label="Rechercher"
                    name="quickFilterText"
                    value={this.state.quickFilterText}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    resetSearchValue={this.resetSearchValue}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} className="ag-theme-material">
                <AgGridReact
                  overlayNoRowsTemplate="Aucune donnée à afficher."
                  enableCellTextSelection={true}
                  animateRows={true}
                  onGridReady={this.onGridReady}
                  rowSelection="multiple"
                  enableFilter={false}
                  columnDefs={this.state.columnDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowData={this.state.data}
                  quickFilterText={this.state.quickFilterText}
                  pagination={true}
                  onRowDoubleClicked={this.goToView}
                  domLayout="autoHeight"
                  paginationPageSize={AgGridUtils.getPageSize(
                    window.innerHeight,
                    this.filtersRef,
                  )}
                  onPaginationChanged={this.autoSizeAll}
                  localeText={AG_GRID_LOCALE}
                  onColumnMoved={this.onColumnMoved}
                  suppressDragLeaveHidesColumns={true}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <MenuBottom>
          <Link to={this.FRONT_URL + "/ajouter"}>
            <Button square round>
              <AddBox />
            </Button>
          </Link>
          <Button
            square
            round
            onClick={() => AgGridUtils.exportCSV(this.gridApi)}
          >
            <GetApp />
          </Button>
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link to={this.FRONT_URL + "/logs?type=Logement"}>
              <Button square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
      </Grid>
    );
  }
}

Logements.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(Logements);
