import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// @mui/icons-material

// components
import PaperList from "components/PaperList/PaperList";
import Render from "Utils/RenderUtils";
import MultilineTypography from "components/MultilineTypography/MultilineTypography";

function ActiviteView({ activites }) {
  return (
    <PaperList
      datas={activites}
      buildBody={(activite) => (
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} md={9}>
            <MultilineTypography>
              {activite.titre}
              {Render.ifNotEmpty(activite.description, " : ")}
            </MultilineTypography>
          </Grid>
          <Grid item xs={12} md={3} textAlign="right">
            <Typography>{Render.euro(activite.cout_facturation)}</Typography>
          </Grid>
        </Grid>
      )}
    />
  );
}

ActiviteView.propTypes = {
  activites: PropTypes.array,
};

export default ActiviteView;
