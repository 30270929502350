import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import Grid from "@mui/material/Grid";

// @mui/icons-material

// components
import TextInput from "components/CustomInput/TextInput";
import CurrencyInput from "components/CustomInput/CurrencyInput";
import PaperListForm from "components/PaperListForm/PaperListForm";

function ActiviteForm({ activites, name, onChange, errors }) {
  return (
    <PaperListForm
      datas={activites}
      name={name}
      onChange={onChange}
      buildFormItem={(activite, key, handleChange) => (
        <>
          <Grid item xs={12} lg={6}>
            <TextInput
              label="Titre"
              name="titre"
              value={activite.titre}
              onChange={handleChange}
              error={Boolean(errors?.[key + ".titre"])}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <CurrencyInput
              label="Coût de facturation"
              name="cout_facturation"
              value={activite.cout_facturation}
              onChange={handleChange}
              error={Boolean(errors?.[key + ".cout_facturation"])}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label="Description"
              name="description"
              value={activite.description}
              multiline
              minRows={2}
              onChange={handleChange}
              error={Boolean(errors?.[key + ".description"])}
            />
          </Grid>
        </>
      )}
      buildDeleteTitle={(activite) =>
        "Voulez-vous supprimer " + (activite?.titre ?? "") + " ?"
      }
      buildCantClickDeleteButtonText={(data) =>
        data.amos_count > 0 ? <>Lié à {data.amos_count} AMO(s)</> : null
      }
      addButtonText="Ajouter une activité"
    />
  );
}

ActiviteForm.propTypes = {
  activites: PropTypes.any,
  name: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.any,
};

export default ActiviteForm;
