import React from "react";
// import PropTypes from "prop-types";

// @mui/material components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// @mui/icons-material
import Info from "@mui/icons-material/Info";

// core components
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <Grid container>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar big>
                  <Info />
                </CardAvatar>
              }
              title={
                <Typography variant="h5" component="h4">
                  Portail national
                </Typography>
              }
            />
            <CardContent>
              <Typography>Portail national</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

Dashboard.propTypes = {};

export default Dashboard;
