export default class AgGridUtils {
  constructor(url) {
    this.URL = url;
  }

  applyOrder(columnDefs) {
    let colsOrder = JSON.parse(localStorage.getItem(`colsOrder${this.URL}`));
    if (colsOrder != null) {
      return columnDefs.sort((a, b) => colsOrder[a.field] - colsOrder[b.field]);
    }
    return columnDefs;
  }

  saveOrder(columnApi) {
    const cols = columnApi.getAllGridColumns();
    var colsOrder = {};
    for (const key in cols) {
      colsOrder[cols[key].getId()] = key;
    }
    localStorage.setItem(`colsOrder${this.URL}`, JSON.stringify(colsOrder));
  }

  static getPageSize(height, ref, min = 4, max = 50) {
    const filtersHeight = ref?.current?.clientHeight ?? 0;
    const pageSize = Math.trunc((height - 280 - filtersHeight) / 50);
    return Math.max(min, Math.min(max, pageSize));
  }

  static exportCSV(gridApi) {
    if (gridApi !== null) {
      let params = {
        columnSeparator: localStorage.getItem("export_separator") || ";",
      };
      gridApi.exportDataAsCsv(params);
    }
  }
}
