import React from "react";
import {
  axiosApiBackend,
  axiosApiBackendNoJson,
} from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// @mui/icons-material
import HomeWork from "@mui/icons-material/HomeWork";
import Place from "@mui/icons-material/Place";

// core components
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import SelectSearch from "components/CustomSelect/SelectSearch";
import FormSubmitButtons from "components/CustomButtons/FormSubmitButtons";
import TextInput from "components/CustomInput/TextInput";
import SelectSearchMultiple from "components/CustomSelect/SelectSearchMultiple";
import CheckBoxInput from "components/CustomInput/CheckBoxInput";
import PhoneInput from "components/PhoneInput/PhoneInput";
import ImagePicker from "components/CustomUpload/ImagePicker";
import AdresseForm from "./Adresse/AdresseForm";

class StructuresLocalesForm extends React.Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/structures-locales";
    this.FRONT_URL = "/structures-locales";

    this.state = {
      // NESTED DATA
      adresse: null,
      types_structures: [],
      metiers: [],
      typologie_couleur: null,
      // DATA FORM
      nom: "",
      code_comptable: "",
      over_50_employees: 0,
      date_agrement_code_construction_habitation: "",
      date_agrement_code_energie: "",
      site_internet: "",
      email_contact: "",
      telephone: "",
      typologie_couleur_id: "",
      siret: "",
      numero_police_assurance: "",
      // END DATA FORM
      logos: [],
      loading: false,
      dep_code: process.env.REACT_APP_DEFAULT_DEPARTEMENT_CODE ?? "",
      errors: null,
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.loadAsyncData();
    }
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = result.data ?? {};
        const cp = result.data.ville?.cp;
        state.dep_code = cp ? cp.substring(0, 2) : "";
        this.setState(state);
      });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChangeAdresse = (newAdresse) => {
    this.setState((prevState) => ({
      adresse: { ...prevState.adresse, ...newAdresse },
    }));
  };

  handleSubmit = () => {
    const { id } = this.state;

    const dataIn = {
      nom: this.state.nom,
      code_comptable: this.state.code_comptable,
      over_50_employees: this.state.over_50_employees,
      date_agrement_code_construction_habitation:
        this.state.date_agrement_code_construction_habitation,
      date_agrement_code_energie: this.state.date_agrement_code_energie,
      site_internet: this.state.site_internet,
      email_contact: this.state.email_contact,
      telephone: this.state.telephone,
      typologie_couleur_id: this.state.typologie_couleur_id,
      siret: this.state.siret,
      numero_police_assurance: this.state.numero_police_assurance,
      metiers: this.state.metiers.map((metier) => metier.id),
      types_structures: this.state.types_structures.map((type) => type.id),
      adresse: this.state.adresse,
    };

    let request = null;
    if (id) {
      // modify
      request = axiosApiBackend.put(this.BACKEND_URL + "/" + id, dataIn);
    } else {
      // create
      request = axiosApiBackend.post(this.BACKEND_URL, dataIn);
    }
    request
      .then((res) => {
        if (this.state.logos.length > 0) {
          this.handleLogo(res.data.id);
        } else {
          this.handleSuccess(res.data.id);
        }
      })
      .catch((err) => {
        this.setState({
          errors: err.response?.data?.errors ?? {},
          loading: false,
        });
      });

    this.setState({
      errors: null,
      logoError: null,
      loading: true,
    });
  };

  handleLogo = (id) => {
    const formData = new FormData();
    this.state.logos.forEach((filePond) => {
      formData.append("logo", filePond.file);
    });

    axiosApiBackendNoJson
      .post(`${this.BACKEND_URL}/${id}/logo`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(() => {
        this.handleSuccess(id);
      })
      .catch((err) => {
        this.setState({
          logoError: err.response?.data?.error ?? "",
          loading: false,
        });
      });
  };

  handleSuccess = (id) => {
    this.props.history.push(this.FRONT_URL + "/detail/" + id);
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const { id, adresse, logoError, errors } = this.state;

    return (
      <Grid container spacing={2}>
        {errors && (
          <Grid item xs={12}>
            <Typography variant="h6" component="h4" color="error">
              {Object.entries(errors).length === 0 ? (
                <>Une erreur est survenue.</>
              ) : (
                <>Merci de corriger les champs en rouge du formulaire.</>
              )}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <HomeWork />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  {this.state.id ? (
                    <>Modifier la Structure Locale</>
                  ) : (
                    <>Ajouter une Structure Locale</>
                  )}
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12}>
                  <ImagePicker
                    label="Ajoutez le nouveau logo<br>(MAX 10Mo)"
                    value={this.state.logos}
                    onChange={(files) => {
                      this.setState({ logos: files });
                    }}
                  />
                  <Typography variant="h6" component="h4" color="error">
                    {logoError}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Nom"
                    name="nom"
                    value={this.state.nom}
                    onChange={this.handleChange}
                    error={Boolean(errors?.nom)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Code comptable"
                    name="code_comptable"
                    value={this.state.code_comptable}
                    onChange={this.handleChange}
                    error={Boolean(errors?.code_comptable)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Siret"
                    name="siret"
                    value={this.state.siret}
                    onChange={this.handleChange}
                    error={Boolean(errors?.siret)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearchMultiple
                    label="Types de structures"
                    apiUrl="/config-sl-type-structure"
                    getOptionLabel={(data) => data.value}
                    onChange={(event, newValue) =>
                      this.setState({ types_structures: newValue })
                    }
                    value={this.state.types_structures}
                    error={Boolean(errors?.types_structures)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CheckBoxInput
                    label="Plus de 50 salariés"
                    name="over_50_employees"
                    value={this.state.over_50_employees}
                    onChange={this.handleChangeByName}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Date agrément code construction et habitation"
                    name="date_agrement_code_construction_habitation"
                    type="date"
                    value={
                      this.state.date_agrement_code_construction_habitation
                    }
                    onChange={this.handleChange}
                    error={Boolean(
                      errors?.date_agrement_code_construction_habitation,
                    )}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Date agrément code énergie"
                    name="date_agrement_code_energie"
                    type="date"
                    value={this.state.date_agrement_code_energie}
                    onChange={this.handleChange}
                    error={Boolean(errors?.date_agrement_code_energie)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Site internet"
                    name="site_internet"
                    value={this.state.site_internet}
                    onChange={this.handleChange}
                    error={Boolean(errors?.site_internet)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Email contact"
                    name="email_contact"
                    value={this.state.email_contact}
                    onChange={this.handleChange}
                    error={Boolean(errors?.email_contact)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <PhoneInput
                    label="Téléphone"
                    name="telephone"
                    value={this.state.telephone}
                    onChange={this.handleChangeByName}
                    error={Boolean(errors?.telephone)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearchMultiple
                    label="Metiers"
                    apiUrl="/config-sl-metier"
                    getOptionLabel={(data) => data.value}
                    onChange={(event, newValue) =>
                      this.setState({ metiers: newValue })
                    }
                    value={this.state.metiers}
                    error={Boolean(errors?.metiers)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    name="typologie_couleur_id"
                    label="Typologie couleur"
                    apiUrl="/config-sl-typologie-couleur"
                    onChange={this.handleChangeByName}
                    value={this.state.typologie_couleur_id}
                    buildOptionLabel={(data) => data.value}
                    buildOptionAdditionalData={(data) => ({
                      color: data.color,
                    })}
                    renderExtra={(data) => (
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          borderRadius: 1,
                          backgroundColor: data.color,
                          ml: 1,
                        }}
                      />
                    )}
                    shrink
                    error={Boolean(errors?.typologie_couleur_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Numéro de police d'assurance"
                    name="numero_police_assurance"
                    value={this.state.numero_police_assurance}
                    onChange={this.handleChange}
                    error={Boolean(errors?.numero_police_assurance)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Place />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Adresse
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2} alignItems="center">
                <AdresseForm
                  adresse={adresse}
                  onChange={this.handleChangeAdresse}
                  errors={errors}
                  isEditMode={Boolean(id)}
                  hideAdresseDetails
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <FormSubmitButtons
            onClick={this.handleSubmit}
            onCancel={this.handleCancel}
            loading={this.state.loading}
            isEdit={Boolean(this.state.id)}
          />
        </Grid>
      </Grid>
    );
  }
}

StructuresLocalesForm.propTypes = {
  location: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
};

export default StructuresLocalesForm;
