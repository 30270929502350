import React, { Component } from "react";
import PropTypes from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";

// @mui/icons-material
import DisplaySettings from "@mui/icons-material/DisplaySettings";

// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import SettingsFormContainer from "components/SettingsFormContainer/SettingsFormContainer";
import TextInput from "components/CustomInput/TextInput";
import SelectSearch from "components/CustomSelect/SelectSearch";

class UiCustomizationSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // DATA FORM
      dep_code: "",
      google_maps_key: "",
      // END DATA FORM
      errors: null,
    };
  }

  componentDidMount() {}

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const { errors } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <SettingsFormContainer
            icon={<DisplaySettings />}
            title="Modifier les Customisations UI"
            backendUrl="/settings/ui-customization"
            formSetData={(data) => {
              this.setState({
                errors: null,
                dep_code: data.dep_code,
                google_maps_key: data.google_maps_key,
              });
            }}
            formGetData={() => ({
              dep_code: this.state.dep_code,
              google_maps_key: this.state.google_maps_key,
            })}
            formSetErrors={(err) => {
              this.setState({
                errors: err,
              });
            }}
            formContent={
              <GridContainer>
                {errors && (
                  <GridItem xs={12}>
                    <Typography variant="h6" component="h4" color="error">
                      {Object.entries(errors).length === 0 ? (
                        <>Une erreur est survenue.</>
                      ) : (
                        <>
                          Merci de corriger les champs en rouge du formulaire.
                        </>
                      )}
                    </Typography>
                  </GridItem>
                )}
                <GridItem xs={12}>
                  <SelectSearch
                    label="Département"
                    name="dep_code"
                    apiUrl="/departements"
                    onChange={this.handleChangeByName}
                    value={this.state.dep_code}
                    buildOptionValue={(data) => data.code}
                    buildOptionLabel={(data) => data.code + " " + data.nom}
                    shrink
                    error={Boolean(errors?.dep_code)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    label="Clé Google Maps"
                    name="google_maps_key"
                    onChange={this.handleChange}
                    value={this.state.google_maps_key}
                    error={Boolean(errors?.google_maps_key)}
                  />
                </GridItem>
              </GridContainer>
            }
          />
        </GridItem>
      </GridContainer>
    );
  }
}

UiCustomizationSettings.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
};

export default UiCustomizationSettings;
