import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/material components
import Checkbox from "@mui/material/Checkbox";

// @mui/icons-material
import Description from "@mui/icons-material/Description";
import PeopleAlt from "@mui/icons-material/PeopleAlt";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Man from "@mui/icons-material/Man";

// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import TablePopUpContainer from "components/TablePopUpContainer/TablePopUpContainer";
import Render from "Utils/RenderUtils";
import Button from "components/Button/Button";
import MenuBottom from "components/MenuBottom/MenuBottom";
import SelectSearchMultiple from "components/CustomSelect/SelectSearchMultiple";
import TextInput from "components/CustomInput/TextInput";
import SelectSearch from "components/CustomSelect/SelectSearch";
import ConfigContainer from "components/ConfigContainer/ConfigContainer";
import { withUserContext } from "context/UserContext";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class UsersManager extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    this.BACKEND_URL = "/usersManager";
    this.FRONT_URL = "/configuration/users";
    this.LABEL = "Utilisateur(s)";
    this.HIDDEN_ROLES = [
      "taskmanager",
      "admin",
      "user",
      "unverified",
      "agentnational",
      "agentlocal",
    ];
    this.QUERY_PARAMS = new URLSearchParams(props.location.search);

    this.state = {
      deleted_at: null,
      // DATA FORM
      id: "",
      civilite_id: "",
      first_name: "",
      last_name: "",
      roles: [],
      email: "",
      created_at: "",
      updated_at: "",
      // END DATA FORM
      setErrs: null,
      errors: null,
      response: null,
    };
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChangeEvent = (event) => {
    this.handleChange(event.target.name, event.target.value);
  };

  render() {
    const { errors } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <TablePopUpContainer
            dialogProps={{
              maxWidth: "lg",
              fullWidth: true,
            }}
            icon={<PeopleAlt />}
            title="Gestion des utilisateurs"
            backendUrl={this.BACKEND_URL}
            frontUrl={this.FRONT_URL}
            etatFilter={this.QUERY_PARAMS.get("etat") ?? "activated"}
            searchBar
            filter
            filterColumns
            pagination
            paginationPageSize={10}
            hideButton={(data) =>
              data?.roles.some((role) => this.HIDDEN_ROLES.includes(role.slug))
            }
            labelFilter={[
              { label: "Actif", value: "activated" },
              { label: "Supprimé", value: "deleted" },
              { label: "Tous", value: "all" },
            ]}
            colDef={[
              {
                headerName: "Identifiant",
                field: "name",
                cellDataType: "text",
              },
              {
                headerName: "Email",
                field: "email",
                cellDataType: "text",
              },
              {
                headerName: "Civilité",
                field: "civilite.value",
                cellDataType: "text",
              },
              {
                headerName: "Prénom",
                field: "first_name",
                cellDataType: "text",
              },
              {
                headerName: "Nom",
                field: "last_name",
                cellDataType: "text",
              },
              {
                headerName: "Rôles",
                field: "roles",
                cellDataType: "text",
                valueGetter: (params) =>
                  params.data.roles.map((role) => role.name).join(", "),
              },
              {
                headerName: "Date de création",
                field: "created_at",
                cellDataType: "dateString",
                valueFormatter: (params) => Render.date(params.value),
              },
              {
                headerName: "Date de mise à jour",
                field: "updated_at",
                cellDataType: "dateString",
                valueFormatter: (params) => Render.date(params.value),
              },
            ]}
            formInitData={() => {
              this.setState({
                errors: null,
                id: "",
                civilite_id: "",
                roles: [],
                first_name: "",
                last_name: "",
                email: "",
                created_at: "",
                updated_at: "",
                deleted_at: null,
              });
            }}
            formSetData={(data) => {
              this.setState({
                errors: null,
                id: data.id,
                civilite_id: data.civilite_id,
                roles: data.roles ?? [],
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                deleted_at: data.deleted_at,
              });
            }}
            formGetData={() => ({
              id: this.state.id,
              civilite_id: this.state.civilite_id,
              roles: this.state.roles.map((role) => role.id),
              first_name: this.state.first_name,
              last_name: this.state.last_name,
              email: this.state.email,
              deleted_at: this.state.deleted_at,
            })}
            formSetErrors={(err) => {
              this.setState({
                errors: err,
              });
            }}
            formTitle={
              this.state.id
                ? "Modifier l'utilisateur"
                : "Ajouter un utilisateur"
            }
            formConfirmLabel={this.state.id ? "Modifier" : "Ajouter"}
            formContent={
              <GridContainer alignItems="start">
                <GridItem xs={12} lg={6}>
                  <TextInput
                    required
                    label="Prénom"
                    name="first_name"
                    value={this.state.first_name}
                    onChange={this.handleChangeEvent}
                    error={Boolean(errors?.first_name)}
                    helperText={errors?.first_name}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <TextInput
                    required
                    label="Nom"
                    name="last_name"
                    value={this.state.last_name}
                    onChange={this.handleChangeEvent}
                    error={Boolean(errors?.last_name)}
                    helperText={errors?.last_name}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <TextInput
                    required
                    label="Email"
                    name="email"
                    type="email"
                    value={this.state.email}
                    onChange={this.handleChangeEvent}
                    error={Boolean(errors?.email)}
                    helperText={errors?.email}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <SelectSearch
                    label="Civilité"
                    name="civilite_id"
                    apiUrl="/config-user-civilite"
                    onChange={this.handleChange}
                    value={this.state.civilite_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.civilite_id)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <SelectSearchMultiple
                    label="Rôles"
                    apiUrl="/roles?restrict=1"
                    onChange={(event, newValue) => {
                      this.setState({ roles: newValue });
                    }}
                    value={this.state.roles}
                    error={Boolean(errors?.roles)}
                    helperText={errors?.roles}
                    getOptionLabel={(option) => `${option.name}`}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.id}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          checked={selected}
                        />
                        {props.key}
                      </li>
                    )}
                    disableCloseOnSelect={true}
                    filterSelectedOptions={false}
                    limitTags={3}
                  />
                </GridItem>
              </GridContainer>
            }
            deleteDialog={{
              title: "Voulez vous supprimer cet utilisateur ?",
              content: (data) => Render.fullName(data),
              confirmLabel: "Supprimer",
            }}
            failDeleteDialog={{
              title: "Vous ne pouvez pas supprimer cet utilisateur.",
              content: null,
            }}
            restoreDialog={{
              title: "Voulez vous restaurer cet utilisateur ?",
              content: (data) => Render.fullName(data),
              confirmLabel: "Restaurer",
            }}
            failRestoreDialog={{
              title: "Vous ne pouvez pas restaurer cet utilisateur.",
              content: null,
            }}
          />
        </GridItem>
        <GridItem xs={12} lg={6}>
          <ConfigContainer
            icon={<Man />}
            title="Civilité"
            backendUrl="/config-user-civilite"
            label={this.LABEL}
          />
        </GridItem>
        {this.CAN_VIEW_LOG_ACTIVITY && (
          <MenuBottom>
            <Link to={this.FRONT_URL + "/logs?type=UsersManager"}>
              <Button square round>
                <Description />
              </Button>
            </Link>
          </MenuBottom>
        )}
      </GridContainer>
    );
  }
}

UsersManager.propTypes = {
  location: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(UsersManager);
