import styled from "@mui/system/styled";
import MuiButton from "@mui/lab/LoadingButton";

const Button = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== "square" && prop !== "round",
})(({ size = "medium", square, round }) => {
  let squareProps = {};
  if (square) {
    const squarePadding = {
      small: "4px",
      medium: "6px",
      large: "8px",
    };
    const iconeFontSize = {
      small: "16px",
      medium: "18px",
      large: "20px",
    };
    squareProps = {
      minWidth: "10px",
      padding: squarePadding[size] ?? squarePadding["medium"],
      "& .MuiSvgIcon-root": {
        fontSize: iconeFontSize[size] ?? "inherit",
      },
    };
  }
  return {
    ...squareProps,
    ...(round && {
      borderRadius: "50%",
    }),
  };
});

export default Button;
