import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
import LocalActivity from "@mui/icons-material/LocalActivity";

// core components
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import FormSubmitButtons from "components/CustomButtons/FormSubmitButtons";
import TextInput from "components/CustomInput/TextInput";
import SelectSearch from "components/CustomSelect/SelectSearch";
import CurrencyInput from "components/CustomInput/CurrencyInput";
import ActiviteForm from "views/Forms/SubForms/ActiviteForm";
import Validation from "Utils/ValidationUtils";
import { withUserContext } from "context/UserContext";

class MissionsForm extends React.Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/missions";
    this.FRONT_URL = "/missions";
    this.USER = props.user;
    this.IS_AGENT_LOCAL = this.USER.is("agent_local");
    this.QUERY_PARAMS = new URLSearchParams(props.location.search);

    this.state = {
      // NESTED DATA
      categorie: null,
      sous_categorie: null,
      mode_facturation: null,
      activites: [],
      // DATA FORM
      titre: "",
      description: "",
      categorie_id: "",
      sous_categorie_id: "",
      importance: "",
      cout_facturation: "",
      mode_facturation_id: "",
      modalite_prise_charge_publique: "",
      modalites_prefinancements_eventuels: "",
      structure_locale_id: "",
      // END DATA FORM
      idToCopy: this.QUERY_PARAMS.get("id_to_copy"),
      loading: false,
      errors: null,
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.loadAsyncData(this.state.id);
    } else if (this.state.idToCopy) {
      this.loadAsyncData(this.state.idToCopy);
    }
  }

  loadAsyncData = (id) => {
    axiosApiBackend.get(this.BACKEND_URL + "/" + id).then((result) => {
      let state = result.data ?? {};
      delete state.id;
      this.setState(state);
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChangeCategorie = (name, value) => {
    this.setState({ [name]: value, sous_categorie_id: "" });
  };

  handleSubmit = () => {
    const { id } = this.state;

    const dataIn = {
      titre: this.state.titre,
      description: this.state.description,
      categorie_id: this.state.categorie_id,
      sous_categorie_id: this.state.sous_categorie_id,
      importance: this.state.importance,
      cout_facturation: this.state.cout_facturation,
      mode_facturation_id: this.state.mode_facturation_id,
      modalite_prise_charge_publique: this.state.modalite_prise_charge_publique,
      modalites_prefinancements_eventuels:
        this.state.modalites_prefinancements_eventuels,
      structure_locale_id: this.state.structure_locale_id,
      activites: this.state.activites,
    };

    let request = null;
    if (id) {
      // modify
      request = axiosApiBackend.put(this.BACKEND_URL + "/" + id, dataIn);
    } else {
      // create
      request = axiosApiBackend.post(this.BACKEND_URL, dataIn);
    }
    request
      .then((res) => {
        this.handleSuccess(res.data.id);
      })
      .catch((err) => {
        this.setState({
          errors: err.response?.data?.errors ?? {},
          loading: false,
        });
      });

    this.setState({
      errors: null,
      loading: true,
    });
  };

  handleSuccess = (id) => {
    this.props.history.push(this.FRONT_URL + "/detail/" + id);
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const { errors, activites } = this.state;

    return (
      <Grid container columnSpacing={2}>
        {errors && (
          <Grid item xs={12}>
            <Typography variant="h6" component="h4" color="error">
              {Object.entries(errors).length === 0 ? (
                <>Une erreur est survenue.</>
              ) : (
                <>Merci de corriger les champs en rouge du formulaire.</>
              )}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Assignment />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  {this.state.id ? (
                    <>Modifier la Mission</>
                  ) : (
                    <>Ajouter une Mission</>
                  )}
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Titre"
                    name="titre"
                    value={this.state.titre}
                    onChange={this.handleChange}
                    error={Boolean(errors?.titre)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  {!this.IS_AGENT_LOCAL && (
                    <SelectSearch
                      label="Structure"
                      name="structure_locale_id"
                      apiUrl="/structures-locales"
                      value={this.state.structure_locale_id}
                      onChange={this.handleChangeByName}
                      buildOptionLabel={(data) => data.nom}
                      shrink
                      error={Boolean(errors?.structure_locale_id)}
                      emptyLabelValue="Structure Nationale"
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label="Description"
                    name="description"
                    value={this.state.description}
                    onChange={this.handleChange}
                    error={Boolean(errors?.description)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    label="Catégorie"
                    name="categorie_id"
                    apiUrl="/config-mission-categorie"
                    value={this.state.categorie_id}
                    onChange={this.handleChangeCategorie}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.categorie_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    label="Sous catégorie"
                    name="sous_categorie_id"
                    apiUrl="/config-mission-sous-categorie"
                    filterKey="categorie_id"
                    filter={this.state.categorie_id}
                    value={this.state.sous_categorie_id}
                    onChange={this.handleChangeByName}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.sous_categorie_id)}
                    disabled={!this.state.categorie_id}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Importance"
                    name="importance"
                    value={this.state.importance}
                    onChange={this.handleChange}
                    error={Boolean(errors?.importance)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CurrencyInput
                    label="Coût de facturation"
                    name="cout_facturation"
                    value={this.state.cout_facturation}
                    onChange={this.handleChange}
                    error={Boolean(errors?.cout_facturation)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    label="Mode de facturation"
                    name="mode_facturation_id"
                    apiUrl="/config-mission-mode-facturation"
                    value={this.state.mode_facturation_id}
                    onChange={this.handleChangeByName}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.mode_facturation_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Modalité de prise en charge publique"
                    name="modalite_prise_charge_publique"
                    value={this.state.modalite_prise_charge_publique}
                    onChange={this.handleChange}
                    error={Boolean(errors?.modalite_prise_charge_publique)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Modalités de préfinancements éventuels"
                    name="modalites_prefinancements_eventuels"
                    value={this.state.modalites_prefinancements_eventuels}
                    onChange={this.handleChange}
                    error={Boolean(errors?.modalites_prefinancements_eventuels)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <LocalActivity />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Activités
                </Typography>
              }
            />
            <CardContent>
              <ActiviteForm
                activites={activites}
                name="activites"
                onChange={this.handleChangeByName}
                errors={Validation.parse(errors, "activites.")}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <FormSubmitButtons
            onClick={this.handleSubmit}
            onCancel={this.handleCancel}
            loading={this.state.loading}
            isEdit={Boolean(this.state.id)}
          />
        </Grid>
      </Grid>
    );
  }
}

MissionsForm.propTypes = {
  location: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
  user: PropTypes.any,
};

export default withUserContext(MissionsForm);
