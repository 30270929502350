import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";

import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import CircularLoading from "components/Loading/CircularLoading";

class RegularButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      classes,
      color,
      round,
      children,
      fullWidth,
      disabled,
      simple,
      size,
      block,
      link,
      justIcon,
      className,
      muiClasses,
      buttonRef,
      loading,
      ...rest
    } = this.props;
    const btnClasses = classNames({
      [classes.button]: true,
      [classes[size]]: size,
      [classes[color]]: color,
      [classes.round]: round,
      [classes.fullWidth]: fullWidth,
      [classes.disabled]: disabled,
      [classes.simple]: simple,
      [classes.block]: block,
      [classes.link]: link,
      [classes.justIcon]: justIcon,
      [className]: className,
    });
    return (
      <>
        {loading ? (
          <CircularLoading display={loading} />
        ) : (
          <Button
            ref={buttonRef}
            {...rest}
            classes={muiClasses}
            className={btnClasses}
          >
            {children}
          </Button>
        )}
      </>
    );
  }
}

RegularButton.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "twitter",
    "facebook",
    "google",
    "linkedin",
    "pinterest",
    "youtube",
    "tumblr",
    "github",
    "behance",
    "dribbble",
    "reddit",
    "transparent",
    "config",
  ]),
  size: PropTypes.oneOf(["sm", "lg"]),
  simple: PropTypes.bool,
  round: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.string,
  justIcon: PropTypes.bool,
  className: PropTypes.string,
  muiClasses: PropTypes.object,
  buttonRef: PropTypes.any,
  children: PropTypes.any,
  loading: PropTypes.any,
};

export default withStyles(buttonStyle)(RegularButton);
