import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackendNoJson } from "variables/axiosConfigs.jsx";

import Button from "components/Button/Button.jsx";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);
import Publish from "@mui/icons-material/Publish";
import FileUtils from "Utils/FileUtils";
import Download from "@mui/icons-material/Download";

class CsvImportPopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      import_files: [],
      templateName: null,
      loading: false,
      errorMessage: null,
      errors: null,
      open: false,
    };
  }

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  handleSubmit = () => {
    this.setState({
      loading: true,
      success: false,
      errorMessage: null,
      errors: null,
    });
    const url = `/import/structure`;
    const formData = new FormData();
    this.state.import_files.forEach((file, i) => {
      formData.append("import_file" + i, file);
    });
    formData.append("structure_locale_id", this.props.structureId);

    axiosApiBackendNoJson
      .post(url, formData)
      .then(() => {
        this.setState({
          import_files: [],
          loading: false,
          success: true,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          errorMessage: error.response?.data?.message || "Erreur inconnue",
          errors: error.response?.data?.errors,
        });
      });
  };

  downloadExemple = () => {
    FileUtils.downLoadFromUrl(
      axiosApiBackendNoJson,
      "import/structure/exemple",
      { responseType: "arraybuffer" },
      "structure.xlsx",
      this.clearAlert,
    );
  };

  render() {
    const { errors } = this.state;

    return (
      <>
        <Button
          fullWidth
          color="primary"
          startIcon={<Publish />}
          onClick={this.toggle}
        >
          Import CSV/Excel
        </Button>
        <Dialog open={this.state.open} maxWidth="sm" fullWidth>
          <DialogTitle>Import CSV/Excel</DialogTitle>
          <DialogContent>
            {!errors && this.state.errorMessage && (
              <Typography color="error">{this.state.errorMessage}</Typography>
            )}
            {this.state.success && (
              <Typography color="success.main">
                Import fait avec success.
              </Typography>
            )}
            <Grid container columnSpacing={2}>
              <Grid item xs={12}>
                <FilePond
                  labelIdle="Ajoutez un fichier<br>(MAX 10Mo)"
                  files={this.state.import_files}
                  allowMultiple={false}
                  maxTotalFileSize={10000000}
                  acceptedFileTypes={[
                    "text/csv",
                    "text/plain",
                    "application/vnd.ms-excel",
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  ]}
                  onupdatefiles={(fileItems) => {
                    this.setState({
                      import_files: fileItems.map((fileItem) => fileItem.file),
                    });
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="reversed"
              onClick={this.downloadExemple}
              startIcon={<Download />}
            >
              Fichier d&apos;exemple
            </Button>
            <Button onClick={this.toggle} color="reversed">
              Annuler
            </Button>
            <Button
              onClick={this.handleSubmit}
              color="primary"
              loading={this.state.loading}
            >
              Importer
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

CsvImportPopUp.defaultProps = {};

CsvImportPopUp.propTypes = {
  structureId: PropTypes.any,
};

export default CsvImportPopUp;
