import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// @mui/icons-material

// components
import TextInput from "components/CustomInput/TextInput";
import PaperListForm from "components/PaperListForm/PaperListForm";

function ConfigMissionSousCategorieForm({ datas, name, onChange, errors }) {
  return (
    <>
      <Typography sx={{ px: 1 }}>Sous-Catégories</Typography>
      <PaperListForm
        datas={datas}
        name={name}
        onChange={onChange}
        buildFormItem={(data, key, handleChange) => (
          <Grid item xs={12}>
            <TextInput
              label="Libellé"
              name="value"
              value={data.value}
              onChange={handleChange}
              error={Boolean(errors?.[key + ".value"])}
            />
          </Grid>
        )}
        buildDeleteTitle={(data) =>
          "Voulez-vous supprimer " + (data?.value ?? "") + " ?"
        }
        buildCantClickDeleteButtonText={(data) =>
          data.missions_count > 0 ? (
            <>Lié à {data.missions_count} Mission(s)</>
          ) : null
        }
        addButtonText="Ajouter une Sous-Catégorie"
      />
    </>
  );
}

ConfigMissionSousCategorieForm.propTypes = {
  datas: PropTypes.array,
  name: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.any,
};

export default ConfigMissionSousCategorieForm;
