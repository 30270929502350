import LogementUtils from "Utils/LogementUtils";

export default class BeneficiaireUtils {
  static buildRowsForLinks = (logements) => {
    let rows = [];
    logements.forEach((logement) => {
      let logRows = LogementUtils.buildRowsForLinks(logement.amos);

      let logForlink = logement;
      logForlink.span = logRows.length > 0 ? logRows.length : 1;
      logRows.forEach((row) => {
        rows.push({
          logement: logForlink,
          dossier_travaux: row.dossier_travaux,
        });
        logForlink = null;
      });
      if (logForlink != null) {
        rows.push({
          logement: logForlink,
          amos: null,
        });
      }
    });

    return rows;
  };
}
