// @mui/icons-material
import People from "@mui/icons-material/People";
import Settings from "@mui/icons-material/Settings";
import SensorOccupied from "@mui/icons-material/SensorOccupied";
import Person from "@mui/icons-material/Person";
import HomeWork from "@mui/icons-material/HomeWork";
import Assignment from "@mui/icons-material/Assignment";
import DisplaySettings from "@mui/icons-material/DisplaySettings";
import Build from "@mui/icons-material/Build";
import Business from "@mui/icons-material/Business";

// core components
import HomeConfig from "views/ViewConfiguration/HomeConfig";
import GeneralSettings from "views/ViewConfiguration/Transverse/GeneralSettings";
import UsersManager from "views/ViewConfiguration/Transverse/UsersManager";
import RolesManager from "views/ViewConfiguration/Transverse/RolesManager";
import StructureLocaleConfig from "views/ViewConfiguration/Transverse/StructureLocaleConfig";
import AgentLocalConfig from "views/ViewConfiguration/Transverse/AgentLocalConfig";
import StructureNationaleConfig from "views/ViewConfiguration/Transverse/StructureNationaleConfig";
import AgentNationalConfig from "views/ViewConfiguration/Transverse/AgentNationalConfig";
import MissionConfig from "views/ViewConfiguration/Transverse/MissionConfig";
import BeneficiaireConfig from "views/ViewConfiguration/Transverse/BeneficiaireConfig";
import AmoConfig from "views/ViewConfiguration/Transverse/AmoConfig";
import UiCustomizationSettings from "views/ViewConfiguration/Transverse/UiCustomizationSettings";

var configDashboardRoutes = [
  {
    permission: ["view.configuration.general"],
    icon: Settings,
    path: "/home",
    name: "Configuration",
    component: HomeConfig,
  },
  {
    divider: true,
    permission: ["view.configuration.general"],
    name: "Portail",
  },
  {
    permission: ["view.configuration.general"],
    path: "/informations",
    name: "Informations",
    icon: Settings,
    component: GeneralSettings,
  },
  {
    permission: ["edit.configuration.uicustomization"],
    path: "/customisation-ui",
    name: "Customisation UI",
    icon: DisplaySettings,
    component: UiCustomizationSettings,
  },
  {
    permission: ["edit.usersmanager"],
    path: "/users",
    name: "Gestion des utilisateurs",
    icon: People,
    component: UsersManager,
    logs: true,
  },
  {
    permission: ["edit.role"],
    path: "/roles",
    name: "Gestion des roles",
    icon: SensorOccupied,
    component: RolesManager,
    logs: true,
  },
  {
    permission: ["config.structurenationale"],
    path: "/structure-nationale",
    name: "Structure Nationale",
    icon: Business,
    component: StructureNationaleConfig,
    logs: true,
  },
  {
    permission: ["config.agentnational"],
    path: "/agents-nationaux",
    name: "Agents Nationaux",
    icon: Person,
    component: AgentNationalConfig,
    logs: true,
  },
  {
    permission: ["config.structurelocale"],
    path: "/structures-locales",
    name: "Structures Locales",
    icon: HomeWork,
    component: StructureLocaleConfig,
    logs: true,
  },
  {
    permission: ["config.agentlocal"],
    path: "/agents-locaux",
    name: "Agents Locaux",
    icon: Person,
    component: AgentLocalConfig,
    logs: true,
  },
  {
    permission: ["config.mission"],
    path: "/missions",
    name: "Missions",
    icon: Assignment,
    component: MissionConfig,
    logs: true,
  },
  {
    permission: ["config.beneficiaire"],
    path: "/beneficiaires",
    name: "Bénéficiaires",
    icon: Person,
    component: BeneficiaireConfig,
    logs: true,
  },
  {
    permission: ["config.amo"],
    path: "/amo",
    name: "AMO",
    icon: Build,
    component: AmoConfig,
    logs: true,
  },
  // redirects
  {
    redirect: true,
    permission: ["view.configuration.general"],
    path: "/informations",
    pathTo: "/informations",
  },
  {
    redirect: true,
    permission: ["edit.usersmanager"],
    path: "/users",
    pathTo: "/users",
  },
  {
    redirect: true,
    permission: ["edit.role"],
    path: "/roles",
    pathTo: "/roles",
  },
  {
    redirect: true,
    permission: ["config.structurenationale"],
    path: "/structure-nationale",
    pathTo: "/structure-nationale",
  },
  {
    redirect: true,
    permission: ["config.agentnational"],
    path: "/agents-nationaux",
    pathTo: "/agents-nationaux",
  },
  {
    redirect: true,
    permission: ["config.structurelocale"],
    path: "/structures-locales",
    pathTo: "/structures-locales",
  },
  {
    redirect: true,
    permission: ["config.agentlocal"],
    path: "/agents-locaux",
    pathTo: "/agents-locaux",
  },
  {
    redirect: true,
    permission: ["config.mission"],
    path: "/missions",
    pathTo: "/missions",
  },
  {
    redirect: true,
    permission: ["config.beneficiaire"],
    path: "/beneficiaires",
    pathTo: "/beneficiaires",
  },
  {
    redirect: true,
    permission: ["config.amo"],
    path: "/amo",
    pathTo: "/amo",
  },
  {
    redirect: true,
    path: "/",
    pathTo: "/home",
  },
];
export default configDashboardRoutes;
