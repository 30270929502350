import React from "react";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.jsx";
import ContentCopy from "@mui/icons-material/ContentCopy";

class CopyButtonRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbar: false,
    };
  }

  onClick = () => {
    navigator.clipboard.writeText(this.props.valueToCopy);
    this.setState({ snackbar: true });
    this.props.onCopy(this.props.valueToCopy);
  };

  render() {
    return (
      <Button justIcon color="primary" size="sm" onClick={this.onClick}>
        <ContentCopy />
      </Button>
    );
  }
}

CopyButtonRenderer.propTypes = {
  valueToCopy: PropTypes.string.isRequired,
  onCopy: PropTypes.func,
};

export default CopyButtonRenderer;
