import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackendNoAuth } from "variables/axiosConfigs.jsx";
import { Link } from "react-router-dom";
import logo from "assets/img/soliha-logo.png";

//Services
import { verifyEmail } from "services/controlForms.jsx";

// @mui/material components
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// core components
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import TextInput from "components/CustomInput/TextInput";

class ForgotPasswordFrom extends React.Component {
  constructor(props) {
    super(props);
    this.QUERY_PARAMS = new URLSearchParams(props.location.search);

    this.state = {
      first: this.QUERY_PARAMS.get("first") === "1",
      userEmail: this.QUERY_PARAMS.get("email") || "",
      message: "",
      successEmail: false,
      errorEmail: false,
      success: false,
      loading: false,
    };
  }

  //function request
  getUserMail = () => {
    this.setState({
      message: "",
      success: false,
      loading: true,
    });
    const data = {
      email: this.state.userEmail,
      first: this.state.first,
    };

    axiosApiBackendNoAuth
      .post("/forgot-password", data)
      .then(() => {
        this.setState({
          success: true,
          loading: false,
        });
      })
      .catch((error) => {
        if (
          error.response &&
          (error.response.status === 400 || error.response.status === 422)
        ) {
          this.setState({
            message: "Email invalide.",
            loading: false,
          });
        } else {
          this.setState({
            message: "Votre demande a échoué.",
            loading: false,
          });
        }
      });
  };

  forgotPasswordClick = (e) => {
    e.preventDefault();
    this.getUserMail();
  };

  change = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onBlurEmail = () => {
    let control = verifyEmail(this.state.userEmail);
    this.setState({
      successEmail: control,
      errorEmail: !control,
    });
  };

  render() {
    const { successEmail } = this.state;
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardHeader
              title={
                <Box sx={{ textAlign: "center", mt: 2 }}>
                  <img style={{ width: "70%" }} src={logo} alt="Logo Soliha" />
                </Box>
              }
            />
            <CardContent>
              <Typography sx={{ textAlign: "center", my: 1 }} variant="h5">
                {this.state.first
                  ? "Initialisation de votre mot de passe"
                  : "Mot de passe oublié ?"}
              </Typography>
              <Typography sx={{ textAlign: "center", my: 1 }}>
                Renseigner votre e-mail dans le formulaire ci-dessous afin de
                recevoir les instructions
                {this.state.first
                  ? " d'initialisation "
                  : " de réinitialisation "}
                de votre mot de passe.
              </Typography>
              <form onSubmit={this.forgotPasswordClick}>
                <TextInput
                  sx={
                    successEmail ? { label: { color: "success.main" } } : null
                  }
                  label="Adresse email"
                  name="userEmail"
                  type="email"
                  onChange={this.change}
                  onBlur={this.onBlurEmail}
                  value={this.state.userEmail}
                  error={this.state.errorEmail}
                  color={successEmail ? "success" : null}
                  disabled={this.state.first ? true : false}
                  required
                />

                <Typography color="error">{this.state.message}</Typography>

                <Box
                  sx={{
                    marginBottom: "0",
                    color: "#999999",
                    fontSize: "14px",
                    py: "10px",
                  }}
                >
                  <small>*</small> Champs obligatoires
                </Box>

                {this.state.success && (
                  <Typography style={{ color: "#388e3c" }} gutterBottom>
                    Les instructions ont été envoyées à l&apos;adresse
                    <strong> {this.state.userEmail} </strong>, si celle-ci est
                    connue du système. Merci de vérifier votre dossier Spam.
                  </Typography>
                )}

                <Box sx={{ textAlign: "center" }}>
                  <LoadingButton type="submit" loading={this.state.loading}>
                    {this.state.first
                      ? "Initialiser le mot de passe"
                      : "Réinitialiser le mot de passe"}
                  </LoadingButton>
                </Box>
              </form>
              <Typography align="right">
                <Link to="/">se connecter</Link>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

ForgotPasswordFrom.propTypes = {
  location: PropTypes.any,
};

export default ForgotPasswordFrom;
