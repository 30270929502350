import React from "react";
import DisableLink from "components/DisableLink/DisableLink";

export default class DossierTravauxUtils {
  static renderInfo = (dt, withLink = true) => {
    if (dt == null) {
      return;
    }

    const url = withLink ? "/amo/detail/" + dt.id : null;

    return <DisableLink to={url}>AMO {dt.id}</DisableLink>;
  };
}
