import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import TableHead from "components/Table/TableHead";
import TableRow from "components/Table/TableRow";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { Link } from "react-router-dom";

// @mui/icons-material
import Button from "components/Button/Button.jsx";
import Add from "@mui/icons-material/Add";

// core components
import BeneficiaireUtils from "Utils/BeneficiaireUtils";
import LogementUtils from "Utils/LogementUtils";
import DossierTravauxUtils from "Utils/DossierTravauxUtils";

class BeneficiaireSubTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { withLinks, logements } = this.props;
    const rowsForLinks = BeneficiaireUtils.buildRowsForLinks(logements);

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Logement</TableCell>
              <TableCell>Amo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsForLinks.length == 0 && (
              <TableRow>
                <TableCell colSpan={3}>Aucun logement</TableCell>
              </TableRow>
            )}
            {rowsForLinks.map((row, key) => (
              <TableRow key={key}>
                {row.logement && (
                  <TableCell rowSpan={row.logement.span}>
                    {LogementUtils.renderInfo(row.logement, withLinks)}
                  </TableCell>
                )}
                {row.dossier_travaux && (
                  <TableCell rowSpan={row.dossier_travaux?.span}>
                    {DossierTravauxUtils.renderInfo(
                      row.dossier_travaux,
                      withLinks,
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

BeneficiaireSubTable.defaultProps = {
  withLinks: true,
};

BeneficiaireSubTable.propTypes = {
  withLinks: PropTypes.bool,
  logements: PropTypes.any,
  dossiers_travaux: PropTypes.any,
};

export default BeneficiaireSubTable;
