import React, { Component } from "react";

// @mui/material components
import Grid from "@mui/material/Grid";

// @mui/icons-material
import Category from "@mui/icons-material/Category";

// core components
import TablePopUpContainer from "components/TablePopUpContainer/TablePopUpContainer";
import TextInput from "components/CustomInput/TextInput";
import ConfigMissionSousCategorieForm from "components/PaperListForm/ConfigMissionSousCategorieForm";
import Validation from "Utils/ValidationUtils";
import Render from "Utils/RenderUtils";

class ConfigMissionCategorie extends Component {
  constructor(props) {
    super(props);
    this.LABEL = "Mission(s)";

    this.state = {
      id: "",
      value: "",
      sous_categories: [],
      errors: null,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const { id, value, sous_categories, errors } = this.state;
    return (
      <TablePopUpContainer
        dialogProps={{
          fullWidth: true,
        }}
        icon={<Category />}
        title="Catégories"
        backendUrl="/config-mission-categorie"
        colDef={[
          { field: "id", editable: false, hide: true },
          {
            headerName: "Libellé",
            field: "value",
            flex: 2,
          },
          {
            headerName: "Sous-Catégories",
            field: "sous_categories",
            flex: 3,
            valueGetter: (params) =>
              Render.configList(params.data.sous_categories),
          },
        ]}
        formInitData={() => {
          this.setState({
            errors: null,
            id: null,
            value: "",
            sous_categories: [],
          });
        }}
        formSetData={(data) => {
          this.setState({
            errors: null,
            id: data.id,
            value: data.value,
            sous_categories: data.sous_categories,
          });
        }}
        formGetData={() => ({
          id: id,
          value: value,
          sous_categories: sous_categories,
        })}
        formSetErrors={(err) => {
          this.setState({
            errors: err,
          });
        }}
        formTitle={id ? "Modifier la Catégorie" : "Ajouter une Catégorie"}
        formConfirmLabel={id ? "Modifier" : "Ajouter"}
        formContent={
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInput
                label="Libellé"
                name="value"
                value={value}
                onChange={this.handleChange}
                error={Boolean(errors?.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <ConfigMissionSousCategorieForm
                datas={sous_categories}
                name="sous_categories"
                onChange={this.handleChangeByName}
                errors={Validation.parse(errors, "sous_categories.")}
              />
            </Grid>
          </Grid>
        }
        deleteDialog={{
          title: "Voulez vous supprimer cette Catégorie ?",
          content: (data) => {
            if (data.missions_count > 0) {
              return `${data.value} : Lié à ${data.missions_count} ${this.LABEL}`;
            }
            const sc = data.sous_categories?.find((a) => a.missions_count > 0);
            if (sc != null) {
              return `${sc.value} : Lié à ${sc.missions_count} ${this.LABEL}`;
            }
            return data.value;
          },
          confirmLabel: "Supprimer",
        }}
        failDeleteDialog={{
          title: "Vous ne pouvez pas supprimer cette Catégorie.",
          content: (data, error) =>
            (data.value ?? "") +
            " est associé à " +
            ((error?.errors?.missions ?? 0) ||
              (error?.errors?.missions2 ?? 0)) +
            " " +
            this.LABEL,
        }}
      />
    );
  }
}

export default ConfigMissionCategorie;
