import styled from "@mui/system/styled";
import MuiCardHeader from "@mui/material/CardHeader";

const CardHeader = styled(MuiCardHeader)({
  marginTop: "30px",
  paddingBottom: 0,
  paddingTop: "0px",
  "& .MuiCardHeader-avatar": {
    marginTop: "-20px",
    alignSelf: "start",
  },
  "& .MuiCardHeader-content ": {
    alignSelf: "start",
  },
  "& .MuiCardHeader-action": {
    marginTop: 0,
  },
});

export default CardHeader;
