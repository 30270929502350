export default class AmoUtils {
  static MONTANT_TVA_TOOLTIP =
    "Calcul du montant de la TVA : Si application de la TVA : TVA = 0.20 x coût montant HT";
  static COUT_MISSION_HT_TOOLTIP =
    "Calcul du montant du coût des missions HT : Somme des missions (si l'intégralité des activités est sélectionnée, c'est le coût de facturation qui est ajouté pour cette mission)";
  static COUT_MISSION_TTC_TOOLTIP =
    "Calcul du montant du coût des missions TTC : coût montant HT + TVA";
  static POURCENTAGE_PARTICIPATION_ANAH_TOOLTIP =
    "Calcul du pourcentage de la participation ANAH : Catégorie 'Très modeste', la participation est égale à 100% du montant HT. Catégorie 'Modeste', la participation est égale à 80% du montant HT. Catégorie 'Intermédiaire' et 'Supérieur', la participation est de 0% du montant HT. Si des catégories de mission non 'MAR' sont sélectionnées, le montant de la participation ANAH n'est pas renseigné.";
  static MONTANT_PARTICIPATION_ANAH_TOOLTIP =
    "Calcul du montant de la participation ANAH : Catégorie 'Très modeste', participation = coût HT. Catégorie 'Modeste', participation = 0,80 x coût HT. Catégorie 'Intermédiaire' et 'Supérieur', participation = 0,00€. Si des catégories de mission non 'MAR' sont sélectionnées, le montant de la participation ANAH n'est pas renseigné.";
}
