import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Redirect, Router, Route, Switch } from "react-router-dom";
import StyledEngineProvider from "@mui/styled-engine/StyledEngineProvider";
import createTheme from "@mui/material/styles/createTheme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { UserContext } from "context/UserContext";
import { UiCustomizationContext } from "context/UiCustomizationContext";
import { uiCustomization, user } from "variables/axiosConfigs";

import indexRoutes from "routes/index.jsx";
import loginRoutes from "routes/login.jsx";

import "assets/scss/material-dashboard-pro-react.css?v=1.5.0";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "assets/css/ag-grid.css";
import "assets/css/global.css";

const theme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: "contained",
      },
    },
    MuiAvatar: {
      defaultProps: {
        variant: "rounded",
      },
    },
  },
  palette: {
    primary: {
      main: "#0a4f75",
    },
    success: {
      main: "#4caf50",
      contrastText: "#fff",
    },
    gray: {
      main: "#757575",
      contrastText: "#fff",
    },
    onyx: {
      main: "#000",
      contrastText: "#fff",
    },
    reversed: {
      main: "#fff",
      contrastText: "#0a4f75",
    },
    config: {
      main: "#DB0000",
      contrastText: "#fff",
    },
  },
});

document.title = window?._env_?.WEBSITE_NAME;
const hist = createBrowserHistory();

function getRoutes(user) {
  const isLoggedIn = user.access_token != null;
  return isLoggedIn ? indexRoutes : loginRoutes;
}

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <UserContext.Provider value={user}>
        <UiCustomizationContext.Provider value={uiCustomization}>
          <Router history={hist}>
            <Switch>
              {getRoutes(user).map((prop, key) => {
                if (prop.redirect) {
                  return (
                    <Redirect from={prop.path} to={prop.pathTo} key={key} />
                  );
                }
                return (
                  <Route
                    exact={prop.exact}
                    path={prop.path}
                    key={key}
                    render={(props) => (
                      <prop.component {...props} {...prop.componentProps} />
                    )}
                  />
                );
              })}
            </Switch>
          </Router>
        </UiCustomizationContext.Provider>
      </UserContext.Provider>
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById("root"),
);
