import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// @mui/icons-material
import Person from "@mui/icons-material/Person";
import Euro from "@mui/icons-material/EuroSymbol";

// core components
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import SelectSearch from "components/CustomSelect/SelectSearch";
import FormSubmitButtons from "components/CustomButtons/FormSubmitButtons";
import TextInput from "components/CustomInput/TextInput";
import { withUiCustomizationContext } from "context/UiCustomizationContext";
import PhoneInput from "components/PhoneInput/PhoneInput";

class BeneficiairesForm extends React.Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/beneficiaires";
    this.FRONT_URL = "/beneficiaires";
    const uiCustomization = props.uiCustomization;

    this.state = {
      // DATA FORM
      structure_locale_id: "",
      civilite_id: "",
      type_representant_id: "",
      nom: "",
      prenom: "",
      date_debut_mandat: "",
      date_fin_mandat: "",
      telephone: "",
      email: "",
      categorie_ressource_id: "",
      // END DATA FORM
      types_representants: [],
      loading: false,
      dep_code: uiCustomization?.dep_code ?? "",
      errors: null,
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.loadAsyncData();
    }
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = result.data ?? {};
        this.setState(state);
      });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  handleTypeRepresentantResponse = (data) => {
    this.setState({ types_representants: data });
  };

  handleSubmit = () => {
    const { id, types_representants, type_representant_id } = this.state;
    const selectedTypeRepresentant = types_representants.find(
      (type) => type.id === type_representant_id,
    )?.value;

    const dataIn = {
      structure_locale_id: this.state.structure_locale_id,
      civilite_id: this.state.civilite_id,
      type_representant_id: type_representant_id,
      nom: this.state.nom,
      prenom: this.state.prenom,
      telephone: this.state.telephone,
      email: this.state.email,
      categorie_ressource_id: this.state.categorie_ressource_id,
      date_debut_mandat: this.state.date_debut_mandat,
      date_fin_mandat:
        selectedTypeRepresentant !== "Syndic"
          ? null
          : this.state.date_fin_mandat,
    };

    let request = null;
    if (id) {
      // modify
      request = axiosApiBackend.put(this.BACKEND_URL + "/" + id, dataIn);
    } else {
      // create
      request = axiosApiBackend.post(this.BACKEND_URL, dataIn);
    }
    request
      .then((res) => {
        this.handleSuccess(res.data.id);
      })
      .catch((err) => {
        this.setState({
          errors: err.response?.data?.errors ?? {},
          loading: false,
        });
      });

    this.setState({
      errors: null,
      loading: true,
    });
  };

  handleSuccess = (id) => {
    this.props.history.push(this.FRONT_URL + "/detail/" + id);
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const { type_representant_id, types_representants, errors } = this.state;
    const selectedTypeRepresentant = types_representants.find(
      (type) => type.id === type_representant_id,
    )?.value;

    return (
      <Grid container spacing={2}>
        {errors && (
          <Grid item xs={12}>
            <Typography variant="h6" component="h4" color="error">
              {Object.entries(errors).length === 0 ? (
                <>Une erreur est survenue.</>
              ) : (
                <>Merci de corriger les champs en rouge du formulaire.</>
              )}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Person />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  {this.state.id ? (
                    <>Modifier le Bénéficiaire</>
                  ) : (
                    <>Ajouter un Bénéficiaire</>
                  )}
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    name="structure_locale_id"
                    label="Structure Locale"
                    apiUrl="/structures-locales"
                    onChange={this.handleChangeByName}
                    value={this.state.structure_locale_id}
                    buildOptionLabel={(data) => data.nom}
                    shrink
                    error={Boolean(errors?.structure_locale_id)}
                    autoSelectFirstOption
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    name="civilite_id"
                    label="Civilité"
                    apiUrl="/config-beneficiaire-civilite"
                    onChange={this.handleChangeByName}
                    value={this.state.civilite_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.civilite_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Nom"
                    name="nom"
                    value={this.state.nom}
                    onChange={this.handleChange}
                    error={Boolean(errors?.nom)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Prénom"
                    name="prenom"
                    value={this.state.prenom}
                    onChange={this.handleChange}
                    error={Boolean(errors?.prenom)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <PhoneInput
                    label="Téléphone"
                    name="telephone"
                    value={this.state.telephone}
                    onChange={this.handleChangeByName}
                    error={Boolean(errors?.telephone)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    error={Boolean(errors?.email)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Date de début du mandat"
                    name="date_debut_mandat"
                    type="date"
                    value={this.state.date_debut_mandat}
                    onChange={this.handleChange}
                    error={Boolean(errors?.date_debut_mandat)}
                  />
                </Grid>
                {selectedTypeRepresentant === "Syndic" && (
                  <Grid item xs={12} lg={6}>
                    <TextInput
                      label="Date de fin du mandat"
                      name="date_fin_mandat"
                      type="date"
                      value={this.state.date_fin_mandat}
                      onChange={this.handleChange}
                      error={Boolean(errors?.date_fin_mandat)}
                    />
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar sx={{ bgcolor: "gla.main" }}>
                  <Euro />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Ressources
                </Typography>
              }
            />
            <CardContent alignitems="center">
              <Grid container columnSpacing={2}>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    name="type_representant_id"
                    label="Type de représentant"
                    apiUrl="/config-beneficiaire-type-representant"
                    onChange={this.handleChangeByName}
                    value={this.state.type_representant_id}
                    buildOptionLabel={(data) => data.value}
                    onResponse={this.handleTypeRepresentantResponse}
                    shrink
                    error={Boolean(errors?.type_representant_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    name="categorie_ressource_id"
                    label="Catégorie de ressources"
                    apiUrl="/config-categorie-ressource"
                    onChange={this.handleChangeByName}
                    value={this.state.categorie_ressource_id}
                    buildOptionLabel={(data) => data.value}
                    buildOptionAdditionalData={(data) => ({
                      color_mpr: data.color_mpr,
                    })}
                    renderExtra={(data) => (
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          borderRadius: 1,
                          backgroundColor: data.color_mpr,
                          ml: 1,
                        }}
                      />
                    )}
                    shrink
                    error={Boolean(errors?.categorie_ressource_id)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <FormSubmitButtons
            onClick={this.handleSubmit}
            onCancel={this.handleCancel}
            loading={this.state.loading}
            isEdit={Boolean(this.state.id)}
          />
        </Grid>
      </Grid>
    );
  }
}

BeneficiairesForm.propTypes = {
  location: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
  uiCustomization: PropTypes.object,
};

export default withUiCustomizationContext(BeneficiairesForm);
