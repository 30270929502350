import { axiosApiBackend } from "variables/axiosConfigs";

export default class UiCustomization {
  loading = false;
  updated_at = null;
  dep_code = null;
  google_maps_key = null;

  constructor() {
    this.loadData();
  }

  loadData() {
    let data = JSON.parse(localStorage.getItem("ui_customization"));
    this.setData(data);
  }

  setData(data) {
    this.updated_at = data?.updated_at;
    this.dep_code = data?.dep_code;
    this.google_maps_key = data?.google_maps_key;
  }

  storeData(data) {
    this.setData(data);
    localStorage.setItem("ui_customization", JSON.stringify(data) ?? "");
  }

  isOutdated(headers) {
    const updatedAt = headers["x-ui-customization-updated-at"] ?? null;
    if (updatedAt == null) {
      // we skip, there is no data
      return;
    }

    return this.updated_at == null || updatedAt > this.updated_at;
  }

  getRefreshUrl() {
    return "/settings/ui-customization";
  }

  refresh() {
    if (this.loading == false) {
      this.loading = true;

      axiosApiBackend
        .get(this.getRefreshUrl())
        .then((result) => {
          this.storeData(result.data);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
}
