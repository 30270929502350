import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink } from "react-router-dom";
import cx from "classnames";

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";

// @mui/material components
import withStyles from "@mui/styles/withStyles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Hidden from "@mui/material/Hidden";
import Collapse from "@mui/material/Collapse";
import Icon from "@mui/material/Icon";

// core components
import Description from "@mui/icons-material/Description";
import PowerSettingsNew from "@mui/icons-material/PowerSettingsNew";
import Settings from "@mui/icons-material/Settings";
import { axiosApiBackend, axiosApiBackendNoJson } from "variables/axiosConfigs";
import FileUtils from "Utils/FileUtils";
import { withUserContext } from "context/UserContext";

var ps;

class SidebarWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.sidebarWrapper = null;
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    const { className, user, headerLinks, links } = this.props;
    return (
      <div className={className} ref={(ref) => (this.sidebarWrapper = ref)}>
        {user}
        {headerLinks}
        {links}
      </div>
    );
  }
}

SidebarWrapper.propTypes = {
  className: PropTypes.any,
  user: PropTypes.any,
  headerLinks: PropTypes.any,
  links: PropTypes.any,
};

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.CAN_CONFIG = props.user.canOne([
      "edit.configuration.general",
      "view.configuration.travaux",
      "view.configuration.immobilier",
    ]);
    this.BASE_PATH = props.basePath;
    this.state = {
      openAvatar: false,
      miniActive: true,
    };
    this.activeRoute.bind(this);
  }

  componentDidMount() {
    this.props.routes.forEach((prop) => {
      if (prop.collapse) {
        this.setState({
          [prop.state]: this.activeRoute(prop.path),
        });
      }
    });
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    let path = this.props.location.pathname + "/";
    let route = routeName + "/";
    // return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
    return path.indexOf(route) > -1 ? true : false;
  }

  toggleCollapse(collapse) {
    this.setState({
      [collapse]: !this.state[collapse],
    });
  }

  generateDoc = (name) => {
    axiosApiBackendNoJson
      .get("/documentation/" + name, {
        responseType: "arraybuffer",
      })
      .then((res) => {
        FileUtils.downLoadFile(res.data, res.headers, "doc-" + name + ".pdf");
      });
  };

  logout = () => {
    axiosApiBackend.post("/logout").finally(() => {
      const { user } = this.props;
      user.cleanData();
      window.location.reload();
    });
  };

  render() {
    const {
      classes,
      color,
      logo,
      image,
      logoText,
      routes,
      bgColor,
      linkPath,
      linkText,
      configBackground,
    } = this.props;
    const itemText =
      classes.itemText +
      " " +
      cx({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
      });
    const collapseItemText =
      classes.collapseItemText +
      " " +
      cx({
        [classes.collapseItemTextMini]:
          this.props.miniActive && this.state.miniActive,
      });
    const userWrapperClass =
      classes.user +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white",
      });
    const caret = classes.caret;
    const ItemIcon = classes.itemIcon;
    const userListItemClass =
      classes.collapseItem +
      " " +
      classes.itemLink +
      " " +
      classes.userCollapseLinks;
    const { user } = this.props;
    const username = user.getName();
    const roleName = user.getRolesNames();

    let userBox = (
      <div className={userWrapperClass}>
        <List className={classes.list}>
          <ListItem className={classes.item + " " + classes.userItem}>
            <div
              className={classes.itemLink + " " + classes.userCollapseButton}
              onClick={() => this.toggleCollapse("openAvatar")}
            >
              <ListItemText
                primary={username}
                secondary={
                  <b
                    className={
                      caret +
                      " " +
                      classes.userCaret +
                      " " +
                      (this.state.openAvatar ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                className={itemText + " " + classes.userItemText}
              />
              <ListItemText
                primary={roleName}
                disableTypography={false}
                className={itemText + " " + classes.roleItemText}
              />
            </div>
            <Collapse in={this.state.openAvatar} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                {user.can("use.documentation.portail") && (
                  <ListItem
                    className={userListItemClass}
                    onClick={() => this.generateDoc("portail")}
                  >
                    <ListItemIcon className={ItemIcon}>
                      <Description />
                    </ListItemIcon>
                    <ListItemText
                      primary="Documentation portail"
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </ListItem>
                )}
                {this.CAN_CONFIG && (
                  <ListItem
                    className={userListItemClass}
                    component={NavLink}
                    to={linkPath}
                  >
                    <ListItemIcon className={ItemIcon}>
                      <Settings />
                    </ListItemIcon>
                    <ListItemText
                      primary={linkText}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </ListItem>
                )}
                <ListItem className={userListItemClass} onClick={this.logout}>
                  <ListItemIcon className={ItemIcon}>
                    <PowerSettingsNew />
                  </ListItemIcon>
                  <ListItemText
                    primary="Se déconnecter"
                    disableTypography={true}
                    className={collapseItemText}
                  />
                </ListItem>
              </List>
            </Collapse>
          </ListItem>
        </List>
      </div>
    );
    var links = (
      <List className={classes.list}>
        {routes.map((prop, key) => {
          if (prop.redirect) {
            return null;
          }
          if (prop.sidebar === false) {
            return null;
          }
          if (!user.canOne(prop.permission)) {
            return null;
          }
          if (prop.divider) {
            return (
              <ListItem key={key} className={classes.item}>
                <ListItemText
                  primary={prop.name}
                  disableTypography={true}
                  className={itemText}
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    fontWeight: "bold",
                    backgroundColor: "lightgrey",
                  }}
                />
              </ListItem>
            );
          }
          if (prop.collapse) {
            const navLinkClasses =
              classes.itemLink +
              " " +
              cx({
                [" " + classes.collapseActive]: this.activeRoute(prop.path),
              });
            const collapseItemText =
              classes.collapseItemText +
              " " +
              cx({
                [classes.collapseItemTextMini]:
                  this.props.miniActive && this.state.miniActive,
              });
            const itemIcon = classes.itemIcon;
            const caret = classes.caret;
            return (
              <React.Fragment key={key}>
                <ListItem
                  className={navLinkClasses}
                  onClick={() => this.toggleCollapse(prop.state)}
                >
                  <ListItemIcon className={itemIcon}>
                    {typeof prop.icon === "string" ? (
                      <Icon>{prop.icon}</Icon>
                    ) : (
                      <prop.icon />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={prop.name}
                    secondary={
                      <b
                        className={
                          caret +
                          " " +
                          (this.state[prop.state] ? classes.caretActive : "")
                        }
                      />
                    }
                    disableTypography={true}
                    className={itemText}
                  />
                </ListItem>
                <Collapse in={this.state[prop.state]} unmountOnExit>
                  <List className={classes.list + " " + classes.collapseList}>
                    {prop.views.map((prop, key) => {
                      if (prop.redirect) {
                        return null;
                      }
                      if (prop.sidebar === false) {
                        return null;
                      }
                      if (!user.canOne(prop.permission)) {
                        return null;
                      }
                      const navLinkClasses =
                        classes.collapseItemLink +
                        " " +
                        cx({
                          [" " + classes[color]]: this.activeRoute(prop.path),
                        });
                      const collapseItemMini = classes.collapseItemMini;
                      return (
                        <ListItem
                          key={key}
                          component={NavLink}
                          to={this.BASE_PATH + prop.path}
                          className={
                            navLinkClasses + " " + classes.collapseItem
                          }
                        >
                          {prop.mini && (
                            <span className={collapseItemMini}>
                              {prop.mini}
                            </span>
                          )}
                          {prop.icon && (
                            <ListItemIcon
                              className={
                                classes.itemIcon +
                                " " +
                                classes.collapseItemIcon
                              }
                            >
                              <prop.icon />
                            </ListItemIcon>
                          )}
                          <ListItemText
                            primary={prop.name}
                            disableTypography={true}
                            className={collapseItemText}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </React.Fragment>
            );
          }
          const navLinkClasses =
            classes.itemLink +
            " " +
            cx({
              [" " + classes[color]]: this.activeRoute(prop.path),
            });
          const itemIcon = classes.itemIcon;
          const IconComponent = prop.icon ?? "";
          return (
            <ListItem
              key={key}
              component={NavLink}
              to={this.BASE_PATH + prop.path}
              className={navLinkClasses}
            >
              <ListItemIcon className={itemIcon}>
                {typeof IconComponent === "string" ? (
                  <Icon>{IconComponent}</Icon>
                ) : (
                  <IconComponent />
                )}
              </ListItemIcon>
              <ListItemText
                primary={prop.name}
                disableTypography={true}
                className={itemText}
              />
            </ListItem>
          );
        })}
      </List>
    );

    const logoNormal =
      classes.logoNormal +
      " " +
      cx({
        [classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive,
      });
    const logoMini = classes.logoMini;
    const logoClasses =
      classes.logo +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white",
      }) +
      " " +
      cx({
        [classes.configBackground]: configBackground,
      });
    var brand = (
      <div className={logoClasses}>
        <a href="/" className={logoMini}>
          <img src={logo} alt="logo" className={classes.img} />
        </a>
        <a href="/" className={logoNormal}>
          {logoText}
        </a>
      </div>
    );
    const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1,
      });
    return (
      <div>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="right"
            open={this.props.open}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={userBox}
              headerLinks={null}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor="left"
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={userBox}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "blue",
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose",
    "config",
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  location: PropTypes.any,
  miniActive: PropTypes.any,
  handleDrawerToggle: PropTypes.func,
  open: PropTypes.any,
  routes: PropTypes.arrayOf(PropTypes.object),
  basePath: PropTypes.string,
  linkPath: PropTypes.string,
  linkText: PropTypes.string,
  configBackground: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(withStyles(sidebarStyle)(Sidebar));
