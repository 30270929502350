import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// @mui/icons-material
import Home from "@mui/icons-material/Home";

// core components
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import SelectSearch from "components/CustomSelect/SelectSearch";
import FormSubmitButtons from "components/CustomButtons/FormSubmitButtons";
import AdresseForm from "./Adresse/AdresseForm";
import { withUserContext } from "context/UserContext";

class LogementsForm extends React.Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/logements";
    this.FRONT_URL = "/logements";
    this.QUERY_PARAMS = new URLSearchParams(props.location.search);

    this.state = {
      // NESTED DATA
      adresse: null,
      // DATA FORM
      beneficiaire_id: +this.QUERY_PARAMS.get("beneficiaire_id") || "",
      // END DATA FORM
      loading: false,
      errors: null,
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.loadAsyncData();
    }
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = result.data ?? {};
        this.setState(state);
      });
  }

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChangeAdresse = (newAdresse) => {
    this.setState((prevState) => ({
      adresse: { ...prevState.adresse, ...newAdresse },
    }));
  };

  handleSubmit = () => {
    const { id, beneficiaire_id, adresse } = this.state;

    const dataIn = {
      beneficiaire_id: beneficiaire_id,
      adresse,
    };

    let request = null;
    if (id) {
      // modify
      request = axiosApiBackend.put(this.BACKEND_URL + "/" + id, dataIn);
    } else {
      // create
      request = axiosApiBackend.post(this.BACKEND_URL, dataIn);
    }
    request
      .then((res) => {
        this.handleSuccess(res.data.id);
      })
      .catch((err) => {
        this.setState({
          errors: err.response?.data?.errors ?? {},
          loading: false,
        });
      });

    this.setState({
      errors: null,
      loading: true,
    });
  };

  handleSuccess = (id) => {
    this.props.history.push(this.FRONT_URL + "/detail/" + id);
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const { id, adresse, beneficiaire_id, loading, errors } = this.state;

    return (
      <Grid container spacing={2}>
        {errors && (
          <Grid item xs={12}>
            <Typography variant="h6" component="h4" color="error">
              {Object.entries(errors).length === 0 ? (
                <>Une erreur est survenue.</>
              ) : (
                <>Merci de corriger les champs en rouge du formulaire.</>
              )}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Home />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  {id ? <>Modifier le Logement</> : <>Ajouter un Logement</>}
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12}>
                  <SelectSearch
                    name="beneficiaire_id"
                    label="Bénéficiaire"
                    apiUrl="/beneficiaires"
                    onChange={this.handleChangeByName}
                    value={beneficiaire_id}
                    buildOptionLabel={(data) =>
                      (data.prenom ?? "") + " " + (data.nom ?? "")
                    }
                    shrink
                    error={Boolean(errors?.beneficiaire_id)}
                  />
                </Grid>
                <AdresseForm
                  adresse={adresse}
                  onChange={this.handleChangeAdresse}
                  errors={errors}
                  isEditMode={Boolean(id)}
                  hideRegion
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <FormSubmitButtons
            onClick={this.handleSubmit}
            onCancel={this.handleCancel}
            loading={loading}
            isEdit={Boolean(id)}
          />
        </Grid>
      </Grid>
    );
  }
}

LogementsForm.propTypes = {
  location: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(LogementsForm);
