import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/icons-material
import Description from "@mui/icons-material/Description";
import SubtitlesIcon from "@mui/icons-material/Subtitles";

// core components
import Grid from "@mui/material/Grid";
import Button from "components/Button/Button";
import MenuBottom from "components/MenuBottom/MenuBottom";
import ConfigContainer from "components/ConfigContainer/ConfigContainer";
import { withUserContext } from "context/UserContext";

class AgentNationalConfig extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    this.FRONT_URL = "/configuration/agents-nationaux";
    this.LABEL = "Agents Nationaux";

    this.state = {};
  }

  render() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <ConfigContainer
            icon={<SubtitlesIcon />}
            title="Titre"
            backendUrl="/config-an-titre"
            label={this.LABEL}
          />
        </Grid>

        {this.CAN_VIEW_LOG_ACTIVITY && (
          <MenuBottom>
            <Link to={this.FRONT_URL + "/logs?type=ConfigAgentNational"}>
              <Button color="primary" square round>
                <Description />
              </Button>
            </Link>
          </MenuBottom>
        )}
      </Grid>
    );
  }
}

AgentNationalConfig.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(AgentNationalConfig);
