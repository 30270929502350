import React, { Component } from "react";
import PropTypes from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";

// @mui/icons-material
import Settings from "@mui/icons-material/Settings";

// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import SettingsFormContainer from "components/SettingsFormContainer/SettingsFormContainer";
import TextInput from "components/CustomInput/TextInput";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import InfoLightTooltip from "components/Tooltip/InfoLightTooltip";
import NumberInput from "components/CustomInput/NumberInput";

class GeneralSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // DATA FORM
      org_nom: "",
      bi_nationale: "",
      bi_locale: "",
      // END DATA FORM
      errors: null,
    };
  }

  componentDidMount() {}

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { errors } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <SettingsFormContainer
            icon={<Settings />}
            title="Modifier les Informations"
            backendUrl="/settings/general"
            formSetData={(data) => {
              this.setState({
                errors: null,
                org_nom: data.org_nom,
                bi_nationale: data.bi_nationale,
                bi_locale: data.bi_locale,
              });
            }}
            formGetData={() => ({
              org_nom: this.state.org_nom,
              bi_nationale: this.state.bi_nationale,
              bi_locale: this.state.bi_locale,
            })}
            formSetErrors={(err) => {
              this.setState({
                errors: err,
              });
            }}
            formContent={
              <GridContainer>
                {errors && (
                  <GridItem xs={12}>
                    <Typography variant="h6" component="h4" color="error">
                      {Object.entries(errors).length === 0 ? (
                        <>Une erreur est survenue.</>
                      ) : (
                        <>
                          Merci de corriger les champs en rouge du formulaire.
                        </>
                      )}
                    </Typography>
                  </GridItem>
                )}
                <GridItem xs={12}>
                  <TextInput
                    label="Nom de la structure"
                    name="org_nom"
                    onChange={this.handleChange}
                    value={this.state.org_nom}
                    error={Boolean(errors?.org_nom)}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <HeaderTitle
                    label={
                      <>
                        Configuration BI{" "}
                        <InfoLightTooltip
                          title={`Si l'URL de votre tableau de bord est "bi.soliha.fr/dashboard/55-direction", vous devez simplement entrer le nombre 55`}
                        />
                      </>
                    }
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <NumberInput
                    label="Nationale"
                    name="bi_nationale"
                    onChange={this.handleChange}
                    value={this.state.bi_nationale}
                    error={Boolean(errors?.bi_nationale)}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <NumberInput
                    label="Locale"
                    name="bi_locale"
                    onChange={this.handleChange}
                    value={this.state.bi_locale}
                    error={Boolean(errors?.bi_locale)}
                  />
                </GridItem>
              </GridContainer>
            }
          />
        </GridItem>
      </GridContainer>
    );
  }
}

GeneralSettings.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
};

export default GeneralSettings;
