import React, { Component } from "react";

// @mui/material components
import Grid from "@mui/material/Grid";

// @mui/icons-material
import Palette from "@mui/icons-material/Palette";

// core components
import ColorPicker from "components/ColorPicker/ColorPicker";
import BoxColorRenderer from "components/CustomAgRenderer/BoxColorRenderer";
import TablePopUpContainer from "components/TablePopUpContainer/TablePopUpContainer";
import TextInput from "components/CustomInput/TextInput";

class ConfigTypologieCouleur extends Component {
  constructor(props) {
    super(props);
    this.LABEL = "Structure(s) Locale(s)";

    this.state = {
      id: "",
      value: "",
      color: "",
      errors: null,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeColor = (value) => {
    this.setState({ color: value });
  };

  render() {
    const { id, value, color, errors } = this.state;
    return (
      <TablePopUpContainer
        dialogProps={{
          fullWidth: true,
        }}
        icon={<Palette />}
        title="Typologie couleur"
        backendUrl="/config-sl-typologie-couleur"
        autoSize={false}
        colDef={[
          { field: "id", editable: false, hide: true },
          {
            headerName: "Libellé",
            field: "value",
            flex: 3,
          },
          {
            headerName: "Couleur",
            field: "color",
            flex: 1,
            cellRenderer: BoxColorRenderer,
            cellRendererParams: (params) => ({
              color: params.data.color,
            }),
          },
        ]}
        formInitData={() => {
          this.setState({
            errors: null,
            id: null,
            value: "",
            color: "",
          });
        }}
        formSetData={(data) => {
          this.setState({
            errors: null,
            id: data.id,
            value: data.value,
            color: data.color,
          });
        }}
        formGetData={() => ({
          id: id,
          value: value,
          color: color,
        })}
        formSetErrors={(err) => {
          this.setState({
            errors: err,
          });
        }}
        formTitle={id ? "Modifier la Typologie" : "Ajouter une Typologie"}
        formConfirmLabel={id ? "Modifier" : "Ajouter"}
        formContent={
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInput
                label="Libellé"
                name="value"
                value={value}
                onChange={this.handleChange}
                error={Boolean(errors?.value)}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <ColorPicker
                label="Couleur"
                name="color"
                fullWidth
                margin="normal"
                variant="outlined"
                value={color}
                onChange={this.handleChange}
                onChangeColor={this.handleChangeColor}
                error={Boolean(errors?.color)}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <BoxColorRenderer color={color} size={60} />
            </Grid>
          </Grid>
        }
        deleteDialog={{
          title: "Voulez vous supprimer cette Typologie ?",
          content: (data) => data.value,
          confirmLabel: "Supprimer",
        }}
        failDeleteDialog={{
          title: "Vous ne pouvez pas supprimer cette Typologie.",
          content: (data, error) =>
            (data.value ?? "") +
            " est associé à " +
            (error ?? "") +
            " " +
            this.LABEL,
        }}
      />
    );
  }
}

export default ConfigTypologieCouleur;
