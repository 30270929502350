import React from "react";
import PropTypes from "prop-types";

import Button from "components/CustomButtons/Button.jsx";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Render from "Utils/RenderUtils";

class LogDetailPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  formatProperty = (property) => {
    return property.replace(/_/g, " ");
  };

  renderData = (data) => {
    let detail = JSON.parse(data);
    return (
      <>
        {Object.entries(detail).map(([key, value]) => {
          return (
            <p key={key}>
              <b style={{ textTransform: "capitalize" }}>
                {this.formatProperty(key)}
              </b>
              :{" "}
              {typeof value === "object"
                ? JSON.stringify(value)
                : value.toString()}
            </p>
          );
        })}
      </>
    );
  };

  render() {
    return (
      <>
        <Button
          justIcon={this.props.params.justIcon}
          color={this.props.params.color}
          size="sm"
          onClick={this.handleOpen}
        >
          {this.props.params.label}
        </Button>
        <Dialog open={this.state.open} maxWidth="sm" fullWidth={true}>
          <DialogTitle>
            Détails Action {Render.CRUDAction(this.props.params.data.action)}
          </DialogTitle>
          <DialogContent>
            {this.renderData(this.props.params.data.data)}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="github">
              Fermer
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

LogDetailPopup.propTypes = {
  params: PropTypes.any,
};

export default LogDetailPopup;
