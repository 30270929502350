import React, { Component } from "react";
import PropTypes from "prop-types";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import TablePopUpContainer from "components/TablePopUpContainer/TablePopUpContainer";
import TextInput from "components/CustomInput/TextInput";

class ConfigContainer extends Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = props.backendUrl;

    this.state = {
      id: null,
      value: "",
      errors: null,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { icon, title, label } = this.props;
    const { id, value, errors } = this.state;

    return (
      <TablePopUpContainer
        icon={icon}
        dialogProps={{
          fullWidth: true,
        }}
        title={title}
        backendUrl={this.BACKEND_URL}
        autoSize={false}
        colDef={[
          {
            headerName: "Libellé",
            field: "value",
          },
        ]}
        formInitData={() => {
          this.setState({
            id: null,
            value: null,
            errors: null,
          });
        }}
        formSetData={(data) => {
          this.setState({
            id: data.id ?? null,
            value: data.value,
            errors: null,
          });
        }}
        formGetData={() => ({
          id: id,
          value: value,
        })}
        formSetErrors={(err) => {
          this.setState({
            errors: err,
          });
        }}
        formTitle={id ? "Modifier le champ" : "Ajouter un champ"}
        formConfirmLabel={id ? "Modifier" : "Ajouter"}
        formContent={
          <GridContainer>
            <GridItem xs={12}>
              <TextInput
                label="Libellé"
                name="value"
                value={value}
                onChange={this.handleChange}
                error={Boolean(errors?.value)}
              />
            </GridItem>
          </GridContainer>
        }
        deleteDialog={{
          title: "Voulez vous supprimer ce champ ?",
          content: (data) => data.value,
          confirmLabel: "Supprimer",
        }}
        failDeleteDialog={{
          title: "Vous ne pouvez pas supprimer ce champ.",
          content: (data, error) =>
            (data.value ?? "") +
            " est associé à " +
            (error ?? "") +
            " " +
            label,
        }}
      />
    );
  }
}

ConfigContainer.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  backendUrl: PropTypes.string,
  label: PropTypes.string,
};

export default ConfigContainer;
