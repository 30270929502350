export default class GedUtils {
  static READABLE_TYPE = { "App\\Models\\Amo": "Amo" };
  static TYPE_URL = { "App\\Models\\Amo": "/amo" };

  static renderType = (type) => {
    return this.READABLE_TYPE[type] ?? type;
  };
  static getUrl = (type) => {
    return this.TYPE_URL[type] ?? "";
  };
}
