import Render from "./RenderUtils";

export default class MissionUtils {
  static renderTreeItem = (item, price = null, displayPrice = true) => {
    const description = Render.ifNotEmpty(item.description?.slice(0, 40)) ?? "";
    let text = description + " (" + item.titre + ")";

    if (displayPrice) {
      const cout = Render.ifNotEmpty(
        Render.euro(price ?? item.cout_facturation),
        " - ",
      );
      text += cout;
    }

    return text;
  };
}
