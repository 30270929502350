import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/icons-material
import Description from "@mui/icons-material/Description";
import Person from "@mui/icons-material/Person";
import Man from "@mui/icons-material/Man";

// core components
import Grid from "@mui/material/Grid";
import Button from "components/Button/Button";
import MenuBottom from "components/MenuBottom/MenuBottom";
import ConfigContainer from "components/ConfigContainer/ConfigContainer";
import { withUserContext } from "context/UserContext";
import TablePopUpContainer from "components/TablePopUpContainer/TablePopUpContainer";
import { Apartment } from "@mui/icons-material";
import BoxColorRenderer from "components/CustomAgRenderer/BoxColorRenderer";
import { TextField } from "@mui/material";
import ColorPicker from "components/ColorPicker/ColorPicker";

class BeneficiaireConfig extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    this.FRONT_URL = "/configuration/beneficiaires";
    this.LABEL = "Bénéficiaires";

    this.state = {
      // DATA FORM
      id: "",
      value: "",
      color_mpr: "",
      // END DATA FORM
      errors: null,
    };
  }

  handleChangeColor = (value) => {
    this.setState({ color_mpr: value });
  };

  render() {
    const { id, value, color_mpr, errors } = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <ConfigContainer
            icon={<Man />}
            title="Civilité"
            backendUrl="/config-beneficiaire-civilite"
            label={this.LABEL}
          />
          <TablePopUpContainer
            dialogProps={{
              fullWidth: true,
            }}
            icon={<Apartment />}
            title="Catégorie de ressources"
            backendUrl="/config-categorie-ressource"
            autoSize={false}
            colDef={[
              { field: "id", editable: false, hide: true },
              {
                headerName: "Libellé",
                field: "value",
                flex: 3,
              },
              {
                headerName: "Couleur MPR",
                field: "color_mpr",
                flex: 1,
                cellRenderer: BoxColorRenderer,
                cellRendererParams: (params) => ({
                  color: params.data.color_mpr,
                }),
              },
            ]}
            formInitData={() => {
              this.setState({
                errors: null,
                id: "",
                value: "",
                color_mpr: "",
              });
            }}
            formSetData={(data) => {
              this.setState({
                errors: null,
                id: data.id ?? "",
                value: data.value ?? "",
                color_mpr: data.color_mpr ?? "",
              });
            }}
            formGetData={() => ({
              id: id,
              value: value,
              color_mpr: color_mpr,
            })}
            formSetErrors={(err) => {
              this.setState({
                errors: err,
              });
            }}
            getDeleteContent={(data) => data.value}
            getDeleteError={(data, error) => (
              <>
                {error?.countBeneficiaires > 0 && (
                  <li>
                    {error.countBeneficiaires} {this.LABEL} associée(s)
                  </li>
                )}
                {error?.countDispositifs > 0 && (
                  <li>{error.countDispositifs} dispositif(s) associé(s)</li>
                )}
              </>
            )}
            formTitle={
              id
                ? "Modifier la Catégorie de ressources"
                : "Ajouter une Catégorie de ressources"
            }
            formConfirmLabel={id ? "Modifier" : "Ajouter"}
            formContent={
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Libellé"
                    name="value"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={value}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.value)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ColorPicker
                    label="Couleur MPR"
                    name="color_mpr"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={color_mpr}
                    onChange={this.handleChange}
                    onChangeColor={this.handleChangeColor}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={Boolean(errors?.color_mpr)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <BoxColorRenderer color={color_mpr} size={60} />
                </Grid>
              </Grid>
            }
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <ConfigContainer
            icon={<Person />}
            title="Type de représentant"
            backendUrl="/config-beneficiaire-type-representant"
            label={this.LABEL}
          />
        </Grid>
        {this.CAN_VIEW_LOG_ACTIVITY && (
          <MenuBottom>
            <Link to={this.FRONT_URL + "/logs?type=ConfigBeneficiaire"}>
              <Button square round>
                <Description />
              </Button>
            </Link>
          </MenuBottom>
        )}
      </Grid>
    );
  }
}

BeneficiaireConfig.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.any,
};

export default withUserContext(BeneficiaireConfig);
