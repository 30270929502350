import React, { Component } from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

// @mui/material components
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// @mui/icons-material
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// core components
import MissionUtils from "Utils/MissionUtils";

class ActiviteSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      availableMissions: [],
      expandedAccordions: {},
    };
  }

  componentDidMount() {
    if (this.props.structureId) {
      this.loadAsyncData();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.structureId !== prevProps.structureId) {
      this.loadAsyncData();
    }
  }

  loadAsyncData() {
    axiosApiBackend
      .get("/missions?structure_id=" + this.props.structureId)
      .then((result) => {
        const missions = [];
        const expandedAccordions = {};

        (result.data ?? []).forEach((mission) => {
          if (mission.activites?.length > 0) {
            // Sort activites
            mission.activites.sort((a, b) => a.titre.localeCompare(b.titre));
            missions.push(mission);

            expandedAccordions[mission.id] = mission.activites.some((a) =>
              this.props.activiteIds.includes(a.id),
            );
          }
        });

        // Sort missions
        missions.sort((a, b) => a.titre.localeCompare(b.titre));

        this.setState({
          availableMissions: missions,
          expandedAccordions: expandedAccordions,
        });
      });
  }

  toggleItemInArray = (array, item) => {
    const newArray = [...array];
    const index = newArray.indexOf(item);

    if (index === -1) {
      newArray.push(item);
    } else {
      newArray.splice(index, 1);
    }

    return newArray;
  };

  handleToggleActivity = (activiteId) => () => {
    let newActiviteIds = this.toggleItemInArray(
      this.props.activiteIds,
      activiteId,
    );

    this.props.onChangeActivites(newActiviteIds);
  };

  handleToggleMission = (missionId) => (event) => {
    event.stopPropagation();
    event.preventDefault();

    const { availableMissions } = this.state;
    const { activiteIds, onChangeActivites } = this.props;

    const mission = availableMissions.find((m) => m.id === missionId);
    if (!mission) return;

    const missionActiviteIds = mission.activites.map((a) => a.id);
    let newActiviteIds;

    if (this.isMissionFullyChecked(missionId)) {
      // Remove all activity IDs associated with the mission from the existing array
      const activiteIdsSet = new Set(missionActiviteIds);
      newActiviteIds = activiteIds.filter((id) => !activiteIdsSet.has(id));
    } else {
      // Merge existing and new activity IDs, remove duplicates
      newActiviteIds = [...new Set([...activiteIds, ...missionActiviteIds])];
    }

    onChangeActivites(newActiviteIds);
  };

  isCheckedActivity = (value) => {
    return this.props.activiteIds.indexOf(value) !== -1;
  };

  isIndeterminateMission = (missionId) => {
    const activites = this.state.availableMissions.find(
      (mission) => mission.id === missionId,
    ).activites;

    const checkedActivites = activites.filter((activite) =>
      this.isCheckedActivity(activite.id),
    ).length;

    return checkedActivites > 0 && checkedActivites < activites.length;
  };

  isMissionFullyChecked = (missionId) => {
    const { availableMissions } = this.state;
    const { activiteIds } = this.props;

    const mission = availableMissions.find((m) => m.id === missionId);
    if (!mission) {
      return false;
    }

    // Return false if one activity is unchecked
    return !mission.activites.some((a) => !activiteIds.includes(a.id));
  };

  render() {
    const { availableMissions, expandedAccordions } = this.state;

    return (
      <>
        {availableMissions.map((mission, key) => (
          <Accordion
            key={key}
            disableGutters
            elevation={0}
            expanded={expandedAccordions[mission.id] ?? false}
            onChange={(_, isExpanded) => {
              this.setState((prevState) => ({
                expandedAccordions: {
                  ...prevState.expandedAccordions,
                  [mission.id]: isExpanded,
                },
              }));
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid container alignItems="center">
                <Grid item>
                  <Checkbox
                    onClick={this.handleToggleMission(mission.id)}
                    checked={this.isMissionFullyChecked(mission.id)}
                    indeterminate={this.isIndeterminateMission(mission.id)}
                  />
                </Grid>
                <Grid item>
                  <Typography>
                    {MissionUtils.renderTreeItem(mission)}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails sx={{ py: 0 }}>
              <List sx={{ p: 0 }}>
                {mission.activites.map((activite, key2) => (
                  <ListItem key={key2} disablePadding>
                    <ListItemButton
                      sx={{ py: 0 }}
                      dense
                      onClick={this.handleToggleActivity(activite.id)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          checked={this.isCheckedActivity(activite.id)}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={MissionUtils.renderTreeItem(activite)}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
      </>
    );
  }
}

ActiviteSelection.propTypes = {
  structureId: PropTypes.any,
  activiteIds: PropTypes.array,
  onChangeActivites: PropTypes.func,
};

export default ActiviteSelection;
