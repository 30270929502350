import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { axiosApiBackendNoJson } from "variables/axiosConfigs.jsx";

import AlertDialog from "components/AlertDialog/AlertDialog";
import TextInput from "components/CustomInput/TextInput";
import Grid from "@mui/material/Grid";
import Snackbar from "components/Popups/Snackbar";
import Typography from "@mui/material/Typography";

function SignModal({
  open,
  onClose,
  onSignSent,
  rootFolder,
  section,
  type,
  objectId,
  getSignataires,
  filename,
}) {
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(false);
  const [errors, setErrors] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [signataires, setSignataires] = useState([]);
  function changeSignataire(key, event) {
    const nextSignataires = [...signataires];
    const { name, value } = event.target;
    nextSignataires[key][name] = value;
    setSignataires(nextSignataires);
  }
  function addSignataire() {
    const nextSignataires = [...signataires, {}];
    setSignataires(nextSignataires);
  }
  function removeSignataire(key) {
    const nextSignataires = signataires.filter((a, k) => k !== key);
    setSignataires(nextSignataires);
  }
  useEffect(() => {
    if (open == true) {
      // let's init signataire when we open the modal (this way we don't have to keep signataires in parent state component)
      const signataires = getSignataires ? getSignataires() : [];
      setSignataires(signataires);
    }
  }, [open]);

  function handleClose() {
    setErrors(null);
    setErrorMessage(null);
    onClose();
  }

  function handleSubmit() {
    const data = {
      rootFolder: rootFolder,
      section: section,
      type: type,
      objectId: objectId,
      filename: filename,
      signataires: signataires,
    };
    setLoading(true);
    setErrors(null);
    setErrorMessage(null);
    axiosApiBackendNoJson
      .post("/ged/sign", data)
      .then(() => {
        onSignSent && onSignSent();
        setLoading(false);
        setSnackbarOpen(true);
        setSnackbarMessage("Signature Envoyée");
        handleClose();
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 404) {
          setErrorMessage("Le fichier est introuvable");
        } else if (error.response.status === 422) {
          setErrors(error.response.data.errors);
        } else {
          setErrorMessage("Une erreur est survenue côté serveur");
        }
      });
  }

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
      />
      <AlertDialog
        open={open}
        title="Signer un document"
        loading={loading}
        onConfirm={handleSubmit}
        confirmLabel="Lancer la signature"
        confirmColor="success"
        onCancel={handleClose}
        cancelLabel="Annuler"
        cancelColor="primary"
      >
        <>
          <Typography color="error">{errorMessage}</Typography>
          {signataires.map((signataire, key) => (
            <React.Fragment key={key}>
              <Grid container columnSpacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h5">Signataire {key + 1} :</Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Prénom"
                    name="prenom"
                    value={signataire.prenom}
                    onChange={(event) => changeSignataire(key, event)}
                    error={Boolean(errors?.[`signataires.${key}.prenom`])}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    label="Nom"
                    name="nom"
                    value={signataire.nom}
                    onChange={(event) => changeSignataire(key, event)}
                    error={Boolean(errors?.[`signataires.${key}.nom`])}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label="Email"
                    name="email"
                    value={signataire.email}
                    onChange={(event) => changeSignataire(key, event)}
                    error={Boolean(errors?.[`signataires.${key}.email`])}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label="Texte d'ancrage"
                    name="anchor_string"
                    value={signataire.anchor_string}
                    onChange={(event) => changeSignataire(key, event)}
                    error={Boolean(
                      errors?.[`signataires.${key}.anchor_string`],
                    )}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        </>
      </AlertDialog>
    </>
  );
}

SignModal.propTypes = {
  open: PropTypes.any,
  onClose: PropTypes.func,
  onSignSent: PropTypes.func,
  rootFolder: PropTypes.oneOf(["documents", "templates"]).isRequired,
  section: PropTypes.oneOf(["portail"]).isRequired,
  type: PropTypes.oneOf([
    "structurenationale",
    "structurelocale",
    "beneficiaire",
    "logement",
    "amo",
  ]).isRequired,
  objectId: PropTypes.any,
  filename: PropTypes.any,
  getSignataires: PropTypes.func,
};

export default SignModal;
