import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";

// @mui/material components
import Button from "@mui/material/Button";

// @mui/icons-material

// core components
import SelectSearch from "components/CustomSelect/SelectSearch";
import TextInput from "components/CustomInput/TextInput";
import AdresseInput from "components/CustomInput/AdresseInput";
import HeaderTitle from "components/HeaderTitle/HeaderTitle";
import GridItem from "components/Grid/GridItem.jsx";
import { withUiCustomizationContext } from "context/UiCustomizationContext";
import AlertDialog from "components/AlertDialog/AlertDialog";
import GridContainer from "components/Grid/GridContainer";

class AdresseForm extends React.Component {
  constructor(props) {
    super(props);

    const uiCustomization = props.uiCustomization;

    this.state = {
      dep_code: props.isEditMode ? "" : uiCustomization?.dep_code ?? "",
      open: false,
      loading: false,
    };
  }

  componentDidMount() {
    const { isEditMode, adresse } = this.props;

    if (isEditMode) {
      this.setState({
        dep_code: adresse?.ville?.code_departement || "",
      });
    }
  }

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  componentDidUpdate(prevProps) {
    const { isEditMode, adresse } = this.props;

    if (
      isEditMode &&
      prevProps.adresse?.ville?.code_departement !==
        adresse?.ville?.code_departement
    ) {
      this.setState({
        dep_code: adresse.ville.code_departement,
      });
    }
  }

  updateAdresse = (newValues) => {
    if (this.props?.onChange) {
      this.props.onChange(newValues);
    }
  };

  handleChange = (event) => {
    this.updateAdresse({ [event.target.name]: event.target.value });
  };

  handleChangeAddress = (event) => {
    this.updateAdresse({
      [event.target.name]: event.target.value,
      ban_id: "",
    });
  };

  handleChangeDepartement = (name, value) => {
    this.setState({ [name]: value });
    this.updateAdresse({ ville_id: "", ban_id: "" });
  };

  handleChangeAddressByName = (name, value) => {
    this.updateAdresse({ [name]: value, ban_id: "" });
  };

  handleAdresseSelected = (adresseDetails) => {
    const {
      dep_code,
      cp,
      code_insee,
      numero_voie,
      type_voie,
      nom_voie,
      latitude,
      longitude,
      ban_id,
      region,
    } = adresseDetails;

    const state = {
      numero_voie: numero_voie,
      type_voie: type_voie,
      nom_voie: nom_voie,
      latitude: latitude,
      longitude: longitude,
      ban_id: ban_id,
      region: region,
    };

    this.setState({ dep_code: dep_code, open: false });

    axiosApiBackend
      .get("/villes/getMatchingVille", {
        params: { cp: cp, code_insee: code_insee },
      })
      .then((result) => {
        this.updateAdresse({
          ...state,
          ville_id: result.data.id,
        });
      })
      .catch(() => {
        this.updateAdresse(state);
      });
  };

  render() {
    const { dep_code, loading, open } = this.state;
    const { adresse, hideAdresseDetails, hideRegion, errors } = this.props;

    return (
      <>
        <GridItem xs={12}>
          <HeaderTitle label="Adresse" />
        </GridItem>
        <GridItem xs={12} style={{ textAlign: "center" }}>
          <Button fullWidth color="primary" size="sm" onClick={this.toggle}>
            Rechercher une adresse
          </Button>
          <AlertDialog
            open={open}
            fullWidth
            outsideClickCancel
            title="Adresse - Saisissez votre adresse ci-dessous"
            onCancel={this.toggle}
            cancelLabel="Annuler"
            cancelColor="reversed"
            loading={loading}
          >
            <GridContainer>
              <GridItem xs={12}>
                <AdresseInput onAdresseSelected={this.handleAdresseSelected} />
              </GridItem>
            </GridContainer>
          </AlertDialog>
        </GridItem>
        {!hideRegion && (
          <GridItem xs={12}>
            <TextInput
              label="Région"
              name="region"
              onChange={this.handleChangeAddress}
              value={adresse?.region}
              error={Boolean(errors?.["adresse.region"])}
            />
          </GridItem>
        )}
        <GridItem xs={12} lg={6}>
          <TextInput
            label="Numéro de la voie"
            name="numero_voie"
            onChange={this.handleChangeAddress}
            value={adresse?.numero_voie}
            error={Boolean(errors?.["adresse.numero_voie"])}
          />
        </GridItem>
        <GridItem xs={12} lg={6}>
          <TextInput
            label="Type de voie"
            name="type_voie"
            onChange={this.handleChangeAddress}
            value={adresse?.type_voie}
            error={Boolean(errors?.["adresse.type_voie"])}
          />
        </GridItem>
        <GridItem xs={12}>
          <TextInput
            label="Nom de la voie"
            name="nom_voie"
            onChange={this.handleChangeAddress}
            value={adresse?.nom_voie}
            error={Boolean(errors?.["adresse.nom_voie"])}
          />
        </GridItem>
        <GridItem xs={12} lg={6}>
          <SelectSearch
            name="dep_code"
            label="Département"
            apiUrl="/departements"
            onChange={this.handleChangeDepartement}
            value={dep_code}
            buildOptionValue={(data) => data.code}
            buildOptionLabel={(data) => data.code + " " + data.nom}
            shrink
            error={Boolean(errors?.["adresse.ville_id"])}
          />
        </GridItem>
        <GridItem xs={12} lg={6}>
          <SelectSearch
            name="ville_id"
            label={
              "Ville" + (!dep_code ? " - Choisissez un département..." : "")
            }
            loadOnMount={Boolean(dep_code)}
            apiUrl={!dep_code ? null : "/villes"}
            filterKey="departement"
            filter={dep_code}
            onChange={this.handleChangeAddressByName}
            value={adresse?.ville_id}
            buildOptionLabel={(data) => data.cp + " " + data.ville}
            shrink
            error={Boolean(errors?.["adresse.ville_id"])}
            disabled={!dep_code}
          />
        </GridItem>
        <GridItem xs={12} lg={6}>
          <TextInput
            label="Latitude"
            name="latitude"
            value={adresse?.latitude}
            onChange={this.handleChangeAddress}
            error={Boolean(errors?.["adresse.latitude"])}
          />
        </GridItem>
        <GridItem xs={12} lg={6}>
          <TextInput
            label="Longitude"
            name="longitude"
            value={adresse?.longitude}
            onChange={this.handleChangeAddress}
            error={Boolean(errors?.["adresse.longitude"])}
          />
        </GridItem>
        {!hideAdresseDetails && (
          <>
            <GridItem xs={12}>
              <HeaderTitle label="Complément d'adresse" />
            </GridItem>
            <GridItem xs={12} lg={6}>
              <TextInput
                label="Résidence"
                name="residence"
                onChange={this.handleChange}
                value={adresse?.residence}
                error={Boolean(errors?.["adresse.residence"])}
              />
            </GridItem>
            <GridItem xs={12} lg={3}>
              <TextInput
                label="Bâtiment"
                name="batiment"
                onChange={this.handleChange}
                value={adresse?.batiment}
                error={Boolean(errors?.["adresse.batiment"])}
              />
            </GridItem>
            <GridItem xs={12} lg={3}>
              <TextInput
                label="Étage"
                name="etage"
                onChange={this.handleChange}
                value={adresse?.etage}
                error={Boolean(errors?.["adresse.etage"])}
              />
            </GridItem>
          </>
        )}
      </>
    );
  }
}

AdresseForm.defaultProps = {
  isEditMode: false,
  hideAdresseDetails: false,
  hideRegion: false,
};

AdresseForm.propTypes = {
  adresse: PropTypes.any,
  onChange: PropTypes.func,
  errors: PropTypes.any,
  isEditMode: PropTypes.bool,
  hideAdresseDetails: PropTypes.bool,
  hideRegion: PropTypes.bool,
  uiCustomization: PropTypes.object,
};

export default withUiCustomizationContext(AdresseForm);
