import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/icons-material
import Description from "@mui/icons-material/Description";
// import Work from "@mui/icons-material/Work";

// core components
import Grid from "@mui/material/Grid";
import Button from "components/Button/Button";
import MenuBottom from "components/MenuBottom/MenuBottom";
import { withUserContext } from "context/UserContext";
// import ConfigContainer from "components/ConfigContainer/ConfigContainer";

class StructureNationaleConfig extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    this.FRONT_URL = "/configuration/structures-nationales";
    // this.LABEL = "Structure(s) Nationale(s)";

    this.state = {};
  }

  render() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          {/* <ConfigContainer
            icon={<Work />}
            title="Métier"
            backendUrl="/config-sl-metier"
            label={this.LABEL}
          /> */}
        </Grid>
        {this.CAN_VIEW_LOG_ACTIVITY && (
          <MenuBottom>
            <Link to={this.FRONT_URL + "/logs?type=ConfigStructureNationale"}>
              <Button square round>
                <Description />
              </Button>
            </Link>
          </MenuBottom>
        )}
      </Grid>
    );
  }
}

StructureNationaleConfig.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(StructureNationaleConfig);
