import React from "react";
import PropTypes from "prop-types";
import { axiosApiBackendNoAuth } from "variables/axiosConfigs.jsx";
import { Link } from "react-router-dom";
import logo from "assets/img/soliha-logo.png";

//Services
import { verifyEmail, verifyLength } from "services/controlForms.jsx";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";

// core components
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import TextInput from "components/CustomInput/TextInput";
import { withUserContext } from "context/UserContext";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.QUERY_PARAMS = new URLSearchParams(props.location.search);

    this.state = {
      passwordChanged: Boolean(this.QUERY_PARAMS.get("passwordChanged")),
      message: "",
      loginEmail: "",
      loginPassword: "",
      errorPassword: false,
      successPassword: false,
      successEmail: false,
      errorEmail: false,
      loading: false,
    };
  }

  //function request
  getLogin = () => {
    this.setState({
      loading: true,
      passwordChanged: false,
      message: "",
    });

    const data = {
      email: this.state.loginEmail,
      password: this.state.loginPassword,
    };

    axiosApiBackendNoAuth
      .post("/login", data)
      .then((result) => {
        const { user } = this.props;

        user.storeData(result.data);
        window.location.replace("/");
      })
      .catch((error) => {
        if (
          error.response &&
          (error.response.status === 400 || error.response.status === 422)
        ) {
          this.setState({
            message: "Mot de passe ou email invalide.",
            errorPassword: true,
            successPassword: false,
            successEmail: false,
            errorEmail: true,
            loading: false,
          });
        } else if (error.response && error.response.status === 429) {
          this.setState({
            message:
              "Trop de tentatives de connexion ont échoué. Veuillez attendre quelques instants, puis réessayez.",
            loading: false,
          });
        } else {
          this.setState({
            message: "Connexion impossible",
            loading: false,
          });
        }
      });
  };

  loginClick = (e) => {
    e.preventDefault();
    this.getLogin();
  };

  change = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onBlurEmail = () => {
    let control = verifyEmail(this.state.loginEmail);
    this.setState({
      successEmail: control,
      errorEmail: !control,
    });
  };

  onBlurPassword = () => {
    let control = verifyLength(this.state.loginPassword, 8);
    this.setState({
      successPassword: control,
      errorPassword: !control,
    });
  };

  render() {
    const { successEmail, successPassword } = this.state;

    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardHeader
              title={
                <Box sx={{ textAlign: "center", mt: 2 }}>
                  <img style={{ width: "70%" }} src={logo} alt="Logo Soliha" />
                </Box>
              }
            />
            <CardContent>
              {this.state.passwordChanged && (
                <Typography style={{ color: "#388e3c" }}>
                  Votre mot de passe a bien été changé. Veuillez vous connecter.
                </Typography>
              )}
              <form onSubmit={this.loginClick}>
                <TextInput
                  sx={
                    successEmail ? { label: { color: "success.main" } } : null
                  }
                  label="Adresse email"
                  name="loginEmail"
                  type="email"
                  onChange={this.change}
                  onBlur={this.onBlurEmail}
                  value={this.state.loginEmail}
                  error={this.state.errorEmail}
                  color={successEmail ? "success" : null}
                  required
                />
                <TextInput
                  sx={
                    successPassword
                      ? { label: { color: "success.main" } }
                      : null
                  }
                  label="Mot de passe"
                  name="loginPassword"
                  type="password"
                  onChange={this.change}
                  onBlur={this.onBlurPassword}
                  value={this.state.loginPassword}
                  error={this.state.errorPassword}
                  color={successPassword ? "success" : null}
                  required
                />
                <Typography color="error">{this.state.message}</Typography>

                <Box
                  sx={{
                    marginBottom: "0",
                    color: "#999999",
                    fontSize: "14px",
                    py: "10px",
                  }}
                >
                  <small>*</small> Champs obligatoires
                </Box>

                <Box sx={{ textAlign: "center" }}>
                  <LoadingButton type="submit" loading={this.state.loading}>
                    Se connecter
                  </LoadingButton>
                </Box>
              </form>
              <Typography align="right">
                <Link to="/forgot-password">Mot de passe oublié ?</Link>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

LoginForm.propTypes = {
  history: PropTypes.any,
  location: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(LoginForm);
