import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import Stack from "@mui/material/Stack";

// @mui/icons-material

import Button from "components/Button/Button";

class ButtonRenderer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <Stack direction="row" spacing={0.5}>
        {this.props.buttons.map(
          (button, key) =>
            !button.hide && (
              <Button
                key={key}
                square
                color={button.color}
                onClick={button.onClick}
                disabled={button.disabled}
              >
                {button.label}
              </Button>
            ),
        )}
      </Stack>
    );
  }
}

ButtonRenderer.propTypes = {
  buttons: PropTypes.array,
};

export default ButtonRenderer;
