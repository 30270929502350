import styled from "@mui/system/styled";
import Typography from "@mui/material/Typography";

const MultilineTypography = styled(Typography)({
  paddingLeft: "10px",
  paddingRight: "10px",
  whiteSpace: "pre-wrap",
  hyphens: "auto",
  overflowWrap: "break-word",
});

export default MultilineTypography;
