import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import InputAdornment from "@mui/material/InputAdornment";

// @mui/icons-material
import Cancel from "@mui/icons-material/Cancel";
import Search from "@mui/icons-material/Search";
import TextInput from "components/CustomInput/TextInput";

//Components

function SearchBar(props) {
  const { margin, size, resetSearchValue, value, ...rest } = props;

  return (
    <TextInput
      value={value}
      size={size}
      margin={margin}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            onClick={resetSearchValue}
            position="end"
            style={{ cursor: "pointer" }}
          >
            {value !== "" ? <Cancel /> : <div style={{ width: "24px" }} />}
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
}

SearchBar.defaultProps = {
  margin: "dense",
  size: "small",
};

SearchBar.propTypes = {
  margin: PropTypes.any,
  value: PropTypes.any,
  size: PropTypes.any,
  resetSearchValue: PropTypes.func,
};

export default SearchBar;
