import React, { Component } from "react";
import PropTypes from "prop-types";
import { axiosApiBackend } from "variables/axiosConfigs";
import { AgGridReact } from "ag-grid-react";

// @mui/material components
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import SettingsBackupRestore from "@mui/icons-material/SettingsBackupRestore";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/CardV2/Card";
import CardHeader from "components/CardV2/CardHeader";
import CardAvatar from "components/CardV2/CardAvatar";
import CardContent from "components/CardV2/CardContent";
import Button from "components/CustomButtons/Button";
import AlertDialog from "components/AlertDialog/AlertDialog";
import ButtonRenderer from "components/CustomAgRenderer/ButtonRenderer";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";
import SearchBarService from "services/SearchBarService";
import SearchBar from "components/SearchBar/SearchBar";
import SelectSearch from "components/CustomSelect/SelectSearch";
import QueryUtils from "Utils/QueryUtils";

class TablePopUpContainer extends Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = props.backendUrl;
    this.FRONT_URL = props.frontUrl;
    this.getRowStyle = (params) => {
      if (params.data.deleted_at) {
        return { backgroundColor: "#d1d1d1" };
      }
      return null;
    };
    this.SEARCH_BAR_SERVICE = new SearchBarService(
      this.FRONT_URL + "/" + props.searchStorageKey,
    );

    let buttonsColumnDef = {
      headerName: "",
      field: "actions",
      type: "rightAligned",
      filter: false,
      editable: false,
      sortable: false,
      autoHeight: true,
      wrapText: false,
      pinned: "right",
      cellStyle: { justifyContent: "center" },
      cellRenderer: ButtonRenderer,
      cellRendererParams: (params) => {
        const { hideEditButton, hideDeleteButton, hideButton, customButtons } =
          this.props;

        let buttons = customButtons ? customButtons(params.data) : [];
        if (!params.data.deleted_at) {
          buttons.push({
            label: <Edit />,
            color: "primary",
            hide: hideEditButton || (hideButton ? hideButton(params.data) : ""),
            onClick: () => this.onClickEditButton(params),
          });
          buttons.push({
            label: <Delete />,
            color: "error",
            hide:
              hideDeleteButton || (hideButton ? hideButton(params.data) : ""),
            onClick: () => this.onClickDeleteButton(params),
          });
        } else {
          buttons.push({
            label: <SettingsBackupRestore />,
            color: "success",
            hide: hideDeleteButton || hideButton ? hideButton(params.data) : "",
            onClick: () => this.onClickRestoreButton(params),
          });
        }

        return {
          buttons: buttons,
        };
      },
    };

    if (props.hasGroupHeader) {
      buttonsColumnDef = {
        headerName: "",
        children: [buttonsColumnDef],
      };
    }

    this.gridApi = null;
    this.gridColumnApi = null;
    this.state = {
      etatFilter: props.etatFilter,
      select_data: props.searchSelect?.defaultValue ?? "",
      alert: null,
      snackbar: null,
      openModal: false,
      loading: false,
      deleteDialogOpen: false,
      deleteDialogModel: null,
      restoreDialogOpen: false,
      restoreDialogModel: null,
      data: null,
      defaultColDef: {
        flex: 1,
        sortable: true,
        filter: props.filterColumns,
        resizable: props.resizable,
        wrapText: true,
        autoHeight: true,
      },
      columnDefs: [...props.colDef, buttonsColumnDef],
      rowClassRules: props.rowClassRules,
      quickFilterText: this.SEARCH_BAR_SERVICE.retrieveSearchValue(),
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  shouldComponentUpdate() {
    if (this.gridApi && this.props.forceRefreshCells) {
      this.gridApi.refreshCells();
    }

    return true;
  }

  loadAsyncData() {
    this.setState({
      loading: true,
      openModal: false,
    });

    this.props.formSetErrors(null);
    const selectName = this.props.searchSelect?.name ?? null;

    let params = {
      etat: this.state.etatFilter,
      ...this.props.getParams,
    };

    if (selectName !== null) {
      params[selectName] = this.state.select_data;
    }

    axiosApiBackend.get(this.BACKEND_URL, { params }).then((result) => {
      this.toggleOverlay(false);
      if (this.props.onFetchData) {
        this.props.onFetchData(result.data);
      }

      if (this.props.forceLoadAsyncRender) {
        this.setState({ data: [] });
      }

      this.setState({ data: result.data, loading: false }, () => {
        this.autoSizeAll();
      });
    });
  }

  toggleOverlay = (show) => {
    if (this.gridApi !== null) {
      show ? this.gridApi.showLoadingOverlay() : this.gridApi.hideOverlay();
    }
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  autoSizeAll = () => {
    if (this.gridColumnApi !== null) {
      this.gridColumnApi.autoSizeColumn("actions");
      if (this.props.autoSize) {
        this.gridColumnApi.autoSizeAllColumns();
      }
    }
  };
  onChangeFilter = (event) => {
    const name = event.target.name;
    const val = event.target.value;

    QueryUtils.replaceSearchParams({ [name]: val });
    this.setState({ [name + "Filter"]: val }, this.loadAsyncData);
  };

  onChangeSelect = (name, value) => {
    this.setState({ select_data: value }, this.loadAsyncData);
  };

  onBlur = (event) => {
    this.SEARCH_BAR_SERVICE.storeSearch(event.target.value, this.gridApi);
  };

  resetSearchValue = () => {
    this.SEARCH_BAR_SERVICE.storeSearch(null, this.gridApi, true);
    this.setState({ quickFilterText: "" });
  };

  onClickDeleteButton = (params) => {
    this.setState({ deleteDialogOpen: true, deleteDialogModel: params.data });
  };

  onClickRestoreButton = (params) => {
    this.setState({ restoreDialogOpen: true, restoreDialogModel: params.data });
  };

  clearAlert = () => {
    this.setState({ alert: null });
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };

  closeDeleteDialog = () => {
    const { onCloseDeleteDialog } = this.props;

    this.setState({
      deleteDialogOpen: false,
      deleteDialogModel: null,
    });

    if (onCloseDeleteDialog) {
      onCloseDeleteDialog();
    }
  };

  closeRestoreDialog = () => {
    const { onCloseRestoreDialog } = this.props;

    this.setState({
      restoreDialogOpen: false,
      restoreDialogModel: null,
    });

    if (onCloseRestoreDialog) {
      onCloseRestoreDialog();
    }
  };

  onClickEditButton = (params) => {
    this.props.formSetData(params.data);
    this.setState({ openModal: true });
  };

  onClickAddButton = () => {
    this.props.formInitData();
    this.setState({ openModal: true });
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onChangeData = () => {
    const { onChangeData } = this.props;
    if (onChangeData) {
      onChangeData();
    }
  };

  handleAlert = () => {
    this.autoSizeAll();
    this.setState({
      loading: true,
    });
    this.toggleOverlay(true);
    const data = this.props.formGetData();
    if (data.id && !data.deleted_at) {
      // modify
      axiosApiBackend
        .put(this.BACKEND_URL + "/" + data.id, data)
        .then((res) => {
          this.props.formSetErrors(res);
          this.loadAsyncData();
          this.onChangeData();
        })
        .catch((err) => {
          this.handleError(err);
        });
    } else {
      // create
      axiosApiBackend
        .post(this.BACKEND_URL, data)
        .then((res) => {
          this.props.formSetErrors(res);
          this.loadAsyncData();
          this.onChangeData();
          this.setState({
            snackbar: true,
          });
        })
        .catch((err) => {
          this.handleError(err);
        });
    }
  };

  closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbar: false });
  };
  handleError = (error) => {
    let err = error.response?.data?.errors ?? null;
    let status = error.response?.status ?? null;
    this.setState({
      loading: false,
    });
    this.props.formSetErrors(err, status);
    this.toggleOverlay(false);
  };

  handleDelete = (model) => {
    this.setState({
      loading: true,
    });
    this.toggleOverlay(true);
    this.clearAlert();

    // delete
    axiosApiBackend
      .delete(this.BACKEND_URL + "/" + model.id, {
        params: this.props.deleteParams,
      })
      .then(() => {
        this.loadAsyncData();
        this.onChangeData();
      })
      .catch((err) => {
        const { failDeleteDialog } = this.props;
        const error = err?.response?.data;
        this.setState({
          loading: false,
          alert: (
            <AlertDialog
              title={this.stringOrFunc(failDeleteDialog?.title, model)}
              content={this.stringOrFunc(
                failDeleteDialog?.content,
                model,
                error,
              )}
              onCancel={this.clearAlert}
              cancelLabel="Ok"
              cancelColor="primary"
            />
          ),
        });
        this.toggleOverlay(false);
      })
      .finally(() => {
        this.closeDeleteDialog();
      });
  };

  handleRestore = (model) => {
    this.setState({
      loading: true,
    });
    this.toggleOverlay(true);
    this.clearAlert();

    // restore
    axiosApiBackend
      .post(this.BACKEND_URL + "/restore/" + model.id)
      .then(() => {
        this.loadAsyncData();
        this.onChangeData();
      })
      .catch((err) => {
        const { failRestoreDialog } = this.props;
        const error = err?.response?.data;
        this.setState({
          loading: false,
          alert: (
            <AlertDialog
              title={this.stringOrFunc(failRestoreDialog?.title, model)}
              content={this.stringOrFunc(
                failRestoreDialog?.content,
                model,
                error,
              )}
              onCancel={this.clearAlert}
              cancelLabel="Ok"
              cancelColor="primary"
            />
          ),
        });
        this.toggleOverlay(false);
      })
      .finally(() => {
        this.closeRestoreDialog();
      });
  };

  stringOrFunc = (v, ...args) => {
    if (typeof v === "function") {
      return v(...args);
    }

    return v;
  };

  render() {
    const {
      deleteDialogOpen,
      deleteDialogModel,
      restoreDialogOpen,
      restoreDialogModel,
    } = this.state;
    const {
      icon,
      iconColor,
      title,
      searchBar,
      filter,
      labelFilter,
      searchSelect,
      dialogProps,
      hideAddButton,
      deleteDialog,
      restoreDialog,
    } = this.props;

    return (
      <Card>
        <CardHeader
          avatar={<CardAvatar sx={{ bgcolor: iconColor }}>{icon}</CardAvatar>}
          title={
            <Typography variant="h6" component="h5">
              {title}
            </Typography>
          }
        />

        <CardContent>
          <GridContainer alignItems="flex-end">
            {searchBar && (
              <GridItem>
                <SearchBar
                  label="Rechercher"
                  name="quickFilterText"
                  value={this.state.quickFilterText}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  resetSearchValue={this.resetSearchValue}
                />
              </GridItem>
            )}

            {searchSelect && (
              <GridItem sx={{ width: 300 }}>
                <SelectSearch
                  name={searchSelect?.name ?? "search_select"}
                  label={searchSelect.label}
                  apiUrl={searchSelect.apiUrl}
                  onChange={this.onChangeSelect}
                  value={this.state.select_data ?? ""}
                  buildOptionValue={searchSelect.buildOptionValue}
                  buildOptionLabel={searchSelect.buildOptionLabel}
                />
              </GridItem>
            )}

            {filter && (
              <GridItem>
                <FormControl>
                  <RadioGroup
                    row
                    aria-label="Active Filter"
                    name="etat"
                    value={this.state.etatFilter}
                    onChange={this.onChangeFilter}
                  >
                    {labelFilter.map((filtre, key) => (
                      <FormControlLabel
                        key={key}
                        value={filtre.value}
                        control={<Radio color="primary" />}
                        label={filtre.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </GridItem>
            )}
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} className="ag-theme-material">
              <AgGridReact
                getRowStyle={this.getRowStyle}
                overlayNoRowsTemplate="Aucune donnée à afficher."
                enableCellTextSelection={true}
                columnDefs={this.state.columnDefs}
                defaultColDef={this.state.defaultColDef}
                rowData={this.state.data}
                onGridReady={this.onGridReady}
                domLayout="autoHeight"
                rowClassRules={this.state.rowClassRules}
                rowSelection="multiple"
                onPaginationChanged={this.autoSizeAll}
                pagination={this.props.pagination}
                paginationPageSize={this.props.paginationPageSize}
                localeText={AG_GRID_LOCALE}
                quickFilterText={this.state.quickFilterText}
                rowHeight={38} // un truc avec qui bug --ag-internal-padded-row-height
              />
            </GridItem>
            {!hideAddButton && (
              <GridItem xs={12}>
                <Button
                  size="sm"
                  color="success"
                  onClick={this.onClickAddButton}
                >
                  Ajouter
                </Button>
              </GridItem>
            )}
          </GridContainer>
          <AlertDialog
            open={this.state.openModal}
            title={this.props.formTitle}
            loading={this.state.loading}
            onConfirm={this.handleAlert}
            confirmLabel={this.props.formConfirmLabel}
            confirmColor="success"
            onCancel={this.closeModal}
            cancelLabel="Annuler"
            cancelColor="primary"
            {...dialogProps}
          >
            {this.props.formContent}
          </AlertDialog>
          {this.state.alert}
          {deleteDialogModel != null && (
            <AlertDialog
              open={deleteDialogOpen}
              title={this.stringOrFunc(deleteDialog?.title, deleteDialogModel)}
              content={this.stringOrFunc(
                deleteDialog?.content,
                deleteDialogModel,
              )}
              onConfirm={() => this.handleDelete(deleteDialogModel)}
              confirmLabel={this.stringOrFunc(
                deleteDialog?.confirmLabel,
                deleteDialogModel,
              )}
              confirmColor="error"
              onCancel={this.closeDeleteDialog}
              cancelLabel="Annuler"
              cancelColor="primary"
            >
              {this.stringOrFunc(deleteDialog?.children, deleteDialogModel)}
            </AlertDialog>
          )}
          {restoreDialogModel != null && (
            <AlertDialog
              open={restoreDialogOpen}
              title={this.stringOrFunc(
                restoreDialog?.title,
                restoreDialogModel,
              )}
              content={this.stringOrFunc(
                restoreDialog?.content,
                restoreDialogModel,
              )}
              onConfirm={() => this.handleRestore(restoreDialogModel)}
              confirmLabel={this.stringOrFunc(
                restoreDialog?.confirmLabel,
                restoreDialogModel,
              )}
              confirmColor="error"
              onCancel={this.closeRestoreDialog}
              cancelLabel="Annuler"
              cancelColor="primary"
            >
              {this.stringOrFunc(restoreDialog?.children, restoreDialogModel)}
            </AlertDialog>
          )}
          <Snackbar
            open={this.state.snackbar}
            autoHideDuration={6000}
            onClose={this.closeSnackbar}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert onClose={this.closeSnackbar} severity="success">
              Enregistré avec succès
            </Alert>
          </Snackbar>
        </CardContent>
      </Card>
    );
  }
}

TablePopUpContainer.defaultProps = {
  autoSize: true,
  pagination: false,
  paginationPageSize: 10,
  filterColumns: false,
  searchStorageKey: "",
  resizable: false,
  iconColor: "config.main",
  deleteDialog: {
    title: "Voulez vous supprimer ce champ ?",
    content: null,
    children: null,
    confirmLabel: "Supprimer",
  },
  failDeleteDialog: {
    title: "Vous ne pouvez pas supprimer ce champ.",
    content: null,
  },
  restoreDialog: {
    title: "Voulez vous restaurer ce champ ?",
    content: null,
    children: null,
    confirmLabel: "Restaurer",
  },
  failRestoreDialog: {
    title: "Vous ne pouvez pas restaurer ce champ.",
    content: null,
  },
};

TablePopUpContainer.propTypes = {
  icon: PropTypes.any,
  iconColor: PropTypes.string,
  title: PropTypes.string,
  backendUrl: PropTypes.string,
  frontUrl: PropTypes.string,
  label: PropTypes.string,
  hasGroupHeader: PropTypes.bool,
  colDef: PropTypes.any,
  formInitData: PropTypes.func,
  formSetData: PropTypes.func,
  formGetData: PropTypes.func,
  formTitle: PropTypes.any,
  formConfirmLabel: PropTypes.any,
  formContent: PropTypes.any,
  formSetErrors: PropTypes.func,
  forceLoadAsyncRender: PropTypes.bool,
  location: PropTypes.any,
  filter: PropTypes.bool,
  etatFilter: PropTypes.any,
  searchBar: PropTypes.bool,
  searchSelect: PropTypes.any,
  searchStorageKey: PropTypes.string,
  labelFilter: PropTypes.array,
  dialogProps: PropTypes.object,
  autoSize: PropTypes.bool,
  pagination: PropTypes.bool,
  paginationPageSize: PropTypes.number,
  filterColumns: PropTypes.bool,
  hideButton: PropTypes.any,
  hideAddButton: PropTypes.bool,
  hideEditButton: PropTypes.bool,
  hideDeleteButton: PropTypes.bool,
  resizable: PropTypes.bool,
  getParams: PropTypes.object,
  deleteParams: PropTypes.object,
  rowClassRules: PropTypes.any,
  onFetchData: PropTypes.func,
  onChangeData: PropTypes.func,
  forceRefreshCells: PropTypes.bool,
  customButtons: PropTypes.func,
  onCloseDeleteDialog: PropTypes.func,
  onCloseRestoreDialog: PropTypes.func,
  deleteDialog: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    content: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    confirmLabel: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  }),
  failDeleteDialog: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    content: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  }),
  restoreDialog: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    content: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    confirmLabel: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  }),
  failRestoreDialog: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    content: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  }),
};

export default TablePopUpContainer;
