import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";

const style = {
  grid: {
    margin: "0 -15px",
    width: "calc(100% + 30px)",
    // '&:before,&:after':{
    //   display: 'table',
    //   content: '" "',
    // },
    // '&:after':{
    //   clear: 'both',
    // }
  },
};

function GridContainer({ ...props }) {
  const { classes, children, className, ...rest } = props;
  return (
    <Grid container {...rest} className={classes.grid + " " + className}>
      {children}
    </Grid>
  );
}

GridContainer.propTypes = {
  classes: PropTypes.any,
  className: PropTypes.string,
  children: PropTypes.any,
};

export default withStyles(style)(GridContainer);
