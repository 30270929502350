import React, { Component } from "react";
import PropTypes from "prop-types";

// @mui/icons-material
import Palette from "@mui/icons-material/Palette";

// core components
import TextField from "@mui/material/TextField";
import { HexColorPicker } from "react-colorful";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";

class ColorPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  handlePopOverClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopOverClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { value, onChangeColor, anchorOrigin, transformOrigin, ...rest } =
      this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const popOverId = open ? "popover-color" : null;

    return (
      <TextField
        value={value ?? ""}
        {...rest}
        InputProps={{
          endAdornment: (
            <>
              <IconButton
                aria-describedby={popOverId}
                variant="contained"
                onClick={this.handlePopOverClick}
              >
                <Palette />
              </IconButton>
              <Popover
                sx={{
                  "& .MuiPopover-paper": {
                    borderRadius: "10px",
                    overflow: "hidden",
                  },
                }}
                open={open}
                anchorEl={this.state.anchorEl}
                onClose={this.handlePopOverClose}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
              >
                <HexColorPicker color={value ?? ""} onChange={onChangeColor} />
              </Popover>
            </>
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }
}

ColorPicker.defaultProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
};

ColorPicker.propTypes = {
  value: PropTypes.string,
  onChangeColor: PropTypes.func.isRequired,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
};

export default ColorPicker;
