import React from "react";
import PropTypes from "prop-types";

import Alert from "@mui/material/Alert";
import MuiSnackbar from "@mui/material/Snackbar";

function Snackbar({ open, setOpen, message, ...rest }) {
  function closeSnackbar(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  }

  return (
    <MuiSnackbar
      open={open}
      autoHideDuration={6000}
      onClose={closeSnackbar}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      {...rest}
    >
      <Alert onClose={closeSnackbar} severity="success">
        {message}
      </Alert>
    </MuiSnackbar>
  );
}

Snackbar.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  message: PropTypes.any,
};

export default Snackbar;
