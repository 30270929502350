import React, { Component } from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// @mui/icons-material
import Description from "@mui/icons-material/Description";
import List from "@mui/icons-material/List";
import Search from "@mui/icons-material/Search";

// core components
import Button from "components/Button/Button";
import Card from "components/CardV2/Card";
import CardContent from "components/CardV2/CardContent";
import CardAvatar from "components/CardV2/CardAvatar";
import CardHeader from "components/CardV2/CardHeader";
import SearchBar from "components/SearchBar/SearchBar";
import Render from "Utils/RenderUtils";
import LogDetailRenderer from "components/CustomAgRenderer/LogDetailRenderer";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";
import MenuBottom from "components/MenuBottom/MenuBottom";
import AgGridUtils from "Utils/AgGridUtils";

class Logs extends Component {
  constructor(props) {
    super(props);

    this.QUERY_PARAMS = new URLSearchParams(props.location.search);
    this.BACKEND_URL = "/logs";
    this.LOG_TYPE = this.QUERY_PARAMS.get("type");
    this.LOG_ID = this.QUERY_PARAMS.get("id");
    this.AG_UTILS = new AgGridUtils("/logs");

    // this.gridApi = null;
    this.gridColumnApi = null;
    this.filtersRef = React.createRef();

    this.state = {
      data: null,
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        cellDataType: "text",
      },
      columnDefs: this.AG_UTILS.applyOrder([
        {
          headerName: "Détails",
          field: "buttons",
          editable: false,
          sortable: false,
          cellRenderer: LogDetailRenderer,
          cellRendererParams: (params) => ({
            params: {
              label: <Search />,
              color: "info",
              justIcon: true,
              data: params.data,
            },
          }),
        },
        {
          headerName: "Action",
          field: "action",
          valueGetter: (params) => Render.CRUDAction(params.data.action),
        },
        {
          headerName: "ID Entité",
          field: "model_id",
          cellDataType: "number",
        },
        {
          headerName: "Entité",
          field: "model",
          valueFormatter: (params) => Render.modelPath(params.value),
        },
        {
          headerName: "Prénom utilisateur",
          field: "user.first_name",
        },
        {
          headerName: "Nom utilisateur",
          field: "user.last_name",
        },
        {
          headerName: "Email utilisateur",
          field: "user.email",
        },
        {
          headerName: "Créé le",
          field: "created_at",
          cellDataType: "dateString",
          valueFormatter: (params) => Render.dateTime(params.value),
          sort: "desc",
        },
      ]),
      getRowStyle: null,
      quickFilterText: "",
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    let params = {
      type: this.LOG_TYPE,
      main_model_id: this.LOG_ID ?? null,
    };
    axiosApiBackend
      .get(this.BACKEND_URL, { params })
      .then((result) => {
        this.setState({ data: result.data });
      })
      .catch(() => {
        this.setState({ data: "" });
      });
  }

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onGridReady = (params) => {
    // this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  autoSizeAll = () => {
    if (this.gridColumnApi !== null) {
      this.gridColumnApi.autoSizeAllColumns();
    }
  };

  onColumnMoved = () => {
    if (this.gridColumnApi !== null) {
      this.AG_UTILS.saveOrder(this.gridColumnApi);
    }
  };

  resetSearchValue = () => {
    this.setState({ quickFilterText: "" });
  };

  backToListUrl = () => {
    const url = this.props.location.pathname;
    return url.substring(0, url.lastIndexOf("/"));
  };

  render() {
    return (
      <Grid container sx={{ px: 1 }}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar sx={{ bgcolor: this.props.iconColor + ".main" }}>
                  <Description />
                </CardAvatar>
              }
              title={<Typography variant="h5">{this.props.title}</Typography>}
            />
            <CardContent>
              <Grid container alignItems="flex-end" ref={this.filtersRef}>
                <Grid item>
                  <SearchBar
                    label="Rechercher"
                    name="quickFilterText"
                    value={this.state.quickFilterText}
                    onChange={this.onChange}
                    resetSearchValue={this.resetSearchValue}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  minHeight: "300px",
                }}
              >
                <Grid item xs={12} className="ag-theme-material">
                  <AgGridReact
                    overlayNoRowsTemplate="Aucune donnée à afficher."
                    enableCellTextSelection={true}
                    animateRows={true}
                    onGridReady={this.onGridReady}
                    rowSelection="multiple"
                    enableFilter={false}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.data}
                    quickFilterText={this.state.quickFilterText}
                    pagination={true}
                    getRowStyle={this.state.getRowStyle}
                    domLayout="autoHeight"
                    paginationPageSize={AgGridUtils.getPageSize(
                      window.innerHeight,
                      this.filtersRef,
                    )}
                    onPaginationChanged={this.autoSizeAll}
                    localeText={AG_GRID_LOCALE}
                    onColumnMoved={this.onColumnMoved}
                    suppressDragLeaveHidesColumns={true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <MenuBottom>
          <Link to={this.backToListUrl()}>
            <Button square round>
              <List />
            </Button>
          </Link>
        </MenuBottom>
      </Grid>
    );
  }
}

Logs.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  title: PropTypes.any,
  iconColor: PropTypes.any,
  parentPath: PropTypes.any,
};

export default Logs;
