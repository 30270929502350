import React from "react";
import PropTypes from "prop-types";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
registerPlugin(
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
);

import FormControl from "@mui/material/FormControl";

class ImagePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: null,
    };
  }

  render() {
    const { label, value, onChange, ...rest } = this.props;

    return (
      <FormControl style={{ width: "100%" }}>
        <FilePond
          labelIdle={label}
          files={value}
          maxTotalFileSize={10000000}
          onupdatefiles={(fileItems) => {
            onChange(fileItems);
          }}
          {...rest}
        />
      </FormControl>
    );
  }
}

ImagePicker.propTypes = {
  label: PropTypes.any,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

export default ImagePicker;
