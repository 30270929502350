import React from "react";
import PropTypes from "prop-types";

// @mui/material components
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  input: {
    "& .MuiOutlinedInput-input svg": {
      marginBottom: "-5px",
    },
  },
  themeBgSecondary: {
    backgroundColor: theme.palette.secondary.main,
  },
});

class CustomOutlinedSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { classes } = this.props;
    return (
      <FormControl
        variant="outlined"
        fullWidth
        margin={this.props.margin}
        disabled={this.props.disabled}
        error={this.props.error}
      >
        <InputLabel shrink={this.props.shrink} id={this.props.labelId}>
          {this.props.label}
        </InputLabel>
        <Select
          labelId={this.props.labelId}
          value={this.props.value ?? ""}
          onChange={this.props.onChange}
          name={this.props.name}
          multiple={this.props.multiple}
          displayEmpty={this.props.displayEmpty}
          input={
            <OutlinedInput
              inputRef={this.props.inputRef}
              className={classes.input}
              notched={this.props.shrink}
              label={this.props.label}
            />
          }
        >
          {this.props.children}
        </Select>
      </FormControl>
    );
  }
}

CustomOutlinedSelect.defaultProps = {
  margin: "normal",
};

CustomOutlinedSelect.propTypes = {
  inputRef: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.any,
  labelId: PropTypes.string,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  margin: PropTypes.any,
  multiple: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  shrink: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  children: PropTypes.any,
  classes: PropTypes.any,
};

/**
 * @deprecated use SelectInput
 */
export default withStyles(styles)(CustomOutlinedSelect);
