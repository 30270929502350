import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
import Build from "@mui/icons-material/Build";

// core components
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import SelectSearch from "components/CustomSelect/SelectSearch";
import FormSubmitButtons from "components/CustomButtons/FormSubmitButtons";
import TextInput from "components/CustomInput/TextInput";
import Render from "Utils/RenderUtils";
import ActiviteSelection from "./SubForms/ActiviteSelection";
import CheckBoxInput from "components/CustomInput/CheckBoxInput";

class AmoForm extends React.Component {
  constructor(props) {
    super(props);
    this.BACKEND_URL = "/amo";
    this.FRONT_URL = "/amo";
    this.initialActivites = {};

    this.state = {
      // NESTED DATA
      logement: null,
      activites: [],
      // DATA FORM
      logement_id: "",
      type_formule_id: "",
      description: "",
      appliquer_tva: false,
      sous_traitance: false,
      // END DATA FORM
      logements: [],
      loading: false,
      errors: null,
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.loadAsyncData();
    }
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = result.data ?? {};
        state.activites = state.activites.map((activite) => activite.id);
        this.initialActivites[state.logement_id] = state.activites;
        this.setState(state);
      });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  handleLogementResponse = (logements) => {
    this.setState({ logements });
  };

  handleChangeLogement = (name, logementId) => {
    const logement = this.state.logements.find((lg) => lg.id === logementId);

    let newActivites = [];
    if (this.initialActivites[logementId]) {
      // Roll back data for existing activites on the first selected logement
      newActivites = this.initialActivites[logementId];
    }

    this.setState({
      [name]: logementId,
      logement: logement,
      activites: newActivites,
    });
  };

  updateActiviteIds = (newIds) => {
    this.setState({ activites: newIds });
  };

  handleSubmit = () => {
    const { id } = this.state;

    const dataIn = {
      logement_id: this.state.logement_id,
      type_formule_id: this.state.type_formule_id,
      description: this.state.description,
      activites: this.state.activites,
      appliquer_tva: this.state.appliquer_tva,
      sous_traitance: this.state.sous_traitance,
    };

    let request = null;
    if (id) {
      // modify
      request = axiosApiBackend.put(this.BACKEND_URL + "/" + id, dataIn);
    } else {
      // create
      request = axiosApiBackend.post(this.BACKEND_URL, dataIn);
    }
    request
      .then((res) => {
        this.handleSuccess(res.data.id);
      })
      .catch((err) => {
        this.setState({
          errors: err.response?.data?.errors ?? {},
          loading: false,
        });
      });

    this.setState({
      errors: null,
      loading: true,
    });
  };

  handleSuccess = (id) => {
    this.props.history.push(this.FRONT_URL + "/detail/" + id);
  };

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const { activites, logement, errors } = this.state;
    const structureId = logement?.beneficiaire?.structure_locale_id ?? null;

    return (
      <Grid container spacing={2}>
        {errors && (
          <Grid item xs={12}>
            <Typography variant="h6" component="h4" color="error">
              {Object.entries(errors).length === 0 ? (
                <>Une erreur est survenue.</>
              ) : (
                <>Merci de corriger les champs en rouge du formulaire.</>
              )}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Build />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  {this.state.id ? (
                    <>Modifier l&apos;AMO</>
                  ) : (
                    <>Ajouter un AMO</>
                  )}
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    name="logement_id"
                    label="Logement"
                    apiUrl="/logements"
                    onChange={this.handleChangeLogement}
                    onResponse={this.handleLogementResponse}
                    value={this.state.logement_id}
                    buildOptionLabel={(data) =>
                      Render.fullAddress(data.adresse) + " (" + data.id + ")"
                    }
                    shrink
                    error={Boolean(errors?.logement_id)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectSearch
                    name="type_formule_id"
                    label="Type de formule"
                    apiUrl="/config-amo-type-formule"
                    onChange={this.handleChangeByName}
                    value={this.state.type_formule_id}
                    buildOptionLabel={(data) => data.value}
                    shrink
                    error={Boolean(errors?.type_formule_id)}
                  />
                </Grid>
                <Grid item xs={12} sx={{ margin: "auto" }}>
                  <CheckBoxInput
                    label="Sous traitance"
                    name="sous_traitance"
                    value={this.state.sous_traitance}
                    onChange={this.handleChangeByName}
                  />
                </Grid>
                <Grid item xs={12} sx={{ margin: "auto" }}>
                  <CheckBoxInput
                    label="Appliquer la TVA"
                    name="appliquer_tva"
                    value={this.state.appliquer_tva}
                    onChange={this.handleChangeByName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    label="Description du projet"
                    name="description"
                    multiline
                    minRows={2}
                    onChange={this.handleChange}
                    value={this.state.description}
                    error={Boolean(errors?.description)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Assignment />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Missions et Activités
                </Typography>
              }
            />
            <CardContent>
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12}>
                  <ActiviteSelection
                    structureId={structureId}
                    activiteIds={activites}
                    onChangeActivites={this.updateActiviteIds}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <FormSubmitButtons
            onClick={this.handleSubmit}
            onCancel={this.handleCancel}
            loading={this.state.loading}
            isEdit={Boolean(this.state.id)}
          />
        </Grid>
      </Grid>
    );
  }
}

AmoForm.propTypes = {
  location: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
};

export default AmoForm;
