import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// @mui/icons-material
import List from "@mui/icons-material/List";
import Edit from "@mui/icons-material/Edit";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Description from "@mui/icons-material/Description";
import Delete from "@mui/icons-material/Delete";
import Assignment from "@mui/icons-material/Assignment";
import CopyAll from "@mui/icons-material/CopyAll";
import LocalActivity from "@mui/icons-material/LocalActivity";

// core components
import SearchBarService from "services/SearchBarService";
import Button from "components/Button/Button.jsx";
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import DisableLink from "components/DisableLink/DisableLink.jsx";
import Render from "Utils/RenderUtils";
import MenuBottom from "components/MenuBottom/MenuBottom";
import AlertDialog from "components/AlertDialog/AlertDialog";
import ActiviteView from "views/Forms/SubForms/ActiviteView";
import FieldView from "components/FieldView/FieldView";
import { withUserContext } from "context/UserContext";

class MissionsView extends React.Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_EDIT = this.USER.can("edit.mission");
    this.CAN_DELETE = this.USER.can("delete.mission");
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");

    this.BACKEND_URL = "/missions";
    this.FRONT_URL = "/missions";
    const storeSearchBar = new SearchBarService(this.FRONT_URL);
    this.prevURL = storeSearchBar.getOffsetUrl(props.match.params.id, -1);
    this.nextURL = storeSearchBar.getOffsetUrl(props.match.params.id);

    this.state = {
      // NESTED DATA
      categorie: null,
      sous_categorie: null,
      mode_facturation: null,
      activites: [],
      structure_locale: null,
      // DATA FORM
      titre: "",
      description: "",
      categorie_id: "",
      sous_categorie_id: "",
      importance: "",
      cout_facturation: "",
      mode_facturation_id: "",
      modalite_prise_charge_publique: "",
      modalites_prefinancements_eventuels: "",
      structure_locale_id: "",
      user_can_edit: false,
      // END DATA FORM
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = result.data ?? {};
        this.setState(state);
      });
  }

  clearAlert = () => {
    this.setState({ alert: null });
  };

  handleDeleteAlert = () => {
    axiosApiBackend
      .delete(this.BACKEND_URL + "/" + this.state.id)
      .then(() => {
        this.props.history.push(this.FRONT_URL);
      })
      .catch((err) => {
        if (err.response.status === 428) {
          const entities = err.response.data;
          this.onError(entities);
        }
      });
  };

  onError = (entities) => {
    this.setState({
      alert: (
        <AlertDialog
          title="Impossible de supprimer cette Mission : "
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Ok"
          cancelColor="info"
          fullWidth
        >
          <ul>
            {entities.amo > 0 && <li>{entities.amo} AMO(s) associé(s)</li>}
          </ul>
        </AlertDialog>
      ),
    });
  };

  onClickDeleteButton = () => {
    this.setState({
      alert: (
        <AlertDialog
          title="Voulez-vous supprimer la Mission ?"
          onConfirm={this.handleDeleteAlert}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="info"
          fullWidth
        />
      ),
    });
  };

  render() {
    const {
      categorie,
      sous_categorie,
      mode_facturation,
      activites,
      structure_locale,
      user_can_edit,
    } = this.state;

    const canEdit = user_can_edit && this.CAN_EDIT;
    const canDelete = user_can_edit && this.CAN_DELETE;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Assignment />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Mission{Render.ifNotEmpty(this.state.titre, " : ")}
                </Typography>
              }
            />
            <CardContent>
              {structure_locale == null ? (
                <FieldView
                  label="Structure nationale"
                  value="Mission Globale"
                />
              ) : (
                <FieldView
                  label="Structure locale"
                  value={
                    <Link
                      to={"/structures-locales/detail/" + structure_locale.id}
                    >
                      {structure_locale.nom}
                    </Link>
                  }
                />
              )}
              <FieldView label="Description" value={this.state.description} />
              <FieldView label="Catégorie" value={categorie?.value} />
              <FieldView label="Sous catégorie" value={sous_categorie?.value} />
              <FieldView label="Importance" value={this.state.importance} />
              <FieldView
                label="Coût de facturation"
                value={Render.euro(this.state.cout_facturation)}
              />
              <FieldView
                label="Mode de facturation"
                value={mode_facturation?.value}
              />
              <FieldView
                label="Modalité de prise en charge publique"
                value={this.state.modalite_prise_charge_publique}
              />
              <FieldView
                label="Modalités de préfinancements éventuels"
                value={this.state.modalites_prefinancements_eventuels}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <LocalActivity />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Activités
                </Typography>
              }
            />
            <CardContent>
              <ActiviteView activites={activites} />
            </CardContent>
          </Card>
        </Grid>
        <MenuBottom>
          <Link to={this.FRONT_URL}>
            <Button square round>
              <List />
            </Button>
          </Link>
          <DisableLink to={this.prevURL}>
            <Button square round disabled={this.prevURL === null}>
              <ArrowBack />
            </Button>
          </DisableLink>
          <DisableLink to={this.nextURL}>
            <Button square round disabled={this.nextURL === null}>
              <ArrowForward />
            </Button>
          </DisableLink>
          {canEdit && (
            <Link to={this.FRONT_URL + "/modifier/" + this.state.id}>
              <Button color="onyx" square round>
                <Edit />
              </Button>
            </Link>
          )}
          {this.CAN_EDIT && (
            <Link to={this.FRONT_URL + "/ajouter?id_to_copy=" + this.state.id}>
              <Button square round>
                <CopyAll />
              </Button>
            </Link>
          )}
          {canDelete && this.state.id && (
            <Button
              color="error"
              square
              round
              onClick={this.onClickDeleteButton}
            >
              <Delete />
            </Button>
          )}
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link
              to={this.FRONT_URL + "/logs?type=Mission&id=" + this.state.id}
            >
              <Button square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
        {this.state.alert}
      </Grid>
    );
  }
}

MissionsView.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.any,
};

export default withUserContext(MissionsView);
