import React, { Component } from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";
import { AG_GRID_LOCALE } from "translations/ag-grid/fr_FR";
import AgGridUtils from "Utils/AgGridUtils";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

// @mui/icons-material
import AddBox from "@mui/icons-material/AddBox";
import Description from "@mui/icons-material/Description";
import GetApp from "@mui/icons-material/GetApp";
import Assignment from "@mui/icons-material/Assignment";
import CopyAll from "@mui/icons-material/CopyAll";

// core components
import Button from "components/Button/Button.jsx";
import Card from "components/CardV2/Card.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import SearchBar from "components/SearchBar/SearchBar.jsx";
import SearchBarService from "services/SearchBarService";
import MenuBottom from "components/MenuBottom/MenuBottom";
import Render from "Utils/RenderUtils";
import { withUserContext } from "context/UserContext";

class Missions extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    this.IS_AGENT_LOCAL = this.USER.is("agent_local");
    this.CAN_EDIT = this.USER.can("edit.mission");

    this.BACKEND_URL = "/missions";
    this.FRONT_URL = "/missions";
    this.SEARCH_BAR_SERVICE = new SearchBarService(this.FRONT_URL);
    this.AG_UTILS = new AgGridUtils(this.FRONT_URL);
    this.gridApi = null;
    this.gridColumnApi = null;
    this.filtersRef = React.createRef();

    let buttonsColumnDef = [];
    if (this.CAN_EDIT) {
      buttonsColumnDef.push({
        headerName: "",
        field: "actions",
        type: "rightAligned",
        autoHeight: true,
        cellRenderer: (params) => (
          <Link
            to={this.FRONT_URL + "/ajouter?id_to_copy=" + params.node.data.id}
          >
            <Button square>
              <CopyAll />
            </Button>
          </Link>
        ),
      });
    }

    this.state = {
      data: null,
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        cellDataType: "text",
      },
      columnDefs: this.AG_UTILS.applyOrder([
        {
          headerName: "#",
          field: "id",
          cellDataType: "number",
        },
        ...buttonsColumnDef,
        {
          headerName: "Structure",
          field: "structure_locale",
          valueGetter: (params) =>
            params.data.structure_locale?.nom ?? "Structure nationale",
        },
        {
          sort: "asc",
          headerName: "Titre",
          field: "titre",
        },
        {
          headerName: "Description",
          field: "description",
        },
        {
          headerName: "Catégorie",
          field: "categorie.value",
        },
        {
          headerName: "Sous catégorie",
          field: "sous_categorie.value",
        },
        {
          headerName: "Importance",
          field: "importance",
        },
        {
          headerName: "Coût de facturation",
          field: "cout_facturation",
          cellDataType: "number",
          type: "numericColumn",
          valueFormatter: (params) => Render.euro(params.value),
        },
        {
          headerName: "Mode de facturation",
          field: "mode_facturation.value",
        },
        {
          headerName: "Modalité de prise en charge publique",
          field: "modalite_prise_charge_publique",
        },
        {
          headerName: "Modalités de préfinancements éventuels",
          field: "modalites_prefinancements_eventuels",
        },
      ]),
      quickFilterText: this.SEARCH_BAR_SERVICE.retrieveSearchValue(),
    };
  }

  componentDidMount() {
    this.loadAsyncData();
  }

  loadAsyncData() {
    axiosApiBackend.get(this.BACKEND_URL).then((result) => {
      this.setState({ data: result.data }, () => {
        this.SEARCH_BAR_SERVICE.storeSearchHistory(this.gridApi);
      });
    });
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  autoSizeAll = () => {
    if (this.gridColumnApi !== null) {
      this.gridColumnApi.autoSizeAllColumns();
    }
  };

  onColumnMoved = () => {
    if (this.gridColumnApi !== null) {
      this.AG_UTILS.saveOrder(this.gridColumnApi);
    }
  };

  goToView = (event) => {
    this.props.history.push(this.FRONT_URL + "/detail/" + event.node.data.id);
  };

  onBlur = (event) => {
    this.SEARCH_BAR_SERVICE.storeSearch(event.target.value, this.gridApi);
  };

  resetSearchValue = () => {
    this.SEARCH_BAR_SERVICE.storeSearch(null, this.gridApi, true);
    this.setState({ quickFilterText: "" });
  };

  render() {
    return (
      <Grid container sx={{ px: 1 }}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Assignment />
                </CardAvatar>
              }
              title={<Typography variant="h5">Gestion des Missions</Typography>}
            />
            <CardContent>
              <Grid container alignItems="flex-end" ref={this.filtersRef}>
                <Grid item>
                  <SearchBar
                    label="Rechercher"
                    name="quickFilterText"
                    value={this.state.quickFilterText}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    resetSearchValue={this.resetSearchValue}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} className="ag-theme-material">
                <AgGridReact
                  overlayNoRowsTemplate="Aucune donnée à afficher."
                  enableCellTextSelection={true}
                  animateRows={true}
                  onGridReady={this.onGridReady}
                  rowSelection="multiple"
                  columnDefs={this.state.columnDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowData={this.state.data}
                  quickFilterText={this.state.quickFilterText}
                  pagination={true}
                  onRowDoubleClicked={this.goToView}
                  domLayout="autoHeight"
                  paginationPageSize={AgGridUtils.getPageSize(
                    window.innerHeight,
                    this.filtersRef,
                  )}
                  onPaginationChanged={this.autoSizeAll}
                  localeText={AG_GRID_LOCALE}
                  onColumnMoved={this.onColumnMoved}
                  suppressDragLeaveHidesColumns={true}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <MenuBottom>
          {!this.IS_AGENT_LOCAL && (
            <Link to={this.FRONT_URL + "/ajouter"}>
              <Button square round>
                <AddBox />
              </Button>
            </Link>
          )}
          <Button
            square
            round
            onClick={() => AgGridUtils.exportCSV(this.gridApi)}
          >
            <GetApp />
          </Button>
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link to={this.FRONT_URL + "/logs?type=Mission"}>
              <Button square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
      </Grid>
    );
  }
}

Missions.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(Missions);
