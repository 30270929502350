import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/icons-material
import Description from "@mui/icons-material/Description";
import Receipt from "@mui/icons-material/Receipt";

// core components
import Grid from "@mui/material/Grid";
import Button from "components/Button/Button";
import MenuBottom from "components/MenuBottom/MenuBottom";
import ConfigContainer from "components/ConfigContainer/ConfigContainer";
import ConfigMissionCategorie from "components/ConfigComponents/ConfigMissionCategorie";
import { withUserContext } from "context/UserContext";

class MissionConfig extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    this.FRONT_URL = "/configuration/missions";
    this.LABEL = "Mission(s)";

    this.state = {};
  }

  render() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <ConfigMissionCategorie />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ConfigContainer
            icon={<Receipt />}
            title="Mode de facturation"
            backendUrl="/config-mission-mode-facturation"
            label={this.LABEL}
          />
        </Grid>

        {this.CAN_VIEW_LOG_ACTIVITY && (
          <MenuBottom>
            <Link to={this.FRONT_URL + "/logs?type=ConfigMission"}>
              <Button square round>
                <Description />
              </Button>
            </Link>
          </MenuBottom>
        )}
      </Grid>
    );
  }
}

MissionConfig.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(MissionConfig);
