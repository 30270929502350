import React from "react";
import { axiosApiBackend } from "variables/axiosConfigs.jsx";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/material components
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";

// @mui/icons-material
import List from "@mui/icons-material/List";
import Edit from "@mui/icons-material/Edit";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Description from "@mui/icons-material/Description";
import Delete from "@mui/icons-material/Delete";
import HomeWork from "@mui/icons-material/HomeWork";
import Place from "@mui/icons-material/Place";
import Key from "@mui/icons-material/Key";
import PlayForWork from "@mui/icons-material/PlayForWork";

// Services
import SearchBarService from "services/SearchBarService";

// core components
import Card from "components/CardV2/Card.jsx";
import CardHeader from "components/CardV2/CardHeader.jsx";
import CardAvatar from "components/CardV2/CardAvatar.jsx";
import CardContent from "components/CardV2/CardContent.jsx";
import DisableLink from "components/DisableLink/DisableLink.jsx";
import Render from "Utils/RenderUtils";
import MenuBottom from "components/MenuBottom/MenuBottom";
import CheckBoxView from "components/CheckBoxView/CheckBoxView";
import AlertDialog from "components/AlertDialog/AlertDialog";
import AgentTable from "components/Table/AgentTable";
import Ged from "views/Tables/Ged";
import Image from "components/Image/Image";
import Button from "components/Button/Button";
import AdresseView from "./Adresse/AdresseView";
import { withUserContext } from "context/UserContext";
import PaperList from "components/PaperList/PaperList";
import FieldView from "components/FieldView/FieldView";
import CopyAll from "@mui/icons-material/CopyAll";
import TextInput from "components/CustomInput/TextInput";
import CsvImportPopUp from "components/CustomPopup/CsvImportPopUp";

class StructuresLocalesView extends React.Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_EDIT = this.USER.can("edit.structurelocale");
    this.CAN_DELETE = this.USER.can("delete.structurelocale");
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    this.CAN_EDIT_AGENT = this.USER.can("edit.agentlocal");
    this.CAN_DELETE_AGENT = this.USER.can("delete.agentlocal");
    this.GED_SL_PERMISSIONS = {
      CAN_VIEW: this.USER.can("view.ged.portail.structurelocale"),
      CAN_EDIT: this.USER.can("edit.ged.portail.structurelocale"),
      CAN_DELETE: this.USER.can("delete.ged.portail.structurelocale"),
    };
    this.CAN_USE_CSV_IMPORT = this.USER.can("use.csvimport");
    this.CAN_USE_ACTIONS = this.CAN_USE_CSV_IMPORT;

    this.BACKEND_URL = "/structures-locales";
    this.FRONT_URL = "/structures-locales";
    const storeSearchBar = new SearchBarService(this.FRONT_URL);
    this.prevURL = storeSearchBar.getOffsetUrl(props.match.params.id, -1);
    this.nextURL = storeSearchBar.getOffsetUrl(props.match.params.id);

    this.state = {
      // NESTED DATA
      tokens: [],
      adresse: null,
      types_structures: null,
      metiers: null,
      typologie_couleur: null,
      // DATA FORM
      nom: "",
      code_comptable: "",
      over_50_employees: "",
      date_agrement_code_construction_habitation: "",
      date_agrement_code_energie: "",
      site_internet: "",
      email_contact: "",
      telephone: "",
      url_logo: null,
      siret: "",
      numero_police_assurance: "",
      // END DATA FORM
      accessToken: "",
      token_name: null,
      token_name_error: "",
      loading: false,
      id: props.match.params.id,
    };
  }

  componentDidMount() {
    this.loadAsyncData();
    this.loadAsyncTokens();
  }

  loadAsyncData() {
    axiosApiBackend
      .get(this.BACKEND_URL + "/" + this.state.id)
      .then((result) => {
        let state = result.data ?? {};
        this.setState(state);
      });
  }

  loadAsyncTokens = () => {
    axiosApiBackend
      .get(`${this.BACKEND_URL}/${this.state.id}/tokens`)
      .then((result) => {
        this.setState({
          tokens: result.data ?? [],
        });
      });
  };

  handleAddToken = () => {
    this.setState({
      token_name_error: "",
      loading: true,
    });
    const data = {
      name: this.state.token_name,
    };
    axiosApiBackend
      .post(`${this.BACKEND_URL}/${this.state.id}/tokens`, data)
      .then((result) => {
        this.setState({
          token_name: "",
          accessToken: result.data.accessToken,
          loading: false,
        });
        this.loadAsyncTokens();
      })
      .catch((err) => {
        this.setState({
          token_name_error: err?.response?.data?.message ?? "Erreur Serveur",
          loading: false,
        });
      });
  };

  handleDeleteToken = (token) => {
    this.setState({
      loading: true,
    });
    axiosApiBackend
      .delete(`${this.BACKEND_URL}/${this.state.id}/tokens/${token.name}`)
      .then(() => {
        this.setState({
          accessToken: "",
          loading: false,
        });
        this.loadAsyncTokens();
        this.clearAlert();
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  onClickDeleteToken = (token) => {
    this.setState({
      alert: (
        <AlertDialog
          title="Voulez-vous supprimer le token ?"
          onConfirm={() => this.handleDeleteToken(token)}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="info"
          fullWidth
        />
      ),
    });
  };

  clearAlert = () => {
    this.setState({ alert: null });
  };

  handleDeleteAlert = () => {
    axiosApiBackend
      .delete(this.BACKEND_URL + "/" + this.state.id)
      .then(() => {
        this.props.history.push(this.FRONT_URL);
      })
      .catch((err) => {
        if (err.response.status === 428) {
          const entities = err.response.data;
          this.onError(entities);
        }
      });
  };

  onError = (entities) => {
    this.setState({
      alert: (
        <AlertDialog
          title="Impossible de supprimer cette Structure Locale : "
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Ok"
          cancelColor="info"
          fullWidth
        >
          <ul>
            {entities.mission > 0 && (
              <li>{entities.mission} mission(s) associée(s)</li>
            )}
            {entities.beneficiaire > 0 && (
              <li>{entities.beneficiaire} bénéficiaire(s) associé(s)</li>
            )}
          </ul>
        </AlertDialog>
      ),
    });
  };

  onClickDeleteButton = () => {
    this.setState({
      alert: (
        <AlertDialog
          title="Voulez-vous supprimer la Structure Locale ?"
          onConfirm={this.handleDeleteAlert}
          confirmLabel="Supprimer"
          confirmColor="error"
          onCancel={this.clearAlert}
          cancelLabel="Annuler"
          cancelColor="info"
          fullWidth
        />
      ),
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      adresse,
      types_structures,
      metiers,
      date_agrement_code_construction_habitation,
      date_agrement_code_energie,
      typologie_couleur,
    } = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <HomeWork />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Structure Locale{Render.ifNotEmpty(this.state.nom, " : ")}
                </Typography>
              }
            />
            <CardContent>
              <Image src={this.state.url_logo} alt="logo" />
              {!this.USER.isAgentLocal() && (
                <Typography>
                  <strong>Code comptable : </strong>
                  {this.state.code_comptable}
                </Typography>
              )}
              <Typography>
                <strong>Siret : </strong>
                {Render.siret(this.state.siret)}
              </Typography>
              <Typography>
                <strong>Types de structures : </strong>
                {Render.configList(types_structures)}
              </Typography>
              <CheckBoxView
                label="Plus de 50 salariés "
                value={this.state.over_50_employees}
              />
              <Typography>
                <strong>
                  Date agrément code construction et habitation :{" "}
                </strong>
                {Render.date(date_agrement_code_construction_habitation)}
              </Typography>
              <Typography>
                <strong>Date agrément code énergie : </strong>
                {Render.date(date_agrement_code_energie)}
              </Typography>
              <Typography>
                <strong>Site internet : </strong>
                {Render.externalLink(this.state.site_internet)}
              </Typography>
              <Typography>
                <strong>Email contact : </strong>
                {Render.email(this.state.email_contact)}
              </Typography>
              <Typography>
                <strong>Téléphone : </strong>
                {Render.telephoneAsLink(this.state.telephone)}
              </Typography>
              <Typography>
                <strong>Métiers : </strong>
                {Render.configList(metiers)}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography>
                  <strong>Typologie couleur : </strong>
                  {typologie_couleur?.value}
                </Typography>
                <Box
                  sx={{
                    width: 25,
                    height: 25,
                    borderRadius: 1,
                    backgroundColor: typologie_couleur?.color,
                  }}
                />
              </Stack>
              <Typography>
                <strong>Numéro de police d&apos;assurance : </strong>
                {this.state.numero_police_assurance}
              </Typography>
            </CardContent>
          </Card>
          <AgentTable
            backendUrl="/agents-locaux"
            configTitreUrl="/config-al-titre"
            structureId={this.state.id}
            canEdit={this.CAN_EDIT_AGENT}
            canDelete={this.CAN_DELETE_AGENT}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Place />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  Adresse
                </Typography>
              }
            />
            <CardContent>
              <AdresseView adresse={adresse} hideAdresseDetails />
            </CardContent>
          </Card>
          <Ged
            rootFolder="documents"
            section="portail"
            type="structurelocale"
            objectId={this.state.id}
            permissions={this.GED_SL_PERMISSIONS}
            frontUrl={this.FRONT_URL}
          />
          {this.CAN_USE_ACTIONS && (
            <Card>
              <CardHeader
                avatar={
                  <CardAvatar>
                    <PlayForWork />
                  </CardAvatar>
                }
                title={
                  <Typography variant="h6" component="h5">
                    Actions
                  </Typography>
                }
              />
              <CardContent>
                {this.CAN_USE_CSV_IMPORT && (
                  <CsvImportPopUp structureId={this.state.id} />
                )}
              </CardContent>
            </Card>
          )}
          <Card>
            <CardHeader
              avatar={
                <CardAvatar>
                  <Key />
                </CardAvatar>
              }
              title={
                <Typography variant="h6" component="h5">
                  API tokens
                </Typography>
              }
            />
            <CardContent>
              {this.state.accessToken && (
                <Alert sx={{ mb: 1 }} severity="warning">
                  Veillez à copier votre token. Vous ne pourrez plus le voir !{" "}
                  <Button
                    square
                    onClick={() => {
                      navigator.clipboard.writeText(this.state.accessToken);
                    }}
                  >
                    <CopyAll />
                  </Button>
                  <br />
                  <Typography sx={{ wordBreak: "break-all" }}>
                    {this.state.accessToken}
                  </Typography>
                </Alert>
              )}
              <PaperList
                datas={this.state.tokens}
                buildBody={(token) => (
                  <Grid container alignItems="center">
                    <Grid item xs={9}>
                      <FieldView label="Nom" value={token.name} />
                      <FieldView
                        label="Créé le"
                        value={Render.dateTime(token.created_at)}
                      />
                      <FieldView
                        label="Expire le"
                        value={Render.dateTime(token.expires_at)}
                      />
                      {Boolean(token.revoked) && (
                        <FieldView label="Révoqué" value="Oui" />
                      )}
                    </Grid>
                    <Grid item xs={3} textAlign="right">
                      {!token.revoked && (
                        <Button
                          color="error"
                          onClick={() => this.onClickDeleteToken(token)}
                        >
                          Révoquer
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                )}
              />
              <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
                <Grid item xs={12} md={6}>
                  <TextInput
                    label="Nom du token"
                    name="token_name"
                    value={this.state.token_name}
                    onChange={this.handleChange}
                    error={Boolean(this.state.token_name_error)}
                    helperText={this.state.token_name_error}
                    margin="none"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    color="success"
                    size="small"
                    onClick={this.handleAddToken}
                  >
                    Ajouter un token
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <MenuBottom>
          <Link to={this.FRONT_URL}>
            <Button square round>
              <List />
            </Button>
          </Link>
          <DisableLink to={this.prevURL}>
            <Button square round disabled={this.prevURL === null}>
              <ArrowBack />
            </Button>
          </DisableLink>
          <DisableLink to={this.nextURL}>
            <Button square round disabled={this.nextURL === null}>
              <ArrowForward />
            </Button>
          </DisableLink>
          {this.CAN_EDIT && (
            <Link to={this.FRONT_URL + "/modifier/" + this.state.id}>
              <Button color="onyx" square round>
                <Edit />
              </Button>
            </Link>
          )}
          {this.CAN_DELETE && this.state.id && (
            <Button
              color="error"
              square
              round
              onClick={this.onClickDeleteButton}
            >
              <Delete />
            </Button>
          )}
          {this.CAN_VIEW_LOG_ACTIVITY && (
            <Link
              to={
                this.FRONT_URL +
                "/logs?type=StructureLocale&id=" +
                this.state.id
              }
            >
              <Button square round>
                <Description />
              </Button>
            </Link>
          )}
        </MenuBottom>
        {this.state.alert}
      </Grid>
    );
  }
}

StructuresLocalesView.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.any,
};

export default withUserContext(StructuresLocalesView);
