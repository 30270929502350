import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/icons-material
import Description from "@mui/icons-material/Description";
import Inventory from "@mui/icons-material/Inventory";

// core components
import Grid from "@mui/material/Grid";
import Button from "components/Button/Button";
import MenuBottom from "components/MenuBottom/MenuBottom";
import ConfigContainer from "components/ConfigContainer/ConfigContainer";
import Ged from "views/Tables/Ged";
import DocumentTemplatePopup from "components/Popups/DocumentTemplatePopup";
import { withUserContext } from "context/UserContext";

class AmoConfig extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    // Ged permissions
    const permission = this.USER.can("config.ged.portail.amo");
    this.GED_AMO_PERMISSIONS = {
      CAN_VIEW: permission,
      CAN_EDIT: permission,
      CAN_DELETE: permission,
    };
    this.FRONT_URL = "/configuration/amo";
    this.LABEL = "AMO";

    this.state = {};
  }

  render() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <ConfigContainer
            icon={<Inventory />}
            title="Type de formule"
            backendUrl="/config-amo-type-formule"
            label={this.LABEL}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          {this.GED_AMO_PERMISSIONS.CAN_VIEW && (
            <Ged
              rootFolder="templates"
              section="portail"
              type="amo"
              permissions={this.GED_AMO_PERMISSIONS}
              title="Modèles de documents"
              iconColor="config.main"
              buttonColor="config"
              acceptedFileTypes={[
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              ]}
              popupComponent={DocumentTemplatePopup}
              frontUrl={this.FRONT_URL}
            />
          )}
        </Grid>
        {this.CAN_VIEW_LOG_ACTIVITY && (
          <MenuBottom>
            <Link to={this.FRONT_URL + "/logs?type=ConfigAmo"}>
              <Button square round>
                <Description />
              </Button>
            </Link>
          </MenuBottom>
        )}
      </Grid>
    );
  }
}

AmoConfig.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
  match: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(AmoConfig);
